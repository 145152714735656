import { useForm, Controller } from "react-hook-form";
import React from "react";
import {
  Grid,
  Typography,
  TextField,
  CardContent,
  Paper,
  Select,
  MenuItem,
  Button,
  Box,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import { toast } from "react-toastify";
import axios from "axios";
import InfrastructureAppBar from "../../../modules/navbar/infrastuctureAppBar";
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const LandMgmt = () => {
  const { control, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = async (data) => {
    const apiData = {
      totalArea: parseFloat(data.areaCoveredRooms) || 0,
      unit: data.areaUnit || "",
      sheetNo: data.sheetNo || "",
      kittaNo: data.kittaNo || "",
      ownerShip: data.ownership === "yes",
      remarks: data.remarks || "",
    };
  
    try {
      const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;
        const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json', 
        },
      };
     await axios.post(`${backendUrl}/Lands`, apiData, config);
      toast.success('Data posted Successfully', {
        autoClose:1500,
      });
    } catch (error) {
      toast.error('Error posting data: ' + error.message);
    }
  };

  return (
    <>
     <Box sx={{
      margin: 0, padding: 0,
      height: '100%',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    }}>
      <Box mt={8}>
      <InfrastructureAppBar/>
      </Box>
     </Box>
    <Grid container spacing={2}>
      <Grid item xs={false} md={2} />
      <Grid item xs={12} md={8} >
        <Paper elevation={5} sx={{ borderRadius: "20px" }}>
          <CardContent>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ textAlign: "center", color: "#2A629A" }}
            >
              Land Management
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} >
                  <Controller
                    name="areaCoveredRooms"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Total Area Covered by Library Rooms is required",
                      pattern: {
                        value: /^[0-9]*\.?[0-9]+$/,
                        message: "Please enter a valid decimal number",
                      },
                      min: {
                        value: 0.01,
                        message: "Total Area Covered by Library Rooms must be a positive number",
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="number"
                        size="small"
                        label="Total Area Covered by Library Rooms (sq.ft)"
                        fullWidth
                        autoComplete="areaCoveredRooms"
                        inputProps={{ step: "0.01", min: "0.01" }} // Allows only positive decimal numbers
                      />
                    )}
                  />
                  {errors.areaCoveredRooms && (
                    <FormHelperText error>{errors.areaCoveredRooms.message}</FormHelperText>
                  )}
                </Grid>

                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth size="small" error={!!errors.areaUnit}>
                    <InputLabel id="area-unit-label">Area Unit</InputLabel>
                    <Controller
                      name="areaUnit"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Area Unit is required" }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelId="area-unit-label"
                          label="Area Unit"
                          autoComplete="areaUnit"
                        >
                          <MenuItem value="hector">Hector</MenuItem>
                          <MenuItem value="ropani">Ropani</MenuItem>
                          <MenuItem value="kattha">Kattha</MenuItem>
                        </Select>
                      )}
                    />
                    {errors.areaUnit && (
                      <FormHelperText>{errors.areaUnit.message}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={3}>
                  <Controller
                    name="sheetNo"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        label="Sheet No of Land"
                        fullWidth
                        autoComplete="sheetNo"
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Controller
                    name="kittaNo"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        label="Kitta No"
                        fullWidth
                        autoComplete="kittaNo"
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth size="small" error={!!errors.ownership}>
                    <InputLabel id="ownership-label">Ownership</InputLabel>
                    <Controller
                      name="ownership"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Ownership is required" }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelId="ownership-label"
                          label="Ownership"
                          autoComplete="ownership"
                        >
                          <MenuItem value="yes">Yes</MenuItem>
                          <MenuItem value="no">No</MenuItem>
                        </Select>
                      )}
                    />
                    {errors.ownership && (
                      <FormHelperText>{errors.ownership.message}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} >
                  <Controller
                    name="remarks"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        label="Remarks"
                        fullWidth
                        autoComplete="remarks"
                        multiline
                        rows={4}
                        InputProps={{ style: { minWidth: '100%' } }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
                    <Button type="submit" variant="contained" color="primary">
                      Submit
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Paper>
      </Grid>
    </Grid>
    </>
  );
};

export default LandMgmt;
