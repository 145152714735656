import { Card, CardContent, Typography, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Box, styled } from "@mui/material";
import {
  Male,
  Female,
  Face,
  Group,
  Home,
  LocalHospital,
} from "@mui/icons-material";
import {
  getCampusByType,
  getEmployeeByGender,
  getStudentByGender,
} from "../dashboard/services/service";

// Styled Components
const IconBox = styled(Box)(({ color }) => ({
  display: "flex",
  alignItems: "center",
  "& svg": {
    marginRight: 4,
    color: color || "#FFF",
    fontSize: "1rem",
  },
}));

const StatBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: theme.spacing(0.5),
}));

// DashboardCard Component
const DashboardCard = ({
  title,
  male,
  female,
  others,
  total,
  color,
  community,
  constituent,
  privateCampus,
}) => {
  return (
    <Card
      sx={{
        backgroundColor: "rgba(255, 255, 255, 0.2)",
        backdropFilter: "blur(10px)",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.15)",
        height: "100%", // Ensure the card takes full height of the container
      }}
    >
      <CardContent sx={{ padding: "16px", flexGrow: 1 }}>
        {title === "No of Campus" ? (
          <StatBox>
            <IconBox color={color}>
              <Home />
              <Typography variant="body2" fontSize="0.8rem">
                Community: {community}
              </Typography>
            </IconBox>
            <IconBox color={color}>
              <Group />
              <Typography variant="body2" fontSize="0.8rem">
                Constituent: {constituent}
              </Typography>
            </IconBox>
            <IconBox color={color}>
              <LocalHospital />
              <Typography variant="body2" fontSize="0.8rem">
                Private: {privateCampus}
              </Typography>
            </IconBox>
          </StatBox>
        ) : (
          <StatBox>
            <IconBox color={color}>
              <Male />
              <Typography variant="body2" fontSize="0.8rem">
                Male: {male}
              </Typography>
            </IconBox>
            <IconBox color={color}>
              <Female />
              <Typography variant="body2" fontSize="0.8rem">
                Female: {female}
              </Typography>
            </IconBox>
            <IconBox color={color}>
              <Face />
              <Typography variant="body2" fontSize="0.8rem">
                Others: {others}
              </Typography>
            </IconBox>
          </StatBox>
        )}
        <Typography
          textAlign="center"
          variant="body1"
          color={color}
          style={{
            marginTop: "5px",
            fontWeight: "bold",
            fontSize: "0.9rem",
          }}
        >
          Total: {total}
        </Typography>
      </CardContent>
      <CardContent
        sx={{
          backgroundColor: color,
          color: "#fff",
          padding: "16px",
          borderRadius: "0 0 8px 8px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "30px",
        }}
      >
        <Typography
          variant="body2"
          component="div"
          sx={{ width: "100%", textAlign: "center", fontSize: "0.875rem" }}
        >
          {title}
        </Typography>
      </CardContent>
    </Card>
  );
};

// Dashboard Component
const Dashboard = ({ authToken, roleName }) => {
  const [students, setStudents] = useState({});
  const [employeeData, setEmployeeData] = useState([]);
  const [campusData, setCampusData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      if (!authToken) return;
      try {
        const studentData = await getStudentByGender(authToken);
        const employeeData = await getEmployeeByGender(authToken);
        const campusData = await getCampusByType(authToken);
        setCampusData(campusData || {});
        setStudents(studentData || {});
        setEmployeeData(employeeData || []);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [authToken]);


  const teachingStaff = employeeData.filter((item) => item.type === 'teaching')
  const noTeaching = employeeData.filter((item) => item.type === 'administrative' || item.type === 'technical').reduce(
    (acc, curr) => {
      acc.male += curr.male;
      acc.female += curr.female;
      acc.other += curr.other;
      acc.totalEmployees += curr.totalEmployees;
      return acc;
    },
    { male: 0, female: 0, other: 0, totalEmployees: 0 }
  );

  console.log(noTeaching)

  // Check if "No of Campus" card should be visible
  const isCampusCardVisible = roleName !== "CollegeAdmin";

  // Number of columns will be adjusted based on visibility of the "No of Campus" card
  const gridColumns = isCampusCardVisible ? 4 : 3;

  return (
    <Grid container spacing={2}>
      {/* Students Card */}
      <Grid item xs={12} sm={6} md={4} lg={gridColumns === 3 ? 4 : 3}>
        <DashboardCard
          title="Students"
          male={students.male || 0}
          female={students.female || 0}
          others={students.other || 0}
          total={students.total || 0}
          color="#005884"
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={gridColumns === 3 ? 4 : 3}>
        <DashboardCard
          title="Teaching Staff "
          male={teachingStaff[0]?.male || 0}
          female={teachingStaff[0]?.female || 0}
          others={teachingStaff[0]?.other || 0}
          total={teachingStaff[0]?.totalEmployees || 0}
          color="#1f3347"
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={gridColumns === 3 ? 4 : 3}>
        <DashboardCard
          title="Non-Teaching Staff"
          male={noTeaching.male || 0}
          female={noTeaching.female || 0}
          others={noTeaching.other || 0}
          total={
            noTeaching.totalEmployees ||
            0
          }
          color="#1976d2"
        />
      </Grid>

      {isCampusCardVisible && (
        <Grid item xs={12} sm={6} md={4} lg={gridColumns === 3 ? 4 : 3}>
          <DashboardCard
            title="No of Campus"
            total={
              campusData.community + campusData.constituent + campusData.private
            }
            color="#5e5e5e"
            community={campusData.community}
            constituent={campusData.constituent}
            privateCampus={campusData.private}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default Dashboard;
