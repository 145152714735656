import {
    Button,
    Dialog,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
  } from "@mui/material";
  import React, { useState } from "react";
  import AddingUniversityRoles from "./AddingUniversityRoles";  
  const ViewUniversityRegisterUser = () => {
    const [open, setOpen] = useState(false);
  
    const handleOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };
    return (
      <Grid container>
        <Grid item xs={false} />
        <Grid item xs={12} md={12}>
          <TableContainer>
            <Table
              style={{ borderCollapse: "collapse", border: "1px solid #ddd" }}
            >
              <TableHead>
                <TableRow
                  style={{ backgroundColor: "#2A629A", color: "#FFFFFF" }}
                >
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "4px",
                      textAlign: "center",
                    }}
                    rowSpan={2}
                  >
                    S.No.
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "4px",
                      textAlign: "center",
                    }}
                    rowSpan={2}
                  >
                    User Name
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "4px",
                      textAlign: "center",
                    }}
                    rowSpan={2}
                  >
                    Email
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "4px",
                      textAlign: "center",
                    }}
                    rowSpan={2}
                  >
                    Phone
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "4px",
                      textAlign: "center",
                    }}
                    rowSpan={2}
                  >
                    Institution
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "4px",
                      textAlign: "center",
                    }}
                    colSpan={2}
                  >
                    Student Management
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "4px",
                      textAlign: "center",
                    }}
                    colSpan={2}
                  >
                    User Management
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "4px",
                      textAlign: "center",
                    }}
                    colSpan={2}
                  >
                    Employee Management
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "4px",
                      textAlign: "center",
                    }}
                    rowSpan={2}
                  >
                    Action
                  </TableCell>
                </TableRow>
                <TableRow
                  style={{ backgroundColor: "#2A629A", color: "#FFFFFF" }}
                >
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "4px",
                      textAlign: "center",
                    }}
                  >
                    Privilege
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "4px",
                      textAlign: "center",
                    }}
                  >
                    Permissions
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "4px",
                      textAlign: "center",
                    }}
                  >
                    Privilege
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "4px",
                      textAlign: "center",
                    }}
                  >
                    Permissions
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "4px",
                      textAlign: "center",
                    }}
                  >
                    Privilege
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "4px",
                      textAlign: "center",
                    }}
                  >
                    Permissions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell style={{ border: "1px solid #ddd", padding: "4px" }}>
                    1
                  </TableCell>
                  <TableCell style={{ border: "1px solid #ddd", padding: "4px" }}>
                    hari bahadur
                  </TableCell>
                  <TableCell style={{ border: "1px solid #ddd", padding: "4px" }}>
                    haribahadur@gmail.com
                  </TableCell>
                  <TableCell style={{ border: "1px solid #ddd", padding: "4px" }}>
                    98712345670
                  </TableCell>
                  <TableCell style={{ border: "1px solid #ddd", padding: "4px" }}>
                    Institute of medicine
                  </TableCell>
                  <TableCell style={{ border: "1px solid #ddd", padding: "4px" }}>
                    yes
                  </TableCell>
                  <TableCell style={{ border: "1px solid #ddd", padding: "4px" }}>
                    w
                  </TableCell>
                  <TableCell style={{ border: "1px solid #ddd", padding: "4px" }}>
                    yes
                  </TableCell>
                  <TableCell style={{ border: "1px solid #ddd", padding: "4px" }}>
                    w
                  </TableCell>
                  <TableCell style={{ border: "1px solid #ddd", padding: "4px" }}>
                    No
                  </TableCell>
                  <TableCell style={{ border: "1px solid #ddd", padding: "4px" }}>
                    -
                  </TableCell>
                  <TableCell style={{ border: "1px solid #ddd", padding: "4px" }}>
                    <Button
                      onClick={handleOpen}
                      sx={{ textTransform: "capitalize" }}
                      size="small"
                    >
                      Add roles
                    </Button>
                  </TableCell>
                </TableRow>
                {/* Add more rows as needed */}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Dialog open={open} onClose={handleClose} maxWidth="lg">
          <AddingUniversityRoles />
        </Dialog>
      </Grid>
    );
  };
  
  export default ViewUniversityRegisterUser;
  