import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Grid,
  Typography,
  TextField,
  CardContent,
  Paper,
  Button,
  TableBody,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  Dialog,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from "@mui/material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { getBatch, postBatch } from "../../../services/services";
import EditBatch from "./EditBatch";
import { toast } from "react-toastify";
import OtherSetupAppBar from "../../../modules/navbar/OtherSetupAppBar";

const BatchMgmt = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [getBatchData, setGetBatchData] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedBatchId, setSelectedBatchId] = useState(null);
  const [loading, setLoading] = useState(false)
  const fetchData = async () => {
    try {
      const data = await getBatch();
      setGetBatchData(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onSubmit = async (data) => {
    setLoading(true)
    try {
      // Ensure UniversityId and CampusId default to 0 if not provided
      const formData = {
        name: data.name,
        batchNepali: data.batchNepali,
        index: data.index,
        isActive: data.isActive === 'true', // Convert string to boolean
      };
      await postBatch(formData);
      toast.success("Data added successfully!", {
        autoClose: 2000,
      });
      fetchData();
    } catch (err) {
      if (err.response && err.response.status === 409) {
        toast.error("Batch already exists!", {
          autoClose: 2000,
        });
      } else {
        toast.error("Failed to add data!", {
          autoClose: 2000,
        });
      }
    } finally {
      setLoading(false)
    }
  };

  const handleEditClick = (BatchId) => {
    setSelectedBatchId(BatchId);
    setOpenEditDialog(true);
  };

  const handleUpdate = () => {
    fetchData(); // Fetch updated data after edit
  };

  return (
    <>
    <Box sx={{
        margin: 0, padding: 0,
        height: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}>
        <Box mt={8}>
        <OtherSetupAppBar/>
        </Box>
       </Box>
    <Grid container spacing={2}>
      <Grid item xs={false} md={2} />
      <Grid item xs={12} md={8}>
        <Paper elevation={5} sx={{ borderRadius: "20px" }}>
          <CardContent>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ textAlign: "center", color: "#2A629A" }}
            >
              Batch Management
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    {...register("batchNepali", { required: "Batch Year in nepali is required" })}
                    size="small"
                    type="number"
                    label="Batch Year(NEP)"
                    fullWidth
                    autoComplete="given-name"
                    error={!!errors.batchNepali}
                    helperText={errors.batchNepali?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    {...register("name", { required: "Batch Name is required" })}
                    size="small"
                    type="number"
                    label="Batch Year(ENG)"
                    fullWidth
                    autoComplete="given-name"
                    error={!!errors.BatchName}
                    helperText={errors.BatchName?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    
                    {...register("index")}
                    size="small"
                    type="number"
                    label="Index"
                    fullWidth
                    autoComplete="given-name"
                    
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                        <FormControl fullWidth size="small">
                          <InputLabel>Status</InputLabel>
                          <Select
                            {...register("isActive")}
                            size="small"
                            label="isActive"
                            fullWidth
                            defaultValue={true}
                          >
                            <MenuItem value={true}>
                              <span style={{ color: "green" }}>Active</span>
                            </MenuItem>
                            <MenuItem value={false}>
                              <span style={{ color: "red" }}>InActive</span>
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                
                
              </Grid>
              <Grid
                container
                style={{
                  margin: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: "#007aff", color: "#inherit" }}
                >
                  {loading?'submiting...':'Submit'}
                </Button>
              </Grid>
            </form>
          </CardContent>
        </Paper>
        <Grid margin="10px">
          <Typography
            variant="h6"
            gutterBottom
            sx={{ textAlign: "center", color: "#2A629A" }}
          >
            List of Batch
          </Typography>
          <TableContainer>
            <Table
              style={{ borderCollapse: "collapse", border: "1px solid #ddd" }}
            >
              <TableHead style={{ backgroundColor: "#2A629A" }}>
                <TableRow>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    S.No
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    Batch Year Nepali
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    Batch Year English
                  </TableCell>
                  
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getBatchData.map((data, index) => (
                  <TableRow key={index}>
                    <TableCell
                      style={{ border: "1px solid #ddd", padding: "8px" }}
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell
                      style={{ border: "1px solid #ddd", padding: "8px" }}
                    >
                      {data.batchNepali}
                    </TableCell>
                    <TableCell
                      style={{ border: "1px solid #ddd", padding: "8px" }}
                    >
                      {data.name}
                    </TableCell>
                    <TableCell
                      style={{ border: "1px solid #ddd", padding: "8px" }}
                    >
                      {data.isActive ? (
                        <span style={{ color: "green" }}>Active</span>
                      ) : (
                        <span style={{ color: "red" }}>Inactive</span>
                      )}
                    </TableCell>
                    <TableCell
                      style={{ border: "1px solid #ddd", padding: "8px" }}
                    >
                      <Button onClick={() => handleEditClick(data.id)}>
                        <EditNoteIcon />
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Dialog
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        maxWidth="md"
      >
        <EditBatch
          id={selectedBatchId}
          onClose={() => setOpenEditDialog(false)}
          onUpdate={handleUpdate}
        />
      </Dialog>
    </Grid>
    </>
  );
};

export default BatchMgmt;
