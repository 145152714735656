
import React, { useState } from "react";
import {
  Box,
  Grid,
  Step,
  StepLabel,
  Paper,
  Stepper,
  Typography,
} from "@mui/material";

import EmployeeGeneralInfo, { EmployeeInfoProvider } from "./employeeGeneralInfo";
import AcademyGeneralInfo from "./AcademyGeneralInfo";
import { WorkInfoProvider } from "./Worknfo";
import {
  AddressInfoProvider,
} from "./EmployeeAdressGeneralInfo";

import DetailsReview from "./DetailsReview";
import { AcademyInfoProvider } from "./AcademyGeneralInfo";
import EmployeeAddressGeneralInfo from "./EmployeeAdressGeneralInfo";
import WorkGeneralInfo from "./Worknfo";
import { useLocation, useSearchParams } from "react-router-dom";
import EmployeeAppBar from "../../modules/navbar/employeeAppBar";

const steps = [
  "General Info",
  "Address Info",
  "Work Info",
  "Academic Info",
  "Review all",
];
export default function EmployeeRegister() {
  const [activeStep, setActiveStep] = useState(0);
  const [searchParams] = useSearchParams();
  const employeeType = searchParams.get("employeeType");
  console.log(employeeType)

  const handleNext = () => setActiveStep((prevStep) => prevStep + 1);
  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);

  return (
    <>
      <Box sx={{
        height: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
      }}>
        <Box mt={8}>
          <EmployeeAppBar />
        </Box>
      </Box>
      <EmployeeInfoProvider>
        <AddressInfoProvider>
          <WorkInfoProvider>
            <AcademyInfoProvider>
              <Grid sm={12} md={10} lg={9} direction='column' container sx={{ mx: "auto" }} >
                {
                  employeeType === 'administrator' ? (
                    <h1 className="text-3xl   text-center tracking-tight mt-4 mb-2">
                      Non-teaching staff registration
                    </h1>
                  ) : (
                    <h1 className="text-3xl   text-center tracking-tight mt-4 mb-2">
                      Teaching staff registration
                    </h1>
                  )
                }
                <h1 className="text-lg text-center tracking-tight text-[#2b6eb5]">
                  {steps[activeStep]}
                </h1>
                <Stepper style={{ marginTop: '10px', marginBottom: '-15px' }} activeStep={activeStep} alternativeLabel>
                  {steps.map((label, index) => (
                    <Step key={label}>
                      <StepLabel>
                        <span
                          style={{
                            color: index <= activeStep ? "#003285" : "inherit",
                          }}
                        >
                          {label}
                        </span>
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>

                <Box component={Paper} elevation={1} padding='25px 20px' mt={2} mb={3}>
                  {activeStep === 0 && (
                    <EmployeeGeneralInfo
                      handleNext={handleNext}
                      handleBack={handleBack}
                      employeeType={employeeType}
                    />
                  )}
                  {activeStep === 1 && (
                    <EmployeeAddressGeneralInfo
                      handleNext={handleNext}
                      handleBack={handleBack}
                    />
                  )}
                  {activeStep === 2 && (
                    <WorkGeneralInfo
                      handleNext={handleNext}
                      handleBack={handleBack}
                      employeeType={employeeType}
                    />
                  )}
                  {activeStep === 3 && (
                    <AcademyGeneralInfo
                      handleNext={handleNext}
                      handleBack={handleBack}
                      employeeType={employeeType}
                    />
                  )}
                  {activeStep === 4 && (
                    <DetailsReview handleBack={handleBack} employeeType={employeeType} />
                  )}
                </Box>
                <Grid item xs={false} md={2} />
              </Grid>
            </AcademyInfoProvider>
          </WorkInfoProvider>
        </AddressInfoProvider>
      </EmployeeInfoProvider>
    </>
  );
}

