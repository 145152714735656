import React from 'react'
import OrganizationAppBar from '../navbar/OrganizationAppBar'
import { Box } from '@mui/material'

const OrganizationManagementHome = () => {
  return (
    <div>
     <Box
        sx={{
          margin: 0,
          padding: 0,
          height: "100%",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box mt={8}>
        <OrganizationAppBar/>
        </Box>
      </Box>
    </div>
  )
}

export default OrganizationManagementHome