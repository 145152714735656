import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Grid,
  Typography,
  TextField,
  CardContent,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  DialogContent,
} from "@mui/material";
import { getBatchById, patchBatch } from "../../../services/services";
import { toast } from "react-toastify";
import { set } from "rsuite/esm/internals/utils/date";

const EditBatch = ({ id, onClose, onUpdate }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const [batchById, setBatchById] = useState(null);

  const onSubmit = async (data) => {
    try {
      // Adjust payload structure based on expected API format
      const payload = {
        id:id,
        name: data.BatchName,
        batchNepali: data.BatchNameNep, // Adjust if necessary
        index: data.index,
        isActive: data.isActive === 'true', // Convert string to boolean
      };
      await patchBatch(payload, id);
      onUpdate();
      onClose();
      toast.success("Data updated Successfully", {
        autoClose: 2000,
      });
    } catch (err) {
      if (err.response && err.response.status === 409) {
        toast.error("Batch already exists!", {
          autoClose: 2000,
        });
      } else {
        toast.error("Failed to add data!", {
          autoClose: 2000,
        });
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const batchDataById = await getBatchById(id);
        setBatchById(batchDataById);
        setValue("BatchName", batchDataById.name);
        setValue("BatchNameNep", batchDataById.batchNepali)
        setValue("index", batchDataById.index);
        setValue("isActive", batchDataById.isActive.toString()); // Convert boolean to string for select
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [id, setValue]);

  if (!batchById) return null; // Ensure data is available before rendering

  return (
    <DialogContent fullWidth>
      <Grid container spacing={0}>
        <Grid item xs={false} md={0} />
        <Grid item xs={12} md={12}>
          <CardContent>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ textAlign: "center", color: "#2A629A" }}
            >
              Edit Batch
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
               
                <Grid item xs={12} sm={3}>
                  <TextField
                    required
                    {...register("BatchNameNep", { required: true })}
                    size="small"
                    label="Batch Year (NEP)"
                    fullWidth
                    autoComplete="given-name"
                    error={!!errors.BatchNameNep}
                    helperText={errors.BatchNameNep ? "Batch Name (NEP) is required" : ""}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    required
                    {...register("BatchName", { required: true })}
                    size="small"
                    label="Batch Year (ENG)"
                    fullWidth
                    autoComplete="given-name"
                    error={!!errors.BatchName}
                    helperText={errors.BatchName ? "Batch Name is required" : ""}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    {...register("index")}
                    size="small"
                    type="number"
                    label="Index"
                    fullWidth
                    autoComplete="given-name"
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Status</InputLabel>
                    <Select
                      {...register("isActive")}
                      size="small"
                      label="Status"
                      fullWidth
                      defaultValue={batchById.isActive.toString()} // Convert boolean to string
                    >
                      <MenuItem value="true">
                        <span style={{ color: "green" }}>Active</span>
                      </MenuItem>
                      <MenuItem value="false">
                        <span style={{ color: "red" }}>InActive</span>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid
                container
                style={{
                  margin: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button onClick={onClose}>Cancel</Button>
                <Button
                  type="submit"
                  size="small"
                  variant="contained"
                  style={{
                    backgroundColor: "#007aff",
                    color: "#inherit",
                  }}
                >
                  Update
                </Button>
              </Grid>
            </form>
          </CardContent>
        </Grid>
      </Grid>
    </DialogContent>
  );
};

export default EditBatch;
