import React, { useEffect } from "react";
import {
  Grid,
  TextField,
  Select,
  Box,
  MenuItem,
  InputLabel,
  Button,
  FormControl,
  InputAdornment,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import ImageUploader from "../Reusable-component/ImageUploader";
import { createContext, useContext, useState } from "react";
import { styled } from "@mui/material/styles";
import { Controller, useForm } from "react-hook-form";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import {
  getBatch,
  getCampus,
  getFeeSetup,
  getFiscalYear,
} from "../../services/services";
import axios from "axios";
const backendUrl = process.env.REACT_APP_BACKEND_URL
const StudentRegContext = createContext();

const StudentRegProvider = ({ children }) => {
  const methods = useForm();
  const [registrationInfo, setRegistrationInfo] = useState({
    campusId: 0,
    levelId: 0,
    facultyId: 0,
    programId: 0,
    admissionYear: 0,
    dateOfEnrollment: "",
    completionYear: 0,
    fiscalYearId: 0,
    programFee: '',
    paidAmount: '',
    scholarshipAmount: 0,
    receipt: null,
    haveScholarship: false,

  });
  const onChange = (event, name, value) => {
    if (event && event.target && event.target.files) {
      const file = event.target.files[0];
      setRegistrationInfo((prevState) => ({
        ...prevState,
        [name]: file,
      }));
    } else {
      setRegistrationInfo((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  return (
    <StudentRegContext.Provider
      value={{ ...methods, registrationInfo, onChange }}
    >
      {children}
    </StudentRegContext.Provider>
  );
};
const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": {
    borderColor: "#c2c2c2",
    borderWidth: 1,
  },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 4,
    padding: "4px !important", // override inline-style
  },
});
const ValidationSelect = styled(Select)({
  "& select:valid + fieldset": {
    borderColor: "#3572EF",
    borderWidth: 1,
  },
  "& select:invalid + fieldset": {
    borderColor: "#ff0000", // Custom invalid border color
    borderWidth: 1,
  },
  "& select:valid:focus + fieldset": {
    borderLeftWidth: 4,
    padding: "4px !important", // override inline-style
  },
});
const StudentRegistrationInfo = ({ handleNext, handleBack }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm(); // Changed useContext(StudentRegContext) to useForm() for form handling

  const [campusData, setCampusData] = useState([]);
  const [levelData, setLevelData] = useState([]);
  const [facultyData, setFacultyData] = useState([]);
  const [programData, setProgramData] = useState([]);
  const [fiscalYear, setFiscalYear] = useState([]);
  const [formData, setFormData] = useState({
    haveScholarship: false,
    scholarshipAmount: ''
  });
  const [feeData, setFeeData] = useState([]);
  const [batchData, setBatchData] = useState([]);
  const [selectedProgramFee, setSelectedProgramFee] = useState('');
  const [defaultFiscal, setDefaultFiscal] = useState('');
  const [selectedLevel, setSelectedLevel] = useState('');
  const [selectedFaculty, setSelectedFaculty] = useState('');
  const [faculties, setFaculties] = useState([]);
  const [programs, setPrograms] = useState([]);

  const handleProgramChange = (event) => {
    const programId = event.target.value;
    const programFee = feeData.find(fee => fee && fee.programId === programId);
    setSelectedProgramFee(programFee);
  };

  const handleChange = (event) => {
    const { name, type, checked } = event.target;
    const value = type === 'checkbox' ? checked : event.target.value;

    // Update local state
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));

    // Update context state
    onChange(null, name, value);
  };

  const fetchData = async () => {
    try {
      const [campusData, fiscalYears, feeData, batchData] =
        await Promise.all([
          getCampus(),
          getFiscalYear(),
          getFeeSetup(),
          getBatch()
        ]);

      setCampusData(campusData);
      setFiscalYear(fiscalYear);
      setFeeData(feeData);
      setBatchData(batchData);
      setFiscalYear(fiscalYears)
      const activeFiscalYear = fiscalYears.find(data => data && data.activeFiscalYear === true);
      if (activeFiscalYear) {
        setDefaultFiscal(activeFiscalYear.id); // Use the ID or value that uniquely identifies the default fiscal year
      }

    } catch (err) {
      console.log(err);
    }
  };

  console.log('Active fiscal', defaultFiscal)
  const fetchData1 = async () => {
    try {
      const localStorageData = JSON.parse(localStorage.getItem('persist:root'));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;

      const config = {
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
      };

      const response = await axios.get(`${backendUrl}/ProgramMgmt/GetCollegePrograms`, config);
      const fetchedData = response.data;

      // Extract unique levels and faculties
      const uniqueLevels = Array.from(new Set(fetchedData.map(item => item.levelName)))
        .map(name => {
          return fetchedData.find(item => item.levelName === name);
        });

      const uniqueFaculties = Array.from(new Set(fetchedData.map(item => item.facultyName)))
        .map(name => {
          return fetchedData.find(item => item.facultyName === name);
        });

      setLevelData(uniqueLevels);
      setFacultyData(uniqueFaculties);
      setProgramData(fetchedData);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
    fetchData1();
  }, []);

  const handleLevelChange = (event) => {
    const levelId = event.target.value;
    setSelectedLevel(levelId);
    setSelectedFaculty(''); // Reset faculty and program selections

    // Filter faculties based on selected level
    const filteredFaculties = programData.filter(item => item.levelId === levelId);
    setFaculties(Array.from(new Set(filteredFaculties.map(item => item.facultyName)))
      .map(facultyName => filteredFaculties.find(faculty => faculty.facultyName === facultyName)));
  };

  const handleFacultyChange = (event) => {
    const facultyId = event.target.value;
    setSelectedFaculty(facultyId);

    // Filter programs based on selected faculty
    const filteredPrograms = programData.filter(item => item.facultyId === facultyId);
    setPrograms(filteredPrograms);
  };
  const { onChange } = useContext(StudentRegContext);

  const onSubmit = (data) => {
    handleSubmit((formData) => {
      Object.keys(formData).forEach((key) => {
        onChange(null, key, formData[key]);
      });
      handleNext();
    })(data);
  };

  const onBack = () => {
    handleBack();
  };

  return (
    <Grid
      container
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      onReset={() => {
        onBack();
      }}
    >
      <Grid mt={'.7rem'} container>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={3}>
            <FormControl sx={{ borderColor: "blue" }} size="small" fullWidth>
              <InputLabel sx={{ borderColor: "blue" }} id="levelId" required>
                Level Name
              </InputLabel>
              <Controller
                name="levelId"
                control={control}
                defaultValue={selectedLevel}
                render={({ field }) => (
                  <ValidationSelect
                    {...field}
                    required
                    labelId="levelId"
                    id="levelId"
                    name="levelId"
                    label="Level Name"
                    fullWidth
                    onChange={(e) => {
                      field.onChange(e);
                      handleLevelChange(e);
                    }}
                    value={selectedLevel}
                  >
                    <MenuItem value='' disabled>Select levels</MenuItem>
                    {levelData.length > 0 ? (
                      levelData.map((data) => (
                        <MenuItem key={data.id} value={data.levelId}>
                          {data.levelName}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="">No levels available</MenuItem>
                    )}
                  </ValidationSelect>
                )}
              />
            </FormControl>
          </Grid>

          {/* Faculty Selector */}
          <Grid item xs={12} sm={3}>
            <FormControl size="small" fullWidth>
              <InputLabel sx={{ borderColor: "blue" }} id="facultyName" required>
                Faculty Name
              </InputLabel>
              <Controller
                name="facultyId"
                control={control}
                rules={{ required: "Required" }}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    required
                    {...field}
                    id="facultyId"
                    size="small"
                    name="facultyId"
                    label="Faculty Name"
                    fullWidth
                    error={!!errors.facultyId}
                    helperText={errors.facultyId ? "Required" : ""}
                    onChange={(e) => {
                      field.onChange(e);
                      handleFacultyChange(e);
                    }}
                    value={selectedFaculty}
                  >
                    <MenuItem value='' disabled>Select Faculty</MenuItem>
                    {faculties.length > 0 ? (
                      faculties.map((data) => (
                        <MenuItem key={data.id} value={data.facultyId}>
                          {data.facultyName}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="">No faculties available</MenuItem>
                    )}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>

          {/* Program Selector */}
          <Grid item xs={12} sm={3}>
            <FormControl sx={{ borderColor: "blue" }} size="small" fullWidth>
              <InputLabel sx={{ borderColor: "blue" }} id="programName" required>
                Program
              </InputLabel>
              <Controller
                name="programId"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <ValidationSelect
                    {...field}
                    required
                    labelId="programId"
                    id="programId"
                    name="programId"
                    label="Program"
                    fullWidth
                    onChange={(e) => {
                      field.onChange(e); // Ensure the field updates
                      handleProgramChange(e); // Update the program fee
                    }}
                  >
                    <MenuItem value="" disabled>
                      <em>Select Programs</em>
                    </MenuItem>
                    {programs.length > 0 ? (
                      programs.map((data) => (
                        <MenuItem key={data.id} value={data.programId}>
                          {data.programName}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="">No programs available</MenuItem>
                    )}
                  </ValidationSelect>
                )}
              />
            </FormControl>

          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl size="small" fullWidth>
              <InputLabel required>Admission Year</InputLabel>
              <Controller
                name="admissionYear"
                control={control}
                defaultValue=""
                rules={{
                  pattern: {
                    value: /^\d{0,4}$/,
                    message:
                      "Please enter only numeric values with maximum length of four digits",
                  },
                }}
                render={({ field }) => (
                  <ValidationSelect
                    {...field}
                    required
                    id="admissionYear"
                    size="small"
                    name="admissionYear"
                    label="Admission Year"
                    fullWidth
                    InputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      onInput: (e) => {
                        e.target.value = e.target.value.replace(/\D/g, ""); // Allow only numeric characters
                        if (e.target.value.length > 4) {
                          e.target.value = e.target.value.slice(0, 4); // Limit input to maximum of four characters
                        }
                      },
                    }}

                  >
                    <MenuItem value='' disabled>Select Addmission Year</MenuItem>
                    {batchData && batchData.map((data) => (
                      <MenuItem key={data.id} value={data.name}>{data.name}</MenuItem>
                    ))}
                  </ValidationSelect>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl size="small" fullWidth>
              <InputLabel required>Completion Year</InputLabel>
              <Controller
                name="completionYear"
                control={control}
                defaultValue=""
                rules={{
                  pattern: {
                    value: /^\d{0,4}$/,
                    message:
                      "Please enter only numeric values with maximum length of four digits",
                  },
                }}
                render={({ field }) => (
                  <ValidationSelect
                    {...field}
                    required
                    id="completionYear"
                    size="small"
                    name="completionYear"
                    label="Completion Year"
                    fullWidth
                    InputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      onInput: (e) => {
                        e.target.value = e.target.value.replace(/\D/g, ""); // Allow only numeric characters
                        if (e.target.value.length > 4) {
                          e.target.value = e.target.value.slice(0, 4); // Limit input to maximum of four characters
                        }
                      },
                    }}

                  >
                    <MenuItem value='' disabled>Select Completion Year</MenuItem>
                    {batchData && batchData.map((data) => (
                      <MenuItem key={data.id} value={data.name}>{data.name}</MenuItem>
                    ))}
                  </ValidationSelect>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={2.5}>
            <Controller
              name="dateOfEnrollment"
              control={control}
              defaultValue={new Date().toISOString().split('T')[0]} // Format current date to YYYY-MM-DD
              render={({ field }) => (
                <ValidationTextField
                  {...field}
                  required
                  id="dateOfEnrollment"
                  size="small"
                  type="date"
                  name="dateOfEnrollment"
                  label="Date Of Enrollment"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>
          {defaultFiscal && (
            <Grid item xs={12} sm={2.5}>
              <FormControl fullWidth size="small">
                <InputLabel id="fiscalYearId">Fiscal Year</InputLabel>
                <Controller
                  name="fiscalYearId"
                  control={control}
                  defaultValue={defaultFiscal} 
                  render={({ field }) => (
                    <Select
                      {...field}
                      required
                      id="fiscalYearId"
                      size="small"
                      label="Fiscal Year"
                      fullWidth
                      value={defaultFiscal} 
                      autoComplete="given-name"
                      error={!!errors.fiscalYearId}
                      helperText={errors.fiscalYearId ? "Fiscal year is required" : ""}
                    >
                      <MenuItem value='' disabled>Select Fiscal Year</MenuItem>
                      {fiscalYear.map((data) => (
                        <MenuItem value={data.id} key={data.id}>{data.yearNepali}</MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12} sm={2}>
            <Controller
              name="programFee"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  id="programFee"
                  size="small"
                  name="programFee"
                  label="Program Fee"
                  fullWidth
                  disabled
                  value={selectedProgramFee} // Bind selectedProgramFee here
                  InputProps={{
                    startAdornment: <InputAdornment position="start">Rs.</InputAdornment>,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Controller
              name="paidAmount"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}

                  id="paidAmount"
                  size="small"
                  name="paidAmount"
                  label="Paid Amount"
                  fullWidth
                  InputProps={{
                    startAdornment: <InputAdornment position="start">Rs.</InputAdornment>,
                  }}
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <FormControl>
              <ImageUploader
                required
                allowMultiple={false}
                onImagesChange={(newImages) => {
                  if (newImages.length > 0) {
                    onChange(null, "receipt", newImages[0]);
                  } else {
                    onChange(null, "receipt", null);
                  }
                }}
                name="receipt"
                placeholder="Paid Receipt Upload"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.haveScholarship}
                  onChange={handleChange}
                  name="haveScholarship"
                  color="primary"
                />
              }
              label="Have a Scholarship?"
            />
          </Grid>
          {formData.haveScholarship && (
            <Grid item xs={12} sm={2}>

              <Controller
                name="scholarshipAmount"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="scholarshipAmount"
                    label="Scholarship Amount"
                    fullWidth
                    variant="outlined"
                    size="small"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">Rs.</InputAdornment>,
                    }}
                    onChange={(e) => {
                      handleChange(e);
                      field.onChange(e); // Ensure the field updates in react-hook-form
                    }}
                  />
                )}
              />
            </Grid>
          )}
        </Grid>

        <Grid container direction="column" alignItems="flex-start">
          <Box mt={2} display="flex" justifyContent="space-between">
            <Button
              variant="outlined"
              size="small"
              onClick={onBack}
              color="error"
              startIcon={<ChevronLeftRoundedIcon />}
            >
              Back
            </Button>

            <Button
              variant="outlined"
              size="small"
              type="submit"
              sx={{
                marginLeft: "10px",
              }}
              endIcon={<ChevronRightRoundedIcon />}
            >
              Next
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};
export { StudentRegProvider, StudentRegContext };
export default StudentRegistrationInfo;
