import React, { useContext, useState } from "react";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { UniversityInfoContext } from "./UniversityInfo";
import { UniversityContactContext } from "./ContactDetails";
import { Button, Box, CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';


const backendUrl = process.env.REACT_APP_BACKEND_URL; // Ensure you set REACT_APP_BACKEND_URL in your .env file

const ReviewDetails = ({ handleBack }) => {
  const { universityInfo, getLogoURL } = useContext(UniversityInfoContext);
  const { contactDetails } = useContext(UniversityContactContext);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  // Function to handle registration
  const handleRegister = async () => {

    setLoading(true); // Start loading

    const formData = new FormData();
    formData.append("type", universityInfo.type);
    formData.append("name", universityInfo.name);
    formData.append("code", universityInfo.code);
    formData.append("shortCode", universityInfo.shortCode);
    formData.append("alias", universityInfo.alias);
    formData.append("phoneNo", universityInfo.phone);
    formData.append("email", universityInfo.email);
    formData.append("url", universityInfo.url || "");
    formData.append("province", universityInfo.province);
    formData.append("district", universityInfo.district);
    formData.append("localLevel", universityInfo.localLevel);
    formData.append("wardNo", universityInfo.wardNo);
    formData.append("address", universityInfo.locality);
    if (universityInfo.logo) {
      formData.append("logo", universityInfo.logo); // Append logo as a file if available
    }
    if (universityInfo.universityLogo) {
      formData.append("universityLogo", universityInfo.universityLogo); // Append universityLogo if available
    }
    formData.append("dateOfEstd", formatDetail(new Date(contactDetails.dateOfEstd).toLocaleDateString()));
    formData.append("yearOfEstd", contactDetails.yearOfEstd || "");
    formData.append("accreditationStatus", contactDetails.accreditationStatus || "");
    formData.append("focalPersonName", contactDetails.focalPersonName || "");
    formData.append("position", contactDetails.position || "");
    formData.append("contactNo", contactDetails.contactNumber || "");
    formData.append("contactEmail", contactDetails.contactEmail || "");
    formData.append("bankName", contactDetails.bankName || "");
    formData.append("branch", contactDetails.branchName || "");
    formData.append("accountNo", contactDetails.accountNumber || "");
    try {
      // Retrieve the authentication token from localStorage
      const localStorageData = JSON.parse(localStorage.getItem('persist:root'));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;
      const config = {
        headers: {
          'Authorization': `Bearer ${authToken}`, // Add the authentication token here
          'Content-Type': 'multipart/form-data', // Important for FormData
        },
      };
      // Make POST request using axios with authentication header
      const response = await axios.post(`${backendUrl}/University`, formData, config);

      // Success handling
      toast.success("University Successfully Registered", {
        autoClose: 2000,
      });
      navigate("/organization-management/university-list");
    } catch (err) {
      if (err.response && err.response.status === 409) {
        toast.error("Student already exists!", {
          autoClose: 2000,
        });
      } else {
        toast.error("Failed to add data!", {
          autoClose: 2000,
        });
      }
    } finally {
      setLoading(false); // Stop loading
    }
  };
  const formatDetail = (detail) => {
    if (detail === null || detail === undefined) return "N/A";
    if (typeof detail === 'object') return JSON.stringify(detail); // For complex objects
    return detail.toString(); // For primitive values
  };

  const UniversityInfo = universityInfo
    ? [
      { name: "University Type:", detail: universityInfo.type },
      { name: "University Name:", detail: universityInfo.name },
      { name: "Year of Establishment:", detail: contactDetails.yearOfEstd },
      { name: "Date of Establishment:", detail: formatDetail(new Date(contactDetails.dateOfEstd).toLocaleDateString()) },
      { name: "University Code:", detail: universityInfo.code },
      { name: "Phone Number:", detail: universityInfo.phone },
      { name: "Email:", detail: universityInfo.email },
      { name: "URL:", detail: universityInfo.url },
      { name: "Short Name:", detail: universityInfo.shortCode },
      { name: "Alias:", detail: universityInfo.alias },
    ]
    : [];

  const contactPerson = contactDetails
    ? [
      { name: "Full Name:", detail: contactDetails.focalPersonName },
      { name: "Contact Number:", detail: contactDetails.contactNumber },
      { name: "Position:", detail: contactDetails.position },
      { name: "Email:", detail: contactDetails.contactEmail },
      { name: "Bank Name:", detail: contactDetails.bankName },
      { name: "Account Number:", detail: contactDetails.accountNumber },
      { name: "Branch:", detail: contactDetails.branchName },
      { name: "Accreditation Status:", detail: contactDetails.accreditationStatus },
    ]
    : [];

  // Get the logo URL from the context
  const logoURL = getLogoURL();

  return (
    <div style={{ marginBottom: '2rem' }}>
      <Grid container justifyContent="center">
        {logoURL && (
          <Box mt={2} display="flex" justifyContent="center">
            <img src={logoURL} alt="University Logo" style={{ maxWidth: '45px', maxHeight: '45px', borderRadius: '8px' }} />
          </Box>
        )}
        <Grid mt='1rem' item xs={12} sm={6} md={12}>
          <Box
            border='1px solid #8c8d90'
            borderRadius="10px"
            position="relative"
            paddingBottom="15px"
          >
            <Typography
              variant="body1"
              borderRadius='10px'
              display="inline-block"
              color='#8c8d90'
              border='1px solid #8c8d90'
              padding="3px 5px"
              position="relative"
              left="20px"
              bottom="17px"
              bgcolor='#e2e4e8'
            >
              University Details
            </Typography>
            <Grid paddingLeft="2.5rem" container justifyContent="flex-start">
              {UniversityInfo.map((details, index) => (
                <Grid item xs="5" key={index}>
                  <Stack direction="row" spacing={1} sx={{ width: "100%" }}>
                    <Typography variant="body1" gap={1}>
                      {details.name}
                    </Typography>
                    <Typography variant="subtitle" color="#1976d2">
                      {details.detail || '-'}
                    </Typography>
                  </Stack>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Grid mt="2rem" item xs={12} md={12}>
            <Box
              border='1px solid #8c8d90'
              borderRadius="10px"
              position="relative"
              paddingBottom="15px"
            >
              <Typography
                variant="body1"
                borderRadius='10px'
                display="inline-block"
                color='#8c8d90'
                border='1px solid #8c8d90'
                padding="3px 5px"
                position="relative"
                left="20px"
                bottom="17px"
                bgcolor='#e2e4e8'
              >
                Contact Person Details
              </Typography>
              <Grid paddingLeft="2.5rem" container justifyContent="flex-start">
                {contactPerson.map((contact, index) => (
                  <Grid key={index} item xs="5">
                    <Stack direction="row" spacing={1} sx={{ width: "100%" }}>
                      <Typography variant="body1">{contact.name}</Typography>
                      <Typography variant="body2" color="#1976d2">
                        {contact.detail || '-'}
                      </Typography>
                    </Stack>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Grid
          mt="2rem"
          color='#8c8d90'
          borderRadius="10px"
          position="relative"
          paddingBottom="15px"
          border='1px solid #8c8d90'
          xs={12}
          alignContent="left"
        >
          <Typography
            variant="body1"
            borderRadius='10px'
            display="inline-block"
            color='#8c8d90'
            border='1px solid #8c8d90'
            padding="3px 5px"
            position="relative"
            left="20px"
            bottom="17px"
            bgcolor='#e2e4e8'
          >Address details</Typography>
          <Typography color="#1976d2">
            {universityInfo &&
              [
                universityInfo.province,
                universityInfo.district,
                `${universityInfo.localLevel}-${universityInfo.wardNo}`,
                universityInfo.address,
              ].join(", ")}
          </Typography>
        </Grid>
      </Grid>

      {/* Buttons */}
      <Box mt={2} display="flex" justifyContent="flex-start">
        <Button
          onClick={handleBack}
          variant="outlined"
          color="error"
          type="reset"
          size="small"
          startIcon={<ChevronLeftRoundedIcon />}
        >
          Back
        </Button>
        <Button
          variant="outlined"
          size="small"
          type="submit"
          sx={{
            marginLeft: "10px",
            position: 'relative',
            pointerEvents: loading ? 'none' : 'auto',
            opacity: loading ? 0.6 : 1,
          }}
          onClick={handleRegister}
          endIcon={loading ? <CircularProgress size={24} color="inherit" /> : <ChevronRightRoundedIcon />}        >
          {loading ? 'Submitting...' : 'Register'}
        </Button>
      </Box>
    </div>
  );
};

export default ReviewDetails;
