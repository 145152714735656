import React, { useContext, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { EmployeeInfoContext } from "./employeeGeneralInfo";
import { AddressContext } from "./EmployeeAdressGeneralInfo";
import { WorkInfoContext } from "./Worknfo";
import { Button, CircularProgress } from "@mui/material";
import { AcademyContext } from "./AcademyGeneralInfo";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { LoadingOverlay } from "@mantine/core";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const DetailsReview = ({ handleBack, employeeType }) => {
  const { employeeInfo, getLogoURL } = useContext(EmployeeInfoContext);
  const { employeeAddress } = useContext(AddressContext);
  const { workInfo } = useContext(WorkInfoContext);
  const { academyInfo } = useContext(AcademyContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const { currentUser } = useSelector((state) => state.user);
  let universityId;
  let campusId;

  if (currentUser.type === 'Uni') {
    universityId = currentUser.institution.id;
  } else if (currentUser.type === 'college') {
    campusId = currentUser.institution.id;
  }

  const profileURL = getLogoURL();
  // Function to handle registration
  const handleRegister = async () => {
    setLoading(true); // Start loading
    const formData = new FormData();
    formData.append("campusId", campusId || 0);
    // formData.append("universityId", universityId || 0);
    formData.append("employeeType", employeeInfo.employeeType || 'teaching');
    formData.append("citizenIssueDist", employeeInfo.citizenIssueDist);
    formData.append("firstName", employeeInfo.firstName);
    formData.append("middleName", employeeInfo.middleName);
    formData.append("lastName", employeeInfo.lastName);
    formData.append("phoneNumber", employeeInfo.phone);
    formData.append("email", employeeInfo.email);
    formData.append("gender", employeeInfo.gender);
    formData.append("ethnicity", employeeInfo.ethnicity);
    formData.append("citizenshipNo", employeeInfo.citizenshipNo);
    formData.append("dateOFBirth", formatDetail(new Date(employeeInfo.dobBS).toLocaleDateString()));
    formData.append("dateOFBirthAd", formatDetail(new Date(employeeInfo.dobAD).toLocaleDateString()));
    formData.append("nidNo", employeeInfo.nidNo);
    formData.append("nid", employeeInfo.nidPic);
    formData.append("ctzFr", employeeInfo.citizenFront);
    formData.append("ctzBr", employeeInfo.citizenBack);
    formData.append("pPhoto", employeeInfo.pPsizePhoto);
    formData.append("levelId", workInfo.levelId);
    formData.append("facultyId", workInfo.facultyId);
    formData.append("programId", workInfo.programId);
    formData.append("graduatedfrom", academyInfo.graduatedfrom);
    formData.append("faculty", academyInfo.faculty);
    formData.append("level", academyInfo.level);
    formData.append("enrolledYear", academyInfo.enrolledYear);
    formData.append("PassedYear", academyInfo.passedYear);
    formData.append("cert", academyInfo.certificateCopy);
    formData.append("trans", academyInfo.transcriptCopy);
    formData.append("Mark", academyInfo.marksheetCopy);
    formData.append("otherF", academyInfo.otherDoc);

    // Address Info
    formData.append("pProvince", employeeAddress.pProvince);
    formData.append("pDistrict", employeeAddress.pDistrict);
    formData.append("pLocalLevel", employeeAddress.pLocalLevel);
    formData.append("pWardNo", employeeAddress.pWardNo);
    formData.append("pBlockNo", 0);
    formData.append("pHouseNo", employeeAddress.pHouseNo);
    formData.append("pLocality", employeeAddress.pTole);
    if (employeeAddress.isSameAsPermament) {
      formData.append("tProvince", employeeAddress.pProvince);
      formData.append("tDistrict", employeeAddress.pDistrict);
      formData.append("tLocalLevel", employeeAddress.pLocalLevel);
      formData.append("tWardNo", employeeAddress.pWardNo);
      formData.append("tBlockNo", 0);
      formData.append("tHouseNo", employeeAddress.pHouseNo);
      formData.append("tLocality", employeeAddress.pTole);
    } else {
      formData.append("tProvince", employeeAddress.tProvince);
      formData.append("tDistrict", employeeAddress.tDistrict);
      formData.append("tLocalLevel", employeeAddress.tLocalLevel);
      formData.append("tWardNo", employeeAddress.tWardNo);
      formData.append("tBlockNo", 0);
      formData.append("tHouseNo", employeeAddress.tHouseNo);
      formData.append("tLocality", employeeAddress.tTole);
    }

    // formData.append("group", workInfo.group);
    formData.append("position", workInfo.position);
    formData.append("group", workInfo.group);

    formData.append("joiningType", workInfo.joiningType);
    formData.append("joiningDate", '2024-08-30');
    formData.append("joiLet", workInfo.joiningletter);
    formData.append("othLet", workInfo.otherletter);
    formData.append("refLet", workInfo.reference);

    try {
      const localStorageData = JSON.parse(localStorage.getItem('persist:root'));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;
      const config = {
        headers: {
          'Authorization': `Bearer ${authToken}`,
        }
      };
      await axios.post(`${backendUrl}/Employee`, formData, config);
      toast.success('Data Successfully Updated!', {
        autoClose: 1500,
      });
      if (employeeType === 'teaching') {
        navigate('/employee-management/teaching-staff')

      } else {
        navigate('/employee-management/non-technical-staff')
      }
    } catch (err) {
      if (err.response && err.response.status === 409) {
        toast.error("Employee already exists!", {
          autoClose: 2000,
        });
      } else {
        toast.error("Failed to add data!", {
          autoClose: 2000,
        });
      }
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const formatDetail = (detail) => {
    if (detail === null || detail === undefined) return "N/A";
    if (typeof detail === 'object') return JSON.stringify(detail); // For complex objects
    return detail.toString(); // For primitive values
  };

  console.log(employeeInfo)
  const employeeDetails = [
    { name: "Full Name", detail: formatDetail(`${employeeInfo.firstName} ${employeeInfo.middleName} ${employeeInfo.lastName}`) },
    { name: "Employee Type:", detail: formatDetail(employeeInfo.employeeType || 'teaching') },
    { name: "Phone Number:", detail: formatDetail(employeeInfo.phone) },
    { name: "Email:", detail: formatDetail(employeeInfo.email) },
    { name: "Date Of Birth(BS):", detail: formatDetail(new Date(employeeInfo.dobBS).toLocaleDateString()) },
    { name: "Date Of Birth(AD):", detail: formatDetail(new Date(employeeInfo.dobAD).toLocaleDateString()) },
    { name: "Citizenship No:", detail: formatDetail(employeeInfo.citizenshipNo) },

  ];

  const workDetails = [
    { name: "Profession:", detail: formatDetail(workInfo.group) },
    // { name: "Sub Group:", detail: formatDetail(workInfo.subgroup) },
    { name: "Position:", detail: formatDetail(workInfo.position) },
    { name: "Joining Type:", detail: formatDetail(workInfo.joiningType) },
    { name: "Joining Date:", detail: formatDetail(new Date(workInfo.joiningdate).toLocaleDateString()) },

  ];

  const academicDetails = [
    { name: "Faculty:", detail: formatDetail(academyInfo.faculty) },
    { name: "Level:", detail: formatDetail(academyInfo.level) },
    { name: "Institution Name:", detail: formatDetail(academyInfo.graduatedfrom) },
    { name: "Graduated From:", detail: formatDetail(academyInfo.graduatedfrom) },
    { name: "Enrolled Year:", detail: formatDetail(academyInfo.enrolledYear) },
    { name: "Passed Year:", detail: formatDetail(academyInfo.passedYear) },
  ];

  return (
    <>
      {
        loading ? (
          <LoadingOverlay
            visible={loading}
            zIndex={100}
            overlayProps={{ radius: 'sm', blur: 1 }}
            loaderProps={{ color: '#1976d2', type: 'bars' }}
          />
        ) : <>
          <Grid mt='1rem' container >
            <Box
              border='1px solid #8c8d90'
              borderRadius="10px"
              position="relative"
              paddingBottom="15px"
            >
              <Typography
                variant="body1"
                borderRadius='10px'
                display="inline-block"
                padding="3px 5px"
                position="relative"
                left="20px"
                bottom="17px"
                border='1px solid #8c8d90'
                bgcolor='#e2e4e8'
                color="#8c8d90"
              >
                Employee Details
              </Typography>
              {profileURL && (
                <Box paddingLeft="2rem"  >
                  <img src={profileURL} alt="University Logo" style={{ width: '80px', height: '80px', borderRadius: '50%', objectFit: 'cover' }} />
                </Box>
              )}
              <Grid container mt='1rem' paddingLeft="2rem" justifyContent="space-between">
                {employeeDetails.map((details, index) => (
                  <Grid item xs={5} key={index}>
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{ width: "100%", mb: 1 }}
                    >
                      <Typography variant="body1" color="text.secondary">
                        {details.name}
                      </Typography>
                      <Typography variant="body2" color="#2B6EB5">{details.detail}</Typography>
                    </Stack>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
          <Grid mt="1.5rem" item xs={12} md={12}>
            <Box
              border='1px solid #8c8d90'
              borderRadius="10px"
              position="relative"
              paddingBottom="15px"
            >
              <Typography
                variant="body1"
                borderRadius='10px'
                display="inline-block"
                border='1px solid #8c8d90'
                bgcolor='#e2e4e8'
                color='#8c8d90'
                padding="3px 5px"
                position="relative"
                left="20px"
                bottom="17px"
              >
                Work Info
              </Typography>
              <Grid container justifyContent="space-between">
                {workDetails.map((details, index) => (
                  <Grid paddingLeft="2rem" item xs={5} key={index}>
                    <Stack direction="row" spacing={1} sx={{ width: "100%" }}>
                      <Typography variant="body1" color="text.secondary">
                        {details.name}
                      </Typography>
                      <Typography variant="body2" color="#2B6EB5">{details.detail}</Typography>
                    </Stack>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
          <Grid mt="1.5rem" item xs={12} md={12}>
            <Box
              border='1px solid #8c8d90'
              borderRadius="10px"
              position="relative"
              paddingBottom="15px"
            >
              <Typography
                variant="body1"
                border='1px solid #8c8d90'
                bgcolor='#e2e4e8'
                borderRadius='10px'
                display="inline-block"
                color='#8c8d90'
                padding="3px"
                position="relative"
                left="20px"
                bottom="17px"
              >
                Academic Info
              </Typography>
              <Grid container flexWrap="wrap" justifyContent="space-between">
                {academicDetails.map((details, index) => (
                  <Grid paddingLeft="2rem" item xs={5} key={index}>
                    <Stack direction="row" spacing={1}>
                      <Typography variant="body1" color="text.secondary">
                        {details.name}
                      </Typography>
                      <Typography variant="body2" color="#2B6EB5">{details.detail}</Typography>
                    </Stack>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
          <Grid mt="1.5rem" item xs={12} md={12}>
            <Box
              border='1px solid #8c8d90'
              borderRadius="10px"
              position="relative"
              paddingBottom="15px"
            >
              <Typography
                variant="body1"
                border='1px solid #8c8d90'
                borderRadius='10px'
                display="inline-block"
                color='#8c8d90'
                padding="3px 5px"
                position="relative"
                left="20px"
                bottom="17px"
                bgcolor='#e2e4e8'
              >
                Address Info
              </Typography>
              <Grid container justifyContent="flex-start" spacing={2}>
                <Grid item xs={12} md={12} sx={{ mb: 1 }} mx='2rem'>
                  <Typography variant="subtitle2" sx={{ textAlign: 'left' }}>Permanent Address:</Typography>
                  <Typography variant="body2" sx={{ textAlign: 'left' }} color="#2B6EB5">
                    {employeeAddress.pProvince}, {employeeAddress.pDistrict}, {employeeAddress.pLocalLevel} - {employeeAddress.pWardNo || '-'} , {employeeAddress.pTole || '-'},  {employeeAddress.pHouseNo || '-'}
                  </Typography>
                </Grid>
                {!employeeAddress.isSameAsPermament && (
                  <Grid item xs={12} md={6} sx={{ mb: 1 }}>
                    <Typography variant="subtitle2" sx={{ textAlign: 'left' }}>Temporary Address:</Typography>
                    <Typography variant="body2" sx={{ textAlign: 'left' }} color="#2B6EB5">
                      {employeeAddress.tProvince}, {employeeAddress.tDistrict}, {employeeAddress.tLocalLevel} - {employeeAddress.tWardNo}, {employeeAddress.tTole || '-'}, {employeeAddress.tHouseNo || '-'},
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Grid>

        </>
      }
      <Box mt={2} display="flex" justifyContent="flex-start">
        <Button
          onClick={handleBack}
          variant="outlined"
          color="error"
          type="reset"
          size="small"
          startIcon={<ChevronLeftRoundedIcon />}
        >
          Back
        </Button>
        <Button
          variant="outlined"
          size="small"
          type="submit"
          sx={{
            marginLeft: "10px",
          }}
          onClick={handleRegister}
          endIcon={loading ? <CircularProgress size={24} color="inherit" /> : <ChevronRightRoundedIcon />}        >
          {loading ? 'Submitting...' : 'Register'}
        </Button>
      </Box>
    </>
  );
};

export default DetailsReview;
