import { useForm, Controller } from "react-hook-form";
import React from "react";
import {
  Grid,
  Typography,
  TextField,
  CardContent,
  Paper,
  Button,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import InfrastructureAppBar from "../../../modules/navbar/infrastuctureAppBar";

const BuildingMgmt = () => {
  const { control, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = (data) => {
    console.log('Form data:', data);
  };

  return (
    <>
    <Box sx={{
      margin: 0, padding: 0,
      height: '100%',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    }}>
      <Box mt={8}>
      <InfrastructureAppBar/>
      </Box>
     </Box>
    <Grid container spacing={2}>
      <Grid item xs={false} md={2} />
      <Grid item xs={12} md={8}>
        <Paper elevation={5} sx={{ borderRadius: "20px" }}>
          <CardContent>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ textAlign: "center", color: "#2A629A" }}
            >
              Building Management
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                {/* House Name (Block No) */}
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="houseName"
                    control={control}
                    defaultValue=""
                    rules={{ required: "House Name (Block No) is required" }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        label="House Name (Block No)"
                        fullWidth
                        autoComplete="houseName"
                        error={!!errors.houseName}
                      />
                    )}
                  />
                  {errors.houseName && (
                    <FormHelperText error>{errors.houseName.message}</FormHelperText>
                  )}
                </Grid>

                {/* Area Covered by Building */}
                <Grid item xs={12} sm={3}>
                  <Controller
                    name="areaCovered"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Area Covered by Building is required",
                      pattern: {
                        value: /^[0-9]*\.?[0-9]+$/,
                        message: "Please enter a valid decimal number",
                      },
                      min: {
                        value: 0.01,
                        message: "Area Covered by Building must be a positive number",
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="number"
                        size="small"
                        label="Area Covered by Building (sq.ft)"
                        fullWidth
                        autoComplete="areaCovered"
                        error={!!errors.areaCovered}
                        inputProps={{ step: "0.01", min: "0.01" }} // Allows only positive decimal numbers
                      />
                    )}
                  />
                  {errors.areaCovered && (
                    <FormHelperText error>{errors.areaCovered.message}</FormHelperText>
                  )}
                </Grid>

                {/* Number of Classrooms */}
                <Grid item xs={12} sm={3}>
                  <Controller
                    name="numberOfClassrooms"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Number of Classrooms is required",
                      pattern: {
                        value: /^[1-9][0-9]*$/,
                        message: "Please enter a positive integer",
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="number"
                        size="small"
                        label="Number of Classrooms"
                        fullWidth
                        autoComplete="numberOfClassrooms"
                        error={!!errors.numberOfClassrooms}
                        inputProps={{ step: "1", min: "1" }} // Allows only positive integers
                      />
                    )}
                  />
                  {errors.numberOfClassrooms && (
                    <FormHelperText error>{errors.numberOfClassrooms.message}</FormHelperText>
                  )}
                </Grid>

                {/* Area Covered by All Rooms */}
                <Grid item xs={12} sm={3}>
                  <Controller
                    name="areaCoveredAllRooms"
                    control={control}
                    defaultValue=""
                    rules={{
                      pattern: {
                        value: /^[0-9]*\.?[0-9]+$/,
                        message: "Please enter a valid decimal number",
                      },
                      min: {
                        value: 0.01,
                        message: "Area Covered by All Rooms must be a positive number",
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="number"
                        size="small"
                        label="Area Covered by All Rooms (sq.ft)"
                        fullWidth
                        autoComplete="areaCoveredAllRooms"
                        
                        inputProps={{ step: "0.01", min: "0.01" }} // Allows only positive decimal numbers
                      />
                    )}
                  />
                  {errors.areaCoveredAllRooms && (
                    <FormHelperText error>{errors.areaCoveredAllRooms.message}</FormHelperText>
                  )}
                </Grid>

                {/* Ownership of Building */}
                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth size="small" error={!!errors.ownership}>
                    <InputLabel id="ownership-label">Ownership of Building</InputLabel>
                    <Controller
                      name="ownership"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Ownership of Building is required" }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelId="ownership-label"
                          label="Ownership of Building"
                          autoComplete="ownership"
                        >
                          <MenuItem value="yes">Yes</MenuItem>
                          <MenuItem value="no">No</MenuItem>
                        </Select>
                      )}
                    />
                    {errors.ownership && (
                      <FormHelperText>{errors.ownership.message}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                {/* Has Internet Connection */}
                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth size="small" error={!!errors.hasInternet}>
                    <InputLabel id="internet-label">Has Internet Connection</InputLabel>
                    <Controller
                      name="hasInternet"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Has Internet Connection is required" }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelId="internet-label"
                          label="Has Internet Connection"
                          autoComplete="hasInternet"
                        >
                          <MenuItem value="true">Yes</MenuItem>
                          <MenuItem value="false">No</MenuItem>
                        </Select>
                      )}
                    />
                    {errors.hasInternet && (
                      <FormHelperText>{errors.hasInternet.message}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                {/* Remarks */}
                <Grid item xs={12}>
                  <Controller
                    name="remarks"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        label="Remarks"
                        fullWidth
                        autoComplete="remarks"
                        multiline rows={4}
                        InputProps={{ style: { minWidth: '100%' } }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} >
                <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
                  
                  <Button type="submit" variant="contained" color="primary">
                    Submit
                  </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Paper>
      </Grid>
    </Grid>
    </>
  );
};

export default BuildingMgmt;
