import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
  Button,
  Grid,
  Popover,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import * as XLSX from "xlsx";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { FileDownload, InsertDriveFile, PictureAsPdf } from "@mui/icons-material";

const UniversityEnrollmentByYear = () => {
  const rows = [
    {
      id: 1,
      campusType: "Bachelors",
      university: "TU",
      year2016_17: 1200,
      year2017_18: 1300,
      year2018_19: 1400,
      year2019_20: 1500,
      year2020_21: 1600,
    },
    {
      id: 2,
      campusType: "Masters",
      university: "KU",
      year2016_17: 1000,
      year2017_18: 1100,
      year2018_19: 1200,
      year2019_20: 1300,
      year2020_21: 1400,
    },
    {
      id: 3,
      campusType: "PG",
      university: "PU",
      year2016_17: 800,
      year2017_18: 900,
      year2018_19: 1000,
      year2019_20: 1100,
      year2020_21: 1200,
    },
  ];

  const [filteredCampusType, setFilteredCampusType] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);


  const handleFilterChange = (event) => {
    setFilteredCampusType(event.target.value);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Close the export popover
  const handleClose = () => {
    setAnchorEl(null);
  };

  const filteredRows = filteredCampusType
    ? rows.filter((row) => row.campusType === filteredCampusType)
    : rows;

  const totals = filteredRows.reduce(
    (acc, row) => {
      acc.year2016_17 += row.year2016_17;
      acc.year2017_18 += row.year2017_18;
      acc.year2018_19 += row.year2018_19;
      acc.year2019_20 += row.year2019_20;
      acc.year2020_21 += row.year2020_21;

      acc.total +=
        row.year2016_17 +
        row.year2017_18 +
        row.year2018_19 +
        row.year2019_20 +
        row.year2020_21;

      return acc;
    },
    {
      year2016_17: 0,
      year2017_18: 0,
      year2018_19: 0,
      year2019_20: 0,
      year2020_21: 0,
      total: 0,
    }
  );

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(filteredRows);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Enrollment Data");
    XLSX.writeFile(wb, "University_Enrollment.xlsx");
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [
        ["S.No.", "University", "2016/17", "2017/18", "2018/19", "2019/20", "2020/21", "Total"],
      ],
      body: filteredRows.map((row, index) => [
        index + 1,
        row.university,
        row.year2016_17,
        row.year2017_18,
        row.year2018_19,
        row.year2019_20,
        row.year2020_21,
        row.year2016_17 +
          row.year2017_18 +
          row.year2018_19 +
          row.year2019_20 +
          row.year2020_21,
      ]),
    });
    doc.save("University_Enrollment.pdf");
  };
  const open = Boolean(anchorEl);
  const id = open ? "export-popover" : undefined;

  return (
    <div>
      <Box sx={{ marginBottom: "10px", display: "flex", gap: 2 }}>
        <FormControl fullWidth sx={{ maxWidth: "30%" }} size="small">
          <InputLabel>Filter by Level</InputLabel>
          <Select
            size="small"
            sx={{
              backgroundColor: '#fff',
              borderRadius: 2,
              borderColor: '#c2c2c2',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#c2c2c2',
                },
                '&:hover fieldset': {
                  borderColor: blue[500],
                },
                '&.Mui-focused fieldset': {
                  borderColor: blue[700],
                  boxShadow: `0 0 0 2px ${blue[100]}`,
                },
              },
              '& .MuiInputLabel-root': {
                fontSize: '1rem', // Slightly larger label
              },
              '& .MuiSelect-icon': {
                color: blue[700], // Color for the dropdown arrow
              },
            }}
            value={filteredCampusType}
            onChange={handleFilterChange}
            label="Filter by level"
          >
            <MenuItem value="">All Level</MenuItem>
            <MenuItem value="Bachelors">Bachelors</MenuItem>
            <MenuItem value="Masters">Masters</MenuItem>
            <MenuItem value="PG">PG</MenuItem>
          </Select>
        </FormControl>
        <Grid container justifyContent="right">
      <Button 
        variant="contained" 
        color="primary" 
        startIcon={<FileDownload />} 
        onClick={handleClick}
        style={{ marginBottom: '10px' }}
      >
        Export
      </Button>

      {/* Popover for Excel and PDF options */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box sx={{ padding: '10px' }}>
          <Button 
            variant="contained" 
            color="primary" 
            sx={{ backgroundColor: '#5FAD41' }}
            startIcon={<InsertDriveFile />} 
            onClick={exportToExcel} 
            fullWidth
            style={{ marginBottom: '10px' }}
          >
            Excel
          </Button>
          <Button 
            variant="contained" 
            sx={{ backgroundColor: '#272727' }}
            startIcon={<PictureAsPdf />} 
            onClick={exportToPDF} 
            fullWidth
          >
           PDF
          </Button>
        </Box>
      </Popover>
      </Grid>
      </Box>
      <TableContainer sx={{ border: "1px solid #ddd" }}>
        <Table style={{ borderCollapse: "collapse", border: "1px solid #ddd" }}>
          <TableHead style={{ backgroundColor: "#2A629A" }}>
            <TableRow>
              <TableCell sx={{ border: "1px solid #ddd", color: "#ffffff", padding: "4px", textAlign: "center" }}>
                S.No.
              </TableCell>
              <TableCell sx={{ border: "1px solid #ddd", color: "#ffffff", padding: "4px", textAlign: "center" }}>
                University
              </TableCell>
              <TableCell sx={{ border: "1px solid #ddd", color: "#ffffff", padding: "4px", textAlign: "center" }}>
                2016/17
              </TableCell>
              <TableCell sx={{ border: "1px solid #ddd", color: "#ffffff", padding: "4px", textAlign: "center" }}>
                2017/18
              </TableCell>
              <TableCell sx={{ border: "1px solid #ddd", color: "#ffffff", padding: "4px", textAlign: "center" }}>
                2018/19
              </TableCell>
              <TableCell sx={{ border: "1px solid #ddd", color: "#ffffff", padding: "4px", textAlign: "center" }}>
                2019/20
              </TableCell>
              <TableCell sx={{ border: "1px solid #ddd", color: "#ffffff", padding: "4px", textAlign: "center" }}>
                2020/21
              </TableCell>
              <TableCell sx={{ border: "1px solid #ddd", color: "#ffffff", padding: "4px", textAlign: "center" }}>
                Total
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {filteredRows.map((row, index) => {
              const totalStudents =
                row.year2016_17 +
                row.year2017_18 +
                row.year2018_19 +
                row.year2019_20 +
                row.year2020_21;

              return (
                <TableRow key={row.id}>
                  <TableCell sx={{ border: "1px solid #c2c2c2", padding: "4px", textAlign: "center" }}>
                    {index + 1}
                  </TableCell>
                  <TableCell sx={{ border: "1px solid #c2c2c2", padding: "4px", textAlign: "left" }}>
                    {row.university}
                  </TableCell>
                  <TableCell sx={{ border: "1px solid #c2c2c2", padding: "4px", textAlign: "right" }}>
                    {row.year2016_17}
                  </TableCell>
                  <TableCell sx={{ border: "1px solid #c2c2c2", padding: "4px", textAlign: "right" }}>
                    {row.year2017_18}
                  </TableCell>
                  <TableCell sx={{ border: "1px solid #c2c2c2", padding: "4px", textAlign: "right" }}>
                    {row.year2018_19}
                  </TableCell>
                  <TableCell sx={{ border: "1px solid #c2c2c2", padding: "4px", textAlign: "right" }}>
                    {row.year2019_20}
                  </TableCell>
                  <TableCell sx={{ border: "1px solid #c2c2c2", padding: "4px", textAlign: "right" }}>
                    {row.year2020_21}
                  </TableCell>
                  <TableCell sx={{ border: "1px solid #c2c2c2", padding: "4px", textAlign: "right" }}>
                    {totalStudents}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>

          <TableRow style={{ backgroundColor: "#c2c2c2" }}>
            <TableCell
              colSpan={2}
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Grand Total
            </TableCell>
            <TableCell sx={{ border: "1px solid #ddd", padding: "4px", textAlign: "right" }}>
              {totals.year2016_17}
            </TableCell>
            <TableCell sx={{ border: "1px solid #ddd", padding: "4px", textAlign: "right" }}>
              {totals.year2017_18}
            </TableCell>
            <TableCell sx={{ border: "1px solid #ddd", padding: "4px", textAlign: "right" }}>
              {totals.year2018_19}
            </TableCell>
            <TableCell sx={{ border: "1px solid #ddd", padding: "4px", textAlign: "right" }}>
              {totals.year2019_20}
            </TableCell>
            <TableCell sx={{ border: "1px solid #ddd", padding: "4px", textAlign: "right" }}>
              {totals.year2020_21}
            </TableCell>
            <TableCell sx={{ border: "1px solid #ddd", padding: "4px", textAlign: "right" }}>
              {totals.total}
            </TableCell>
          </TableRow>
        </Table>
      </TableContainer>
    </div>
  );
};

export default UniversityEnrollmentByYear;
