import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Popover, Box, Grid } from '@mui/material';
import { FileDownload, PictureAsPdf, InsertDriveFile } from '@mui/icons-material';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import axios from 'axios';
const backendUrl = process.env.REACT_APP_BACKEND_URL;


const InstitutionsStatus = () => {

  const [data, setData] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
        const userState = JSON.parse(localStorageData.user);
        const authToken = userState.currentUser.tokenString;
        const config = {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        };
        const response = await axios.get(`${backendUrl}/Dashboard/GetStudentByCollegeType?fiscalyearid=4`, config);
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);


  const rows = data?.map((item) => ({
    id: item.sn,
    university: item.universityName,
    constituteCampus: item.numberOfConstituentCampuses,
    communityCampus: item.numberOfCommunityCampuses,
    privateCampus: item.numberOfPrivateCampuses,
    totalCampus: item.totalCampuses,
    percentage: item.campusPercentage,
    constituentStudents: item.studentsInConstituentCampuses,
    privateStudents: item.studentsInPrivateCampuses,
    communityStudent: item.studentsInCommunityCampuses,
    total: item.totalStudents,
    numberOfGraduates: item.numberOfGraduates,
    numberOfTeachers: item.numberOfTeachers
  }))

  const totals = rows?.reduce(
    (acc, row) => {
      acc.constituteCampus += row.constituteCampus;
      acc.communityCampus += row.communityCampus;
      acc.privateCampus += row.privateCampus;
      acc.totalCampus += row.totalCampus;
      acc.percentage += row.percentage;
      acc.constituentStudents += row.constituentStudents;
      acc.privateStudents += row.privateStudents;
      acc.communityStudents += row.communityStudent;
      acc.totalStudents += row.total;
      acc.numberOfGraduates += row.numberOfGraduates;
      acc.numberOfTeachers += row.numberOfTeachers;
      return acc;
    },
    { constituteCampus: 0, communityCampus: 0, privateCampus: 0, totalCampus: 0, percentage: 0, constituentStudents: 0, privateStudents: 0, communityStudents: 0, totalStudents: 0, numberOfGraduates: 0, numberOfTeachers: 0 }
  );

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget); // Open the popover when button is clicked
  };

  const handleClose = () => {
    setAnchorEl(null); // Close the popover
  };

  const open = Boolean(anchorEl);
  const id = open ? 'export-popover' : undefined;

  // Export data to Excel
  const exportToExcel = () => {
    const header = [
      ['S.No.', 'University', 'Constituent', 'Community', 'Private', 'Total Campuses', 'Percentage',
        'Constituent Students', 'Community Students', 'Private Students', 'Total Students', 'Number of Graduates', 'Number of Teachers']
    ];

    const data = rows?.map((row, index) => [
      index + 1, row.university, row.constituteCampus, row.communityCampus, row.privateCampus, row.totalCampus, row.percentage,
      row.constituentStudents, row.communityStudent, row.privateStudents, row.total, row.numberOfGraduates, row.numberOfTeachers
    ]);

    const ws = XLSX.utils.aoa_to_sheet([...header, ...data]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Institutions Status');
    XLSX.writeFile(wb, 'institutions_status.xlsx');
  };

  // Export data to PDF
  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.setFontSize(12);
    const columns = [
      'S.No.', 'University', 'Constituent', 'Community', 'Private', 'Total Campuses', 'Percentage',
      'Constituent Students', 'Community Students', 'Private Students', 'Total Students', 'Number of Graduates', 'Number of Teachers'
    ];
    const data = rows?.map((row, index) => [
      index + 1, row.university, row.constituteCampus, row.communityCampus, row.privateCampus, row.totalCampus, row.percentage,
      row.constituentStudents, row.communityStudent, row.privateStudents, row.total, row.numberOfGraduates, row.numberOfTeachers
    ]);
    doc.autoTable(columns, data);
    doc.save('institutions_status.pdf');
  };

  return (
    <div>
      {/* Export Button with Popover */}
      <Grid container justifyContent="right">
        <Button
          variant="contained"
          color="primary"
          startIcon={<FileDownload />}
          onClick={handleClick}
          style={{ marginBottom: '10px' }}
        >
          Export
        </Button>

        {/* Popover for Excel and PDF options */}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Box sx={{ padding: '10px' }}>
            <Button
              variant="contained"
              color="primary"
              sx={{ backgroundColor: '#5FAD41' }}
              startIcon={<InsertDriveFile />}
              onClick={exportToExcel}
              fullWidth
              style={{ marginBottom: '10px' }}
            >
              Excel
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: '#272727' }}
              startIcon={<PictureAsPdf />}
              onClick={exportToPDF}
              fullWidth
            >
              PDF
            </Button>
          </Box>
        </Popover>
      </Grid>

      <TableContainer sx={{ border: '1px solid #ddd' }}>
        <Table style={{ borderCollapse: 'collapse', border: '1px solid #ddd' }}>
          <TableHead style={{ backgroundColor: '#2A629A' }}>
            <TableRow>
              <TableCell sx={{ border: '1px solid #ddd', color: '#ffffff', padding: '4px', textAlign: 'center' }} rowSpan={2}>S.No.</TableCell>
              <TableCell sx={{ border: '1px solid #ddd', color: '#ffffff', padding: '4px', textAlign: 'center' }} rowSpan={2}>University</TableCell>
              <TableCell sx={{ border: '1px solid #ddd', color: '#ffffff', padding: '4px', textAlign: 'center' }} colSpan={5}>Number of Campuses by Campus Type</TableCell>
              <TableCell sx={{ border: '1px solid #ddd', color: '#ffffff', padding: '4px', textAlign: 'center' }} colSpan={4}>Number of Students by Campus Type</TableCell>
              <TableCell sx={{ border: '1px solid #ddd', color: '#ffffff', padding: '4px', textAlign: 'center' }} rowSpan={2}>Number of Graduates</TableCell>
              <TableCell sx={{ border: '1px solid #ddd', color: '#ffffff', padding: '4px', textAlign: 'center' }} rowSpan={2}>Number of Teachers</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ border: '1px solid #ddd', color: '#ffffff', padding: '4px', textAlign: 'center' }}>Constituent</TableCell>
              <TableCell sx={{ border: '1px solid #ddd', color: '#ffffff', padding: '4px', textAlign: 'center' }}>Community</TableCell>
              <TableCell sx={{ border: '1px solid #ddd', color: '#ffffff', padding: '4px', textAlign: 'center' }}>Private</TableCell>
              <TableCell sx={{ border: '1px solid #ddd', color: '#ffffff', padding: '4px', textAlign: 'center' }}>Total</TableCell>
              <TableCell sx={{ border: '1px solid #ddd', color: '#ffffff', padding: '4px', textAlign: 'center' }}>Percentage</TableCell>
              <TableCell sx={{ border: '1px solid #ddd', color: '#ffffff', padding: '4px', textAlign: 'center' }}>Constituent Students</TableCell>
              <TableCell sx={{ border: '1px solid #ddd', color: '#ffffff', padding: '4px', textAlign: 'center' }}>Private Students</TableCell>
              <TableCell sx={{ border: '1px solid #ddd', color: '#ffffff', padding: '4px', textAlign: 'center' }}>Community Students</TableCell>
              <TableCell sx={{ border: '1px solid #ddd', color: '#ffffff', padding: '4px', textAlign: 'center' }}>Total Students</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, index) => (
              <TableRow key={index}>
                <TableCell sx={{ border: '1px solid #ddd' }} align="center">{index + 1}</TableCell>
                <TableCell sx={{ border: '1px solid #ddd' }} align="center">{row.university}</TableCell>
                <TableCell sx={{ border: '1px solid #ddd' }} align="center">{row.constituteCampus}</TableCell>
                <TableCell sx={{ border: '1px solid #ddd' }} align="center">{row.communityCampus}</TableCell>
                <TableCell sx={{ border: '1px solid #ddd' }} align="center">{row.privateCampus}</TableCell>
                <TableCell sx={{ border: '1px solid #ddd' }} align="center">{row.totalCampus}</TableCell>
                <TableCell sx={{ border: '1px solid #ddd' }} align="center">{row.percentage}</TableCell>
                <TableCell sx={{ border: '1px solid #ddd' }} align="center">{row.constituentStudents}</TableCell>
                <TableCell sx={{ border: '1px solid #ddd' }} align="center">{row.privateStudents}</TableCell>
                <TableCell sx={{ border: '1px solid #ddd' }} align="center">{row.communityStudent}</TableCell>
                <TableCell sx={{ border: '1px solid #ddd' }} align="center">{row.total}</TableCell>
                <TableCell sx={{ border: '1px solid #ddd' }} align="center">{row.numberOfGraduates}</TableCell>
                <TableCell sx={{ border: '1px solid #ddd' }} align="center">{row.numberOfTeachers}</TableCell>
              </TableRow>
            ))}
            {/* Row for totals */}
            <TableRow>
              <TableCell sx={{ border: '1px solid #ddd' }} align="center" colSpan={2}><strong>Total</strong></TableCell>
              <TableCell sx={{ border: '1px solid #ddd' }} align="center"><strong>{totals.constituteCampus}</strong></TableCell>
              <TableCell sx={{ border: '1px solid #ddd' }} align="center"><strong>{totals.communityCampus}</strong></TableCell>
              <TableCell sx={{ border: '1px solid #ddd' }} align="center"><strong>{totals.privateCampus}</strong></TableCell>
              <TableCell sx={{ border: '1px solid #ddd' }} align="center"><strong>{totals.totalCampus}</strong></TableCell>
              <TableCell sx={{ border: '1px solid #ddd' }} align="center"><strong>{totals.percentage}</strong></TableCell>
              <TableCell sx={{ border: '1px solid #ddd' }} align="center"><strong>{totals.constituentStudents}</strong></TableCell>
              <TableCell sx={{ border: '1px solid #ddd' }} align="center"><strong>{totals.privateStudents}</strong></TableCell>
              <TableCell sx={{ border: '1px solid #ddd' }} align="center"><strong>{totals.communityStudents}</strong></TableCell>
              <TableCell sx={{ border: '1px solid #ddd' }} align="center"><strong>{totals.totalStudents}</strong></TableCell>
              <TableCell sx={{ border: '1px solid #ddd' }} align="center"><strong>{totals.numberOfGraduates}</strong></TableCell>
              <TableCell sx={{ border: '1px solid #ddd' }} align="center"><strong>{totals.numberOfTeachers}</strong></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default InstitutionsStatus;
