import axios from "axios";

const backendUrl = process.env.REACT_APP_BACKEND_URL; // Ensure you set REACT_APP_BACKEND_URL in your .env file

export async function postFaculty(formData) {
  try {
    const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
    const userState = JSON.parse(localStorageData.user);
    const authToken = userState.currentUser.tokenString;

    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "multipart/form-data", // Use multipart/form-data if necessary
      },
    };

    // Create FormData object
    const data = new FormData();
    Object.keys(formData).forEach((key) => {
      data.append(key, formData[key] || ""); // Append empty strings for null values
    });
    const response = await axios.post(`${backendUrl}/Faculty`, data, config, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error posting faculty data:", error);
    throw error;
  }
}
export async function patchFaculty(formData, id) {
  try {
    const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
    const userState = JSON.parse(localStorageData.user);
    const authToken = userState.currentUser.tokenString;

    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "multipart/form-data", // Use multipart/form-data if necessary
      },
    };
    const response = await axios.patch(
      `${backendUrl}/Faculty/${id}`,
      formData,
      config,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}
export async function patchLevel(formData, id) {
  try {
    const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
    const userState = JSON.parse(localStorageData.user);
    const authToken = userState.currentUser.tokenString;

    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "multipart/form-data", // Use multipart/form-data if necessary
      },
    };
    const response = await axios.patch(
      `${backendUrl}/Level/${id}`,
      formData,
      config,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error posting Level data:", error);
    throw error;
  }
}

export async function getFacultyById(id) {
  try {
    const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
    const userState = JSON.parse(localStorageData.user);
    const authToken = userState.currentUser.tokenString;
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(
      `${backendUrl}/Faculty/GetAllFaculties/${id}`,
      config
    );
    console.log(response.data);
    return response.data;
  } catch (err) {
    console.log(err);
  }
}
export async function getFacultyByUniId(id) {
  try {
    const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
    const userState = JSON.parse(localStorageData.user);
    const authToken = userState.currentUser.tokenString;
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(`${backendUrl}/Faculty/${id}`, config);
    return response.data;
  } catch (err) {
    console.log(err);
  }
}
export async function getProgramById(id) {
  try {
    const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
    const userState = JSON.parse(localStorageData.user);
    const authToken = userState.currentUser.tokenString;
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(`${backendUrl}/ProgramMgmt/${id}`, config);
    return response.data;
  } catch (err) {
    console.log(err);
  }
}
export async function getProgramByUniId(id) {
  try {
    const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
    const userState = JSON.parse(localStorageData.user);
    const authToken = userState.currentUser.tokenString;
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(
      `${backendUrl}/ProgramMgmt/GetAllPrograms/${id}`,
      config
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
}

export async function getLevelById(id) {
  try {
    const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
    const userState = JSON.parse(localStorageData.user);
    const authToken = userState.currentUser.tokenString;
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(`${backendUrl}/level/${id}`, config);
    return response.data;
  } catch (err) {
    console.log(err);
  }
}
export async function getLevelByUniId(id) {
  try {
    const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
    const userState = JSON.parse(localStorageData.user);
    const authToken = userState.currentUser.tokenString;
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(
      `${backendUrl}/level/GetAllLebel/${id}`,
      config
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
}

export async function postLevel(formData) {
  try {
    const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
    const userState = JSON.parse(localStorageData.user);
    const authToken = userState.currentUser.tokenString;

    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "multipart/form-data", // Use multipart/form-data if necessary
      },
    };

    // Create FormData object
    const data = new FormData();
    Object.keys(formData).forEach((key) => {
      data.append(key, formData[key] || ""); // Append empty strings for null values
    });

    const response = await axios.post(`${backendUrl}/Level`, data, config);
    return response.data;
  } catch (error) {
    console.error("Error posting level data:", error);
    throw error;
  }
}

export async function postProgram(formData) {
  try {
    const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
    const userState = JSON.parse(localStorageData.user);
    const authToken = userState.currentUser.tokenString;

    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "multipart/form-data", // Use multipart/form-data if necessary
      },
    };

    // Create FormData object
    const data = new FormData();
    Object.keys(formData).forEach((key) => {
      data.append(key, formData[key] || ""); // Append empty strings for null values
    });
    const response = await axios.post(
      `${backendUrl}/ProgramMgmt`,
      data,
      config
    );
    return response.data;
  } catch (err) {
    throw err;
  }
}
export async function patchProgram(programData, id) {
  try {
    const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
    const userState = JSON.parse(localStorageData.user);
    const authToken = userState.currentUser.tokenString;

    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "multipart/form-data",
      },
    };

    const response = await axios.patch(
      `${backendUrl}/ProgramMgmt/${id}`,
      programData,
      config
    );
    return response.data;
  } catch (err) {
    console.error("Error in patchProgram:", err);
    throw err; // Optionally re-throw the error to handle it elsewhere
  }
}

export async function postSubject(subjectData) {
  try {
    const response = await axios.post(`${backendUrl}/Subject`, subjectData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (err) {
    console.log(err);
  }
}

export async function postFee(feeData) {
  try {
    const response = await axios.post(`${backendUrl}/FeeSetup`, feeData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (err) {
    console.log(err);
  }
}

export async function patchFee(feeData, id) {
  try {
    const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
    const userState = JSON.parse(localStorageData.user);
    const authToken = userState.currentUser.tokenString;

    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.put(
      `${backendUrl}/FeeSetup/${id}`,
      feeData,
      config
    );
    return response.data;
  } catch (err) {
    console.error("Error in patchProgram:", err);
    throw err; // Optionally re-throw the error to handle it elsewhere
  }
}
export async function getFaculty() {
  try {
    const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
    const userState = JSON.parse(localStorageData.user);
    const authToken = userState.currentUser.tokenString;
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(
      `${backendUrl}/Faculty/GetAllFaculties`,
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching faculty:", error);
    throw error;
  }
}

export async function getLevel() {
  try {
    const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
    const userState = JSON.parse(localStorageData.user);
    const authToken = userState.currentUser.tokenString;
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(
      `${backendUrl}/Level/GetAllLevels`,
      config
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
}

export async function getProgram() {
  try {
    const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
    const userState = JSON.parse(localStorageData.user);
    const authToken = userState.currentUser.tokenString;
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(
      `${backendUrl}/ProgramMgmt/GetAllPrograms`,
      config
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
}
export async function getSubject() {
  try {
    const response = await axios.get(`${backendUrl}/Subject`);
    return response.data;
  } catch (err) {
    console.log(err);
  }
}

export async function getUniversity() {
  try {
    const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
    const userState = JSON.parse(localStorageData.user);
    const authToken = userState.currentUser.tokenString;
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(
      `${backendUrl}/University/GetAllUniversities`,
      config
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
}
export async function getUniversityById(id) {
  console.log(id);
  try {
    const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
    const userState = JSON.parse(localStorageData.user);
    const authToken = userState.currentUser.tokenString;
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(`${backendUrl}/University/${id} `, config);
    console.log(response);
    return response.data;
  } catch (err) {
    console.log(err);
  }
}

export async function getCampus() {
  try {
    const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
    const userState = JSON.parse(localStorageData.user);
    const authToken = userState.currentUser.tokenString;
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(
      `${backendUrl}/Campus/GetAllCampuses`,
      config
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
}

export async function getCampusById(id) {
  console.log(id);
  try {
    const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
    const userState = JSON.parse(localStorageData.user);
    const authToken = userState.currentUser.tokenString;
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(`${backendUrl}/Campus/${id} `, config);
    return response.data;
  } catch (err) {
    console.log(err);
  }
}
export async function getFiscalYear() {
  try {
    const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
    const userState = JSON.parse(localStorageData.user);
    const authToken = userState.currentUser.tokenString;
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(`${backendUrl}/FiscalYear`, config);
    console.log(response);
    return response.data;
  } catch (error) {
    console.error("Error fetching faculty:", error);
    throw error;
  }
}
export async function getFeeSetup() {
  try {
    const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
    const userState = JSON.parse(localStorageData.user);
    const authToken = userState.currentUser.tokenString;
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(`${backendUrl}/FeeSetup`, config);
    return response.data;
  } catch (error) {
    console.error("Error fetching faculty:", error);
    throw error;
  }
}

export async function getBatch() {
  try {
    const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
    const userState = JSON.parse(localStorageData.user);
    const authToken = userState.currentUser.tokenString;
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(`${backendUrl}/Batch`, config);
    return response.data;
  } catch (error) {
    console.error("Error fetching faculty:", error);
    throw error;
  }
}

export async function postBatch(levelData) {
  try {
    const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
    const userState = JSON.parse(localStorageData.user);
    const authToken = userState.currentUser.tokenString;
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    const response = await axios.post(`${backendUrl}/Batch`, levelData, config);
    return response.data;
  } catch (error) {
    console.error("Error posting faculty data:", error);
    throw error;
  }
}

export async function patchBatch(payload, id) {
  try {
    const response = await axios.put(`${backendUrl}/Batch/${id}`, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error posting Level data:", error);
    throw error;
  }
}
export async function getBatchById(id) {
  try {
    const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
    const userState = JSON.parse(localStorageData.user);
    const authToken = userState.currentUser.tokenString;
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(`${backendUrl}/Batch/${id} `, config);
    return response.data;
  } catch (err) {
    console.log(err);
  }
}

export async function getEthnicGroup() {
  try {
    const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
    const userState = JSON.parse(localStorageData.user);
    const authToken = userState.currentUser.tokenString;
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(`${backendUrl}/EthinicGroup`, config);
    return response.data;
  } catch (error) {
    console.error("Error fetching faculty:", error);
    throw error;
  }
}

export async function postEthnicGroup(formData) {
  try {
    const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
    const userState = JSON.parse(localStorageData.user);
    const authToken = userState.currentUser.tokenString;
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    const response = await axios.post(
      `${backendUrl}/EthinicGroup`,
      formData,
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error posting faculty data:", error);
    throw error;
  }
}

export async function patchEthnicGroup(formData, id) {
  try {
    const response = await axios.put(
      `${backendUrl}/EthinicGroup/${id}`,
      formData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error posting Level data:", error);
    throw error;
  }
}
export async function getEthnicGroupById(id) {
  console.log(id);
  try {
    const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
    const userState = JSON.parse(localStorageData.user);
    const authToken = userState.currentUser.tokenString;
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(
      `${backendUrl}/EthinicGroup/${id} `,
      config
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
}
