import { useForm, Controller } from "react-hook-form";
import React from "react";
import {
  Grid,
  Typography,
  TextField,
  CardContent,
  Paper,
  Button,
  FormControl,
  InputLabel,
  Select,
  Box,
  MenuItem,
  FormHelperText,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import InfrastructureAppBar from "../../../modules/navbar/infrastuctureAppBar";

const FacilityMgmt = () => {
  const { control, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = (data) => {
    console.log('Form data:', data);
  };

  return (
    <>
    <Box sx={{
      margin: 0, padding: 0,
      height: '100%',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    }}>
      <Box mt={8}>
      <InfrastructureAppBar/>
      </Box>
     </Box>
    <Grid container spacing={2}>
      <Grid item xs={false} md={2} />
      <Grid item xs={12} md={8}>
        <Paper elevation={5} sx={{ borderRadius: "20px" }}>
          <CardContent>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ textAlign: "center", color: "#2A629A" }}
            >
               Register Facility 
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                {/* Facility Type */}
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth size="small" error={!!errors.facilityType}>
                    <InputLabel id="facility-type-label">Facility Type</InputLabel>
                    <Controller
                      name="facilityType"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Facility Type is required" }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelId="facility-type-label"
                          label="Facility Type"
                          autoComplete="facilityType"
                        >
                          <MenuItem value="drinkingWater">Drinking Water</MenuItem>
                          <MenuItem value="playground">Playground</MenuItem>
                          <MenuItem value="maleToilet">Male Toilet</MenuItem>
                          <MenuItem value="femaleToilet">Female Toilet</MenuItem>
                          <MenuItem value="waitingRoom">Waiting Room</MenuItem>
                          <MenuItem value="library">Library</MenuItem>
                          <MenuItem value="maleHostel">Male Hostel</MenuItem>
                          <MenuItem value="femaleHostel">Female Hostel</MenuItem>
                          <MenuItem value="laboratory">Laboratory</MenuItem>
                          <MenuItem value="meetingHall">Meeting Hall</MenuItem>
                          <MenuItem value="labEquipment">Lab Equipment</MenuItem>
                          <MenuItem value="eLibrary">E-library</MenuItem>
                          <MenuItem value="building">Building</MenuItem>
                          <MenuItem value="internetFacility">Internet Facility</MenuItem>
                        </Select>
                      )}
                    />
                    {errors.facilityType && (
                      <FormHelperText>{errors.facilityType.message}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                {/* Facility Availability */}
                <Grid item xs={12} sm={6} md={4}>
                  <FormControlLabel
                    control={
                      <Controller
                        name="facilityAvailability"
                        control={control}
                        defaultValue={false}
                        rules={{ required: "Facility Availability is required" }}
                        render={({ field }) => (
                          <Checkbox
                            {...field}
                            checked={field.value}
                            color="primary"
                          />
                        )}
                      />
                    }
                    label="Facility Availability"
                  />
                  {errors.facilityAvailability && (
                    <FormHelperText error>{errors.facilityAvailability.message}</FormHelperText>
                  )}
                </Grid>

                {/* Adequacy of Facility */}
                <Grid item xs={12} sm={6} md={4}>
                  <FormControlLabel
                    control={
                      <Controller
                        name="adequacyOfFacility"
                        control={control}
                        defaultValue={false}
                        render={({ field }) => (
                          <Checkbox
                            {...field}
                            checked={field.value}
                            color="primary"
                          />
                        )}
                      />
                    }
                    label="Adequacy of Facility"
                  />
                </Grid>

                {/* Remarks */}
                <Grid item xs={12}>
                  <Controller
                    name="remarks"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        label="Remarks"
                        fullWidth
                        autoComplete="remarks"
                        multiline
                        rows={4}
                        InputProps={{ style: { minWidth: '100%' } }}  // Ensures full width for remarks
                      />
                    )}
                  />
                </Grid>

                {/* Submit Button */}
                <Grid item xs={12} sx={{ mt: 2 }} display={"flex"} justifyContent={"center"}>
                  <Button type="submit" variant="contained" color="primary">
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Paper>
      </Grid>
      <Grid item xs={false} md={2} />
    </Grid>
    </>
  );
};

export default FacilityMgmt;
