import React from 'react'
import InfrastructureAppBar from '../navbar/infrastuctureAppBar'
import { Box } from '@mui/material';

const InfrastructureManagement = () => {
  return (
    
    <Box sx={{
      margin: 0, padding: 0,
      height: '100%',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    }}>
      <Box mt={5}>
      <InfrastructureAppBar/>
      </Box>
     </Box>
  )
}

export default InfrastructureManagement;