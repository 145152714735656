import { Box, Typography } from "@mui/material";
import React from "react";
import ProgramAppBar from "../navbar/ProgramSetupAppBar";
import { Grid } from "rsuite";
import { blue } from "@mui/material/colors";
import AttendanceAppBar from "../navbar/AttendanceAppBar";
import StudentAppBar from "../navbar/studentAppBar";

const AttendanceManagementHome = () => {
  return (
    <div>
      <Box
        sx={{
          margin: 0,
          padding: 0,
          height: "100%",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box mt={8}>
          <StudentAppBar />
        </Box>
      </Box>
      <Grid
        container
        sx={{ height: "100vh", width: "100%" }}
      >
        <Grid item xs={12} sm={12} md={12}>
          <Box sx={{ p: 2 }}>
            <Typography textAlign="center" fontSize={"1.5em"} color={blue[700]}>
             Student Attendance Management
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default AttendanceManagementHome;
