import React from 'react'
import OtherSetupAppBar from '../navbar/OtherSetupAppBar'
import { Box } from '@mui/material'

const OtherSetupHome = () => {
  return (
    <div><Box sx={{
        margin: 0, padding: 0,
        height: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}>
        <Box mt={8}>
        <OtherSetupAppBar/>
        </Box>
       </Box></div>
  )
}

export default OtherSetupHome