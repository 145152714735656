import axios from "axios";
const backendUrl = process.env.REACT_APP_BACKEND_URL; // Ensure you set REACT_APP_BACKEND_URL in your .env file

export async function getEmployeeById(id) {
  console.log(id)
  try {
    const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
    const userState = JSON.parse(localStorageData.user);
    const authToken = userState.currentUser.tokenString;
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(`${backendUrl}/Employee/${id}`, config);
    return response.data;
  } catch (err) {}
}

export async function getStudentById(id) {
  try {
    const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
    const userState = JSON.parse(localStorageData.user);
    const authToken = userState.currentUser.tokenString;
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(`${backendUrl}/Student/${id}`, config);
    return response.data;
  } catch (err) {
    console.log(err);
  }
}

export async function getEthnicGroup(id) {
  console.log(id)
  try {
    const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
    const userState = JSON.parse(localStorageData.user);
    const authToken = userState.currentUser.tokenString;
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(`${backendUrl}/EthinicGroup`, config);
    return response.data;
  } catch (err) {
    throw err;
  }
}
