import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Popover,
  Box,
  Grid,
} from "@mui/material";
import { FileDownload, InsertDriveFile, PictureAsPdf } from "@mui/icons-material";
import { blue } from "@mui/material/colors";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";

const GenderWiseEnrollment = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);

  // Fetch data from the API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
        const userState = JSON.parse(localStorageData.user);
        const authToken = userState.currentUser.tokenString;
        const config = {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        };
        const response = await fetch(
          "http://apiheims.dibugsoft.com/api/Dashboard/GetGenderWiseEnrollmentReport",
          config
        );
        const data = await response.json();

        // Check if the data is an array before setting it in the state
        if (Array.isArray(data)) {
          setRows(data);
        } else {
          console.error("Data is not an array", data);
          setRows([]); // Optionally set rows to an empty array if the data is not valid
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setRows([]); // Optionally set rows to an empty array in case of error
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Calculate the totals for each column and the overall total
  const totals = rows.reduce(
    (acc, row) => {
      acc.maleStudents += row.maleStudents;
      acc.femaleStudents += row.femaleStudents;
      acc.totalStudents += row.totalStudents;
      acc.percentOfStudents += row.percentOfStudents;

      return acc;
    },
    {
      maleStudents: 0,
      femaleStudents: 0,
      totalStudents: 0,
      percentOfStudents: 0,
    }
  );

  // Open the export popover
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget); // Open the popover when button is clicked
  };

  // Close the export popover
  const handleClose = () => {
    setAnchorEl(null); // Close the popover
  };

  const open = Boolean(anchorEl);
  const id = open ? "export-popover" : undefined;

  // Export data to Excel
  const exportToExcel = () => {
    const header = [
      [
        "S.No.",
        "University",
        "Male Students",
        "Female Students",
        "Total Students",
        "Percent of Students",
      ],
    ];

    const data = rows.map((row, index) => [
      index + 1,
      row.universityName,
      row.maleStudents,
      row.femaleStudents,
      row.totalStudents,
      row.percentOfStudents,
    ]);

    const ws = XLSX.utils.aoa_to_sheet([...header, ...data]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Gender Wise Enrollment");
    XLSX.writeFile(wb, "gender_wise_enrollment.xlsx");
  };

  // Export data to PDF
  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.setFontSize(12);
    const columns = [
      "S.No.",
      "University",
      "Male Students",
      "Female Students",
      "Total Students",
      "Percent of Students",
    ];
    const data = rows.map((row, index) => [
      index + 1,
      row.universityName,
      row.maleStudents,
      row.femaleStudents,
      row.totalStudents,
      row.percentOfStudents,
    ]);
    doc.autoTable(columns, data);
    doc.save("gender_wise_enrollment.pdf");
  };

  return (
    <div>

      <Grid container justifyContent="right">
        <Button
          variant="contained"
          color="primary"
          startIcon={<FileDownload />}
          onClick={handleClick}
          style={{ marginBottom: '10px' }}
        >
          Export
        </Button>

        {/* Popover for Excel and PDF options */}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Box sx={{ padding: '10px' }}>
            <Button
              variant="contained"
              color="primary"
              sx={{ backgroundColor: '#5FAD41' }}
              startIcon={<InsertDriveFile />}
              onClick={exportToExcel}
              fullWidth
              style={{ marginBottom: '10px' }}
            >
              Excel
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: '#272727' }}
              startIcon={<PictureAsPdf />}
              onClick={exportToPDF}
              fullWidth
            >
              PDF
            </Button>
          </Box>
        </Popover>
      </Grid>

      <TableContainer sx={{ border: "1px solid #ddd" }}>
        <Table style={{ borderCollapse: "collapse", border: "1px solid #ddd" }}>
          <TableHead style={{ backgroundColor: "#2A629A" }}>
            <TableRow>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                S.No.
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                University
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Male
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Female
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Number of Students
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Percent of Students
              </TableCell>
            </TableRow>
          </TableHead>

          {/* Central Universities */}
          <TableBody>
            {rows.map((row, index) => {
              return (
                <TableRow key={row.id}>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "center",
                    }}
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "left",
                    }}
                  >
                    {row.universityName}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "right",
                    }}
                  >
                    {row.maleStudents}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "right",
                    }}
                  >
                    {row.femaleStudents}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "right",
                    }}
                  >
                    {row.totalStudents}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "right",
                    }}
                  >
                    {row.percentOfStudents}%
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>

          {/* Grand Total Row */}
          <TableRow style={{ backgroundColor: '#c2c2c2' }}>
            <TableCell
              colSpan={2}
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Grand Total
            </TableCell>
            <TableCell
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "right",
              }}
            >
              {totals.maleStudents}
            </TableCell>
            <TableCell
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "right",
              }}
            >
              {totals.femaleStudents}
            </TableCell>
            <TableCell
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "right",
              }}
            >
              {totals.totalStudents}
            </TableCell>
            <TableCell
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "right",
              }}
            >
              {((totals.totalStudents / totals.totalStudents) * 100).toFixed(2)}%
            </TableCell>
          </TableRow>
        </Table>
      </TableContainer>
    </div>
  );
};

export default GenderWiseEnrollment;