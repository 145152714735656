import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Grid,
  Typography,
  TextField,
  CardContent,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  DialogContent,
} from "@mui/material";
import { getLevelById, patchLevel } from "../../../services/services";
import { toast } from "react-toastify";

const EditLevel = ({ id, onClose, onUpdate }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const [levelById, setLevelById] = useState([]);

  const onSubmit = async (data) => {
    try {
      await patchLevel(data, id);
      onUpdate();
      onClose();
      toast.success("Data updated Successfully", {
        autoClose: 2000,
      });
    } catch (err) {
      console.error("Submission error:", err);
      if (err.response && err.response.status === 409) {
        toast.error("Level already exists!", {
          autoClose: 2000,
        });
      } else {
        toast.error("Failed to add data!", {
          autoClose: 2000,
        });
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const levelDataById = await getLevelById(id);
        setLevelById(
          Array.isArray(levelDataById) ? levelDataById : [levelDataById]
        );
        setValue('universityId', levelDataById.universityId)
        setValue('campusId', levelDataById.campusId)
        setValue("levelName", levelDataById.levelName);
        setValue("shortName", levelDataById.shortName);
        setValue("code", levelDataById.code);
        setValue("alias", levelDataById.alias);
        setValue("status", levelDataById.status);
        setValue("remarks", levelDataById.remarks);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [id, setValue]);

  return (
    <>
      <DialogContent fullWidth>
        <Grid container spacing={0}>
          <Grid item xs={false} md={0} />
          <Grid item xs={12} md={12}>
            <CardContent>
              <Typography
                variant="h6"
                gutterBottom
                sx={{ textAlign: "center", color: "#2A629A" }}
              >
                Edit Level
              </Typography>
              <form onSubmit={handleSubmit(onSubmit)}>
                {levelById.map((data) => (
                  <Grid key={data.id}>
                    <Grid container spacing={2} key={data.id}>
                      <Grid item xs={12} sm={3}>
                        <TextField
                          required
                          {...register("levelName", { required: true })}
                          size="small"
                          label="Level Name"
                          fullWidth
                          autoComplete="given-name"
                          error={!!errors.levelName}
                          helpertext={
                            errors.levelName ? "Level Name is required" : ""
                          }
                        />
                      </Grid>
                      {/* <Grid item xs={12} sm={2}>
                        <TextField
                          required
                          {...register("shortName", { required: true })}
                          size="small"
                          label="Short Name"
                          fullWidth
                          autoComplete="short-name"
                          error={!!errors.shortName}
                          helpertext={
                            errors.shortName ? "Short Name is required" : ""
                          }
                        />
                      </Grid> */}
                      <Grid item xs={12} sm={2}>
                        <TextField
                          required
                          {...register("code", { required: true })}
                          size="small"
                          label="Code"
                          fullWidth
                          error={!!errors.code}
                          helpertext={errors.code ? "Code is required" : ""}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                  <TextField
                    {...register("displayOrder")}
                    size="small"
                    label="Level Display Order"
                    fullWidth
                   
                  />
                </Grid>
                      <Grid item xs={12} sm={2}>
                        <TextField
                          {...register("alias")}
                          size="small"
                          label="Alias"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <FormControl fullWidth size="small">
                          <InputLabel>Status</InputLabel>
                          <Select
                            {...register("status")}
                            size="small"
                            label="Status"
                            fullWidth
                            defaultValue={data.status}
                          >
                            <MenuItem value={true}>
                              <span style={{ color: "green" }}>Active</span>
                            </MenuItem>
                            <MenuItem value={false}>
                              <span style={{ color: "red" }}>InActive</span>
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          {...register("remarks")}
                          size="small"
                          label="Details"
                          fullWidth
                          error={!!errors.remarks}
                          helpertext={
                            errors.remarks ? "Details are required" : ""
                          }
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      style={{
                        margin: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      <Button onClick={onClose}>Cancel</Button>
                      <Button
                        type="submit"
                        size="small"
                        variant="contained"
                        style={{
                          backgroundColor: "#007aff",
                          color: "#inherit",
                        }}
                      >
                        {" "}
                        Update
                      </Button>
                    </Grid>
                  </Grid>
                ))}
              </form>
            </CardContent>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};

export default EditLevel;
