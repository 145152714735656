import React, { useEffect, useState } from "react";
import {
  Grid,
  CardContent,
  Paper,
  Button,
  Checkbox,
  FormControlLabel,
  Divider,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import GetCollegePrograms from "./GetCollegePrograms";
import { useSelector } from "react-redux";
import ProgramAppBar from "../../modules/navbar/ProgramSetupAppBar";
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const AddFaculties = () => {
  const [levels, setLevels] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [selectedLevels, setSelectedLevels] = useState([]);
  const [selectedFaculties, setSelectedFaculties] = useState([]);
  const [selectedPrograms, setSelectedPrograms] = useState([]);
  const [preSelectedPrograms, setPreSelectedPrograms] = useState([]);
  const [preSelectedLevels, setPreSelectedLevels] = useState([]);
  const [preSelectedFaculty, setPreSelectedFaculty] = useState([]);
  const [programData, setProgramData] = useState([]);
  const { currentUser } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);


  const universityId =
    currentUser.type === "college" ? currentUser.institution.universityId : 0;

  useEffect(() => {
    const fetchPrograms = async () => {
      setLoading(true);
      try {
        const localStorageData = JSON.parse(
          localStorage.getItem("persist:root")
        );
        const userState = JSON.parse(localStorageData.user);
        const authToken = userState.currentUser.tokenString;

        const config = {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        };

        const response = await axios.get(
          `${backendUrl}/ProgramMgmt/GetAllPrograms/${universityId}`,
          config
        );

        const allPrograms = response.data;
        const uniqueLevels = [
          ...new Set(allPrograms.map((program) => program.levelName)),
        ];

        setLevels(uniqueLevels);
        setPrograms(allPrograms);
      } catch (err) {
        console.error("Error fetching data:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchPrograms();
  }, [universityId]);

  useEffect(() => {
    const fetchCollegePrograms = async () => {
      setLoading(true);
      try {
        const localStorageData = JSON.parse(
          localStorage.getItem("persist:root")
        );
        const userState = JSON.parse(localStorageData.user);
        const authToken = userState.currentUser.tokenString;
        const config = {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        };
        const response = await axios.get(
          `${backendUrl}/ProgramMgmt/GetCollegePrograms`,
          config
        );
        const collegePrograms = response.data;

        setProgramData(collegePrograms);

        const preSelected = new Set(
          collegePrograms.map((program) => program.programName)
        );
        setPreSelectedPrograms([...preSelected]);
        setSelectedPrograms([...preSelected]);
        const preSelectedLevels = new Set(
          collegePrograms.map((program) => program.levelName)
        );
        setPreSelectedLevels([...preSelectedLevels]);
        setSelectedLevels([...preSelectedLevels]);
        const preSelectedFaculty = new Set(
          collegePrograms.map((program) => program.facultyName)
        );
        setPreSelectedFaculty([...preSelectedFaculty]);
        setSelectedFaculties([...preSelectedFaculty]);

        const levelsSet = new Set(
          collegePrograms.map((program) => program.levelName)
        );
        const facultiesSet = new Set(
          collegePrograms.map((program) => program.facultyName)
        );
        setSelectedLevels([...levelsSet]);
        setSelectedFaculties([...facultiesSet]);
      } catch (err) {
        console.error("Error fetching college programs:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchCollegePrograms();
  }, []);

  const getAvailableFaculties = () => {
    if (selectedLevels.length === 0) return [];
    const faculties = programs
      .filter((program) => selectedLevels.includes(program.levelName))
      .map((program) => program.facultyName);

    return [...new Set(faculties)];
  };

  const getAvailablePrograms = () => {
    if (selectedFaculties.length === 0) return [];
    const programsList = programs
      .filter((program) => selectedFaculties.includes(program.facultyName))
      .map((program) => program.programName);

    return [...new Set(programsList)];
  };

  const handleLevelSelection = (levelName, checked) => {
    setSelectedLevels((prevLevels) =>
      checked
        ? [...prevLevels, levelName]
        : prevLevels.filter((selectedLevel) => selectedLevel !== levelName)
    );
    setSelectedFaculties([]);
    setSelectedPrograms([]);
  };

  const handleFacultySelection = (facultyName, checked) => {
    setSelectedFaculties((prevFaculties) =>
      checked
        ? [...prevFaculties, facultyName]
        : prevFaculties.filter(
            (selectedFaculty) => selectedFaculty !== facultyName
          )
    );
    setSelectedPrograms([]);
  };

  const handleProgramSelection = (programName, checked) => {
    if (preSelectedPrograms.includes(programName)) {
      return;
    }
    setSelectedPrograms((prevPrograms) =>
      checked
        ? [...prevPrograms, programName]
        : prevPrograms.filter(
            (selectedProgram) => selectedProgram !== programName
          )
    );
  };
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    if (levels.includes(name)) {
      handleLevelSelection(name, checked);
    } else if (getAvailableFaculties().includes(name)) {
      handleFacultySelection(name, checked);
    } else if (getAvailablePrograms().includes(name)) {
      handleProgramSelection(name, checked);
    }
  };

  const formatDataForAPI = () => {
    return {
      programId: programs
        .filter((program) => selectedPrograms.includes(program.programName))
        .map((program) => program.id),
      isActive: true,
    };
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;
      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      const dataToSend = formatDataForAPI();
      await axios.post(
        `${backendUrl}/ProgramMgmt/AddProgramToCollege`,
        dataToSend,
        config
      );
      setSelectedLevels([]);
      setSelectedFaculties([]);
      setSelectedPrograms([]);
      window.location.reload();
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  return (
    <>
      <Box
        sx={{
          margin: 0,
          padding: 0,
          height: "100%",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box mt={8}>
          <ProgramAppBar />
        </Box>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={false} md={2} />
        <Grid item xs={12} md={8}>
          <Paper elevation={5} sx={{ borderRadius: "20px" }}>
            <CardContent>
            {loading ? (  // Show circular progress while loading
                <Box display="flex" justifyContent="center" p={2}>
                  <CircularProgress />
                </Box>
              ) : (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Divider textAlign="left">
                      <span style={{ color: "#1976d2" }}>Level</span>
                    </Divider>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    {levels.map((level) => (
                      <FormControlLabel
                        key={level}
                        control={
                          <Checkbox
                            size="small"
                            checked={selectedLevels.includes(level)}
                            onChange={handleCheckboxChange}
                            name={level}
                            disabled={preSelectedLevels.includes(level)}
                            sx={{
                              "&.Mui-disabled": {
                                color: "blue",
                              },
                              "&.Mui-checked.Mui-disabled": {
                                color: "blue",
                              },
                            }}
                          />
                        }
                        label={
                          <span
                            style={{
                              color: preSelectedLevels.includes(level)
                                ? "black"
                                : "black",
                            }}
                          >
                            {level}
                          </span>
                        }
                      />
                    ))}
                  </Grid>
                  {selectedLevels.length > 0 && (
                    <>
                      <Grid item xs={12}>
                        <Divider textAlign="left">
                          <span style={{ color: "#1976d2" }}>Faculty</span>
                        </Divider>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        {getAvailableFaculties().map((faculty) => (
                          <FormControlLabel
                            key={faculty}
                            control={
                              <Checkbox
                                size="small"
                                checked={selectedFaculties.includes(faculty)}
                                onChange={handleCheckboxChange}
                                name={faculty}
                                disabled={preSelectedFaculty.includes(faculty)}
                                sx={{
                                  "&.Mui-disabled": {
                                    color: "blue",
                                  },
                                  "&.Mui-checked.Mui-disabled": {
                                    color: "blue",
                                  },
                                }}
                              />
                            }
                            label={
                              <span
                                style={{
                                  color: preSelectedLevels.includes(faculty)
                                    ? "black"
                                    : "black",
                                }}
                              >
                                {faculty}
                              </span>
                            }
                          />
                        ))}
                      </Grid>
                    </>
                  )}
                  {selectedFaculties.length > 0 && (
                    <>
                      <Grid item xs={12}>
                        <Divider textAlign="left">
                          <span style={{ color: "#1976d2" }}>Program</span>
                        </Divider>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        {getAvailablePrograms().map((program) => (
                          <FormControlLabel
                            key={program}
                            control={
                              <Checkbox
                                size="small"
                                checked={selectedPrograms.includes(program)}
                                onChange={handleCheckboxChange}
                                name={program}
                                disabled={preSelectedPrograms.includes(program)}
                                sx={{
                                  "&.Mui-disabled": {
                                    color: "blue",
                                  },
                                  "&.Mui-checked.Mui-disabled": {
                                    color: "blue",
                                  },
                                }}
                              />
                            }
                            label={
                              <span
                                style={{
                                  color: preSelectedLevels.includes(program)
                                    ? "black"
                                    : "black",
                                }}
                              >
                                {program}
                              </span>
                            }
                          />
                        ))}
                      </Grid>
                    </>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={
                      selectedLevels.length === 0 &&
                      selectedFaculties.length === 0 &&
                      selectedPrograms.length === 0
                    }
                  >
                    Add
                  </Button>
                </Grid>
              </form>
              )}
            </CardContent>
          </Paper>
          <Box mt={2}>
            <Typography
              textAlign={"center"}
              style={{ color: "#1976d2", padding: "10px" }}
            >
              List of Campus Programs
            </Typography>
            <GetCollegePrograms programData={programData} />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default AddFaculties;
