
import React, { useState } from "react";
import {
  Box,
  Grid,
  Step,
  StepLabel,
  Paper,
  Stepper,
  Typography,
} from "@mui/material";
import EditAcademyGeneralInfo from "../../../components/employeeRegister/edit/EditAcademyGeneralInfo";
import EditEmployeeAddressGeneralInfo, { EditAddressInfoProvider } from "../../../components/employeeRegister/edit/EditEmployeeAddressGeneralInfo";
import EditWorkGeneralInfo, { EditWorkInfoProvider } from "../../../components/employeeRegister/edit/EditWorkInfo";
import { EditAcademyInfoProvider } from "../../../components/employeeRegister/edit/EditAcademyGeneralInfo";
import EditEmployeeGeneralInfo, { EditEmployeeInfoProvider } from "../../../components/employeeRegister/edit/EditEmployeeGeneralInfo";
import EditDetailsReview from "../../../components/employeeRegister/edit/EditDetailReview";
const steps = [
  "General Info",
  "Address Info",
  "Work Info",
  "Academic Info",
  "Review all",
];
export default function EditEmployeeRegister({ id }) {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => setActiveStep((prevStep) => prevStep + 1);
  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);

  return (
    <EditEmployeeInfoProvider>
      <EditAddressInfoProvider>
        <EditWorkInfoProvider>
          <EditAcademyInfoProvider>
            <Grid container sx={{ minHeight: "0" }} spacing={0} marginTop={2}>
              <Grid item xs={false} md={2} />
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                sx={{ backgroundColor: "background.default", p: 0, borderRadius: "20px" }}
              >
                <Box sx={{ maxWidth: '100%', mx: "auto" }}>
                  <Typography
                    variant="h5"
                    mb={2}
                    textAlign="center"
                    p="10px"
                    sx={{ color: "#2A629A" }}
                  >
                    Register Employee
                  </Typography>
                  <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label, index) => (
                      <Step key={label}>
                        <StepLabel>
                          <span
                            style={{
                              color: index <= activeStep ? "#003285" : "inherit",
                            }}
                          >
                            {label}
                          </span>
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>

                  <Box mt={2} mx={3}>
                    {activeStep === 0 && (
                      <EditEmployeeGeneralInfo
                        handleNext={handleNext}
                        handleBack={handleBack}
                        id={id}
                      />
                    )}
                    {activeStep === 1 && (
                      <EditEmployeeAddressGeneralInfo
                        handleNext={handleNext}
                        handleBack={handleBack}
                        id={id}
                      />
                    )}
                    {activeStep === 2 && (
                      <EditWorkGeneralInfo
                        handleNext={handleNext}
                        handleBack={handleBack}
                        id={id}
                      />
                    )}
                    {activeStep === 3 && (
                      <EditAcademyGeneralInfo
                        handleNext={handleNext}
                        handleBack={handleBack}
                        id={id}
                      />
                    )}
                    {activeStep === 4 && (
                      <EditDetailsReview handleBack={handleBack} id={id} />
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={false} md={2} />
            </Grid>
          </EditAcademyInfoProvider>
        </EditWorkInfoProvider>
      </EditAddressInfoProvider>
    </EditEmployeeInfoProvider>
  );
}

