import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import axios from "axios";
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const HeadTableCell = styled(TableCell)({
  fontSize: "16px",
  backgroundColor: "#2B6EB5",
  color: " white",
});

function UgcTable({ authToken }) {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      if (!authToken) return;
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        };
        const response = await axios.get(
          `${backendUrl}/Employee/Report/GetEmployeesForAdmin`,
          config
        );
        setData(response.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [authToken]);

  return (
    <TableContainer component={Paper}>
      <Table size="small" sx={{ minWidth: 650 }} aria-label="a dense table">
        <TableHead>
          <TableRow>
            <HeadTableCell sx={{ border: '1px solid #ddd', color: '#ffffff', padding: '4px', textAlign: 'center' }} rowSpan={2}>
              {" "}
              S.No{" "}
            </HeadTableCell>
            <HeadTableCell
              sx={{
                border: '1px solid #ddd', color: '#ffffff', padding: '4px', textAlign: 'center'
              }}
              rowSpan={2}
            >
              University
            </HeadTableCell>
            <HeadTableCell
             sx={{ border: '1px solid #ddd', color: '#ffffff', padding: '4px', textAlign: 'center' }}
              rowSpan={2}
            >
              No Of Campus
            </HeadTableCell>
            <HeadTableCell
              colSpan={4}
              sx={{ border: '1px solid #ddd', color: '#ffffff', padding: '4px', textAlign: 'center' }}
            >
              Students
            </HeadTableCell>
            <HeadTableCell
              colSpan={4}
              sx={{ border: '1px solid #ddd', color: '#ffffff', padding: '4px', textAlign: 'center' }}
            >
              Teaching Staff
            </HeadTableCell>
            <HeadTableCell sx={{ border: '1px solid #ddd', color: '#ffffff', padding: '4px', textAlign: 'center' }} colSpan={4}>Administrative Staff</HeadTableCell>
          </TableRow>
          <TableRow>
            <HeadTableCell sx={{ border: '1px solid #ddd', color: '#ffffff', padding: '4px', textAlign: 'center' }}>Male</HeadTableCell>
            <HeadTableCell sx={{ border: '1px solid #ddd', color: '#ffffff', padding: '4px', textAlign: 'center' }}>Female</HeadTableCell>
            <HeadTableCell sx={{ border: '1px solid #ddd', color: '#ffffff', padding: '4px', textAlign: 'center' }}>Others</HeadTableCell>

            <HeadTableCell sx={{ border: '1px solid #ddd', color: '#ffffff', padding: '4px', textAlign: 'center' }}>
              total
            </HeadTableCell>
            <HeadTableCell sx={{ border: '1px solid #ddd', color: '#ffffff', padding: '4px', textAlign: 'center' }}>Male</HeadTableCell>
            <HeadTableCell sx={{ border: '1px solid #ddd', color: '#ffffff', padding: '4px', textAlign: 'center' }}>Female</HeadTableCell>
            <HeadTableCell sx={{ border: '1px solid #ddd', color: '#ffffff', padding: '4px', textAlign: 'center' }}>Others</HeadTableCell>

            <HeadTableCell sx={{ border: '1px solid #ddd', color: '#ffffff', padding: '4px', textAlign: 'center' }}>
              total
            </HeadTableCell>
            <HeadTableCell sx={{ border: '1px solid #ddd', color: '#ffffff', padding: '4px', textAlign: 'center' }}>Male</HeadTableCell>
            <HeadTableCell sx={{ border: '1px solid #ddd', color: '#ffffff', padding: '4px', textAlign: 'center' }}>Female</HeadTableCell>
            <HeadTableCell sx={{ border: '1px solid #ddd', color: '#ffffff', padding: '4px', textAlign: 'center' }}>Others</HeadTableCell>

            <HeadTableCell sx={{ border: '1px solid #ddd', color: '#ffffff', padding: '4px', textAlign: 'center' }}>total</HeadTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((institution, index) => {
            const studentMale =
              institution.students.find((s) => s.gender === "male")?.count || 0;
            const studentFemale =
              institution.students.find((s) => s.gender === "female")?.count ||
              0;
              const studentOthers =
              institution.students.find((s) => s.gender === "other")?.count ||
              0;
            const studentTotal = studentMale + studentFemale + studentOthers;

            const teachingMale =
              institution.teachingStaff.find((s) => s.gender === "male")
                ?.count || 0;
            const teachingFemale =
              institution.teachingStaff.find((s) => s.gender === "female")
                ?.count || 0;
                const teachingOthers =
              institution.teachingStaff.find((s) => s.gender === "other")
                ?.count || 0;
            const teachingTotal = teachingMale + teachingFemale + teachingOthers;

            const adminMale =
              institution.administrativeStaff.find((s) => s.gender === "male")
                ?.count || 0;
            const adminFemale =
              institution.administrativeStaff.find((s) => s.gender === "female")
                ?.count || 0;
                const adminOthers =
              institution.administrativeStaff.find((s) => s.gender === "other")
                ?.count || 0;
            const adminTotal = adminMale + adminFemale + adminOthers;

            return (
              <TableRow key={index}>
                <TableCell sx={{ border: '1px solid #c2c2c2', padding: '4px',  }}>{index + 1}</TableCell>
                <TableCell
                  sx={{ border: '1px solid #c2c2c2', padding: '4px' }}
                >
                  {institution.institutionName}
                </TableCell>
                <TableCell
                  sx={{ border: '1px solid #c2c2c2', padding: '4px', textAlign: 'right' }}
                >
                  {institution.noOfCOllege}
                </TableCell>
                <TableCell sx={{ border: '1px solid #c2c2c2', padding: '4px', textAlign: 'right' }}>{studentMale}</TableCell>
                <TableCell sx={{ border: '1px solid #c2c2c2', padding: '4px', textAlign: 'right' }}>{studentFemale}</TableCell>
                <TableCell sx={{ border: '1px solid #c2c2c2', padding: '4px', textAlign: 'right' }}>{studentOthers}</TableCell>

                <TableCell sx={{ border: '1px solid #c2c2c2', padding: '4px', textAlign: 'right' }}>
                  {studentTotal}
                </TableCell>
                <TableCell sx={{ border: '1px solid #c2c2c2', padding: '4px', textAlign: 'right' }}>{teachingMale}</TableCell>
                <TableCell sx={{ border: '1px solid #c2c2c2', padding: '4px', textAlign: 'right' }}>{teachingFemale}</TableCell>
                <TableCell sx={{ border: '1px solid #c2c2c2', padding: '4px', textAlign: 'right' }}>{teachingOthers}</TableCell>

                <TableCell sx={{ border: '1px solid #c2c2c2', padding: '4px', textAlign: 'right' }}>
                  {teachingTotal}
                </TableCell>
                <TableCell sx={{ border: '1px solid #c2c2c2', padding: '4px', textAlign: 'right' }}>{adminMale}</TableCell>
                <TableCell sx={{ border: '1px solid #c2c2c2', padding: '4px', textAlign: 'right' }}>{adminFemale}</TableCell>
                <TableCell sx={{ border: '1px solid #c2c2c2', padding: '4px', textAlign: 'right' }}>{adminOthers}</TableCell>

                <TableCell sx={{ border: '1px solid #c2c2c2', padding: '4px', textAlign: 'right' }}>{adminTotal}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default UgcTable;
