import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Grid,
  Typography,
  TextField,
  CardContent,
  Paper,
  Button,
  TableBody,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  Dialog,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from "@mui/material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { toast } from "react-toastify";
import axios from "axios";
import OtherSetupAppBar from "../../../modules/navbar/OtherSetupAppBar";
const backendUrl = process.env.REACT_APP_BACKEND_URL; // Ensure you set REACT_APP_BACKEND_URL in your .env file

const FeeType = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [getBatchData, setGetBatchData] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedBatchId, setSelectedBatchId] = useState(null);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${backendUrl}/FeeType`); // Changed to GET request for fetching data
      setGetBatchData(response.data); // Ensure you access `data` from the response object
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onSubmit = async (data) => {
    try {
      // Convert the string values to boolean
      const formData = {
        id: 0, // Assuming you don't need to provide an ID on creation
        name: data.name,
        isExamFee: data.isExamFee === "true", // Convert string to boolean
        isFormFee: data.isFormFee === "true", // Convert string to boolean
      };

      await axios.post(`${backendUrl}/FeeType`, formData);
      toast.success("Data added successfully!", {
        autoClose: 2000,
      });
      fetchData();
      reset();
    } catch (err) {
      toast.error("Failed to add data!");
    }
  };

  const handleEditClick = (BatchId) => {
    setSelectedBatchId(BatchId);
    setOpenEditDialog(true);
  };

  const handleUpdate = () => {
    fetchData(); // Fetch updated data after edit
  };

  return (
    <>
    <Box sx={{
        margin: 0, padding: 0,
        height: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}>
        <Box mt={8}>
        <OtherSetupAppBar/>
        </Box>
       </Box>
    <Grid container spacing={2}>
      <Grid item xs={false} md={2} />
      <Grid item xs={12} md={8}>
        <Paper elevation={5} sx={{ borderRadius: "20px" }}>
          <CardContent>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ textAlign: "center", color: "#2A629A" }}
            >
              Fee Type Setup
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    {...register("name", { required: "Name is required" })}
                    size="small"
                    type="text"
                    label="Fee Type Name"
                    fullWidth
                    autoComplete="given-name"
                    error={!!errors.name}
                    helperText={errors.name?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Status</InputLabel>
                    <Select
                      {...register("isActive")}
                      size="small"
                      label="Status"
                      fullWidth
                      defaultValue={true}
                    >
                      <MenuItem value={true}>
                        <span style={{ color: "green" }}>Active</span>
                      </MenuItem>
                      <MenuItem value={false}>
                        <span style={{ color: "red" }}>Inactive</span>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Exam Fee</InputLabel>
                    <Select
                      {...register("isExamFee")}
                      size="small"
                      label="Exam Fee"
                      fullWidth
                      defaultValue={false}
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Form Fee</InputLabel>
                    <Select
                      {...register("isFormFee")}
                      size="small"
                      label="Form Fee"
                      fullWidth
                      defaultValue={false}
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                container
                style={{
                  margin: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: "#007aff", color: "#inherit" }}
                >
                  Submit
                </Button>
              </Grid>
            </form>
          </CardContent>
        </Paper>
        <Grid margin="10px">
          <Typography
            variant="h6"
            gutterBottom
            sx={{ textAlign: "center", color: "#2A629A" }}
          >
            List of Fee Types
          </Typography>
          <TableContainer>
            <Table
              style={{ borderCollapse: "collapse", border: "1px solid #ddd" }}
            >
              <TableHead style={{ backgroundColor: "#2A629A" }}>
                <TableRow>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    S.No
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    Fee Type Name
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getBatchData.map((data, index) => (
                  <TableRow key={data.id}>
                    <TableCell
                      style={{ border: "1px solid #ddd", padding: "8px" }}
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell
                      style={{ border: "1px solid #ddd", padding: "8px" }}
                    >
                      {data.name}
                    </TableCell>
                    <TableCell
                      style={{ border: "1px solid #ddd", padding: "8px" }}
                    >
                      {data.isActive ? (
                        <span style={{ color: "green" }}>Active</span>
                      ) : (
                        <span style={{ color: "red" }}>Inactive</span>
                      )}
                    </TableCell>
                    <TableCell
                      style={{ border: "1px solid #ddd", padding: "8px" }}
                    >
                      <Button onClick={() => handleEditClick(data.id)}>
                        <EditNoteIcon />
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Dialog
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        maxWidth="md"
      >
        {/* <EditBatch
          id={selectedBatchId}
          onClose={() => setOpenEditDialog(false)}
          onUpdate={handleUpdate}
        /> */}
      </Dialog>
    </Grid>
    </>
  );
};

export default FeeType;
