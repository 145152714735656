import { Box, TextField, Typography, Button, Grid, Paper, IconButton } from '@mui/material';
import React, { useState } from 'react';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { blue } from '@mui/material/colors';
import UserAppBar from '../navbar/UserAppBar';

const AddModule = () => {
  const [imagePreview, setImagePreview] = useState(null);

  // Handle image upload and preview
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
    <Box sx={{
      margin: 0, padding: 0,
      height: '100%',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    }}>
      <Box mt={8}>
      <UserAppBar/>
      </Box>
     </Box>
    <Box
      sx={{
        padding: 3,
        maxWidth: 600,
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#f9f9f9',
        borderRadius: 2,
        boxShadow: 3,
      }}
    >
      <Typography variant="h5" align="center" sx={{ fontWeight: 500, padding:1, color: blue[700]}}>
        Add Module
      </Typography>
      <form>
        <Grid container spacing={2}>
          {/* Module Name */}
          <Grid item xs={6}>
            <TextField
              required
              id="moduleName"
              label="Module Name"
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              id="status"
              label="Status"
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>

          {/* Details */}
          <Grid item xs={12}>
            <TextField
              required
              id="details"
              label="Description"
              size="small"
              fullWidth
              multiline
              rows={2}
              variant="outlined"
              sx={{
                borderColor: '#3f51b5',
              }}
            />
          </Grid>

          {/* Module Icon */}
          <Grid item xs={12}>
            <Typography variant="subtitle1" sx={{ mb: 1 }}>
              Upload Module Icon
            </Typography>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: '1px dashed #3f51b5',
                borderRadius: 2,
                padding: 2,
                cursor: 'pointer',
                backgroundColor: '#fafafa',
              }}
            >
              <input
                type="file"
                id="moduleIcon"
                accept="image/*"
                hidden
                onChange={handleImageChange}
              />
              <label htmlFor="moduleIcon">
                <IconButton
                  component="span"
                  sx={{
                    backgroundColor: '#3f51b5',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: '#303f9f',
                    },
                  }}
                >
                  <PhotoCameraIcon />
                </IconButton>
              </label>

              {/* Preview Image */}
              {imagePreview && (
                <Box
                  sx={{
                    marginLeft: 2,
                    width: 60,
                    height: 60,
                    borderRadius: '50%',
                    overflow: 'hidden',
                    boxShadow: 1,
                  }}
                >
                  <img
                    src={imagePreview}
                    alt="Module Icon Preview"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                  />
                </Box>
              )}
            </Box>
          </Grid>

          {/* Status */}
          

          {/* Submit Button */}
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size='small' 
            >
              Add Module
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
    </>
  );
};

export default AddModule;
