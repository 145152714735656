import React from "react";
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Button,
  FormControl,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { createContext, useContext, useState } from "react";
import useAddressData from "../address/address";
import { Controller, useForm } from "react-hook-form";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ImageUploader from "../Reusable-component/ImageUploader";
const UniversityInfoContext = createContext();

const UniversityInfoProvider = ({ children }) => {
  const methods = useForm();

  const [universityInfo, setUniversityInfo] = useState({
    type: "",
    name: "",
    code: "",
    shortCode: "",
    alias: "",
    phone: "",
    email: "",
    url: "",
    province: "",
    district: "",
    localLevel: "",
    wardNo: "",
    locality: "",
    logo: "",
    universityLogo: null,
  });
  const getLogoURL = () => {
    return universityInfo.universityLogo ? URL.createObjectURL(universityInfo.universityLogo) : null;
  };
  const onChange = (event, name, value) => {
    if (event && event.target && event.target.files) {
      const file = event.target.files[0];
      setUniversityInfo((prevState) => ({
        ...prevState,
        universityLogo: file,
        logo: file.name,
      }));
    } else {
      setUniversityInfo((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  return (
    <UniversityInfoContext.Provider value={{ ...methods, universityInfo, onChange, getLogoURL }}>
      {children}
    </UniversityInfoContext.Provider>
  );
};

const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": {
    borderColor: "#c2c2c2",
    borderWidth: 1,
  },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 4,
    padding: "4px !important", // override inline-style
  },
});
const ValidationSelect = styled(Select)({
  "& select:valid + fieldset": {
    borderColor: "#3572EF",
    borderWidth: 1,
  },
  "& select:invalid + fieldset": {
    borderColor: "#ff0000", // Custom invalid border color
    borderWidth: 1,
  },
  "& select:valid:focus + fieldset": {
    borderLeftWidth: 4,
    padding: "4px !important", // override inline-style
  },
});

const UniversityInfo = ({ handleNext, handleBack }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useContext(UniversityInfoContext);
  const { universityInfo, onChange } = useContext(UniversityInfoContext);

  const {
    uniqueProvinces,
    uniqueDistricts,
    uniqueLocalLevels,
    selectedProvince,
    setSelectedProvince,
    selectedDistrict,
    setSelectedDistrict,
    noOfWards
  } = useAddressData();

  const onSubmit = (data) => {
    // Assuming data includes all fields from the form
    Object.keys(data).forEach(key => {
      onChange(null, key, data[key]);
    });
    handleNext();
  };
  const onBack = () => {
    handleBack();
  };
  const getWardOptions = (localLevel) => {
    const wards = noOfWards[localLevel] || 0;
    return Array.from({ length: wards }, (_, i) => i + 1);
  };



  return (
    <Grid
      container
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      onReset={() => {
        onBack();
      }}
    >
      <Grid mt='.5rem' container spacing={1}>
        <Grid item xs={12} sm={3}>
          <FormControl size="small" fullWidth>
            <InputLabel id="type" required>
              University Type
            </InputLabel>
            <Controller
              name="type"
              control={control}
              defaultValue="national" // Set the default value here
              render={({ field }) => (
                <ValidationSelect
                  {...field}
                  labelId="type"
                  id="type"
                  name="type"
                  label="University Type"
                  fullWidth
                >
                  <MenuItem value="">
                    {" "}
                    {/* This will select the default value */}
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="central">Central University</MenuItem>
                  <MenuItem value="medical">Medical Academies</MenuItem>
                  <MenuItem value="provencial">Provencial University</MenuItem>
                  <MenuItem value="foreign">Foreign</MenuItem>
                </ValidationSelect>
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4.5}>
          {/* <FormControl fullWidth required> */}
          <Controller
            name="name"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <ValidationTextField
                required
                {...field}
                id="name"
                size="small"
                name="name"
                label="University Name"
                fullWidth

              />
            )}
          />
          {/* </FormControl> */}
        </Grid>
        <Grid item xs={12} sm={2.5}>
          <Controller
            name="code"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <ValidationTextField
                {...field}
                id="code"
                size="small"
                name="code"
                label="University Code"
                fullWidth

              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Controller
            name="shortCode"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <ValidationTextField
                {...field}
                required
                id="shortCode"
                size="small"
                name="shortCode"
                label="Short Name"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller
            name="alias"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <ValidationTextField
                {...field}
                id="alias"
                size="small"
                name="alias"
                label="Alias"
                fullWidth
                error={!!errors.alias}
                helpertext={errors.alias}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={2.5}>
          <Controller
            name="phone"
            control={control}
            rules={{
              validate: (value) => {
                const isValidPhone = /^[9]\d{9}$/.test(value);
                return isValidPhone || "invalid phone umber";
              },
            }}
            defaultValue=""
            render={({ field }) => (
              <ValidationTextField
                {...field}
                required
                id="phone"
                type="text"
                name="phone"
                label="Phone Number"
                InputProps={{
                  placeholder: "98XXXXXXXX",
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                  onInput: (e) => {
                    e.target.value = e.target.value
                      .replace(/[^0-9]/g, "")
                      .slice(0, 10); // Limit input to 10 characters
                  },
                }}
                fullWidth
                autoComplete="phone number"
                size="small"
                error={!!errors.phone}
                helpertext={errors.phone ? errors.phone.message : ""}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller
            name="email"
            control={control}
            rules={{
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Email format not matched",
              },
            }}
            defaultValue=""
            render={({ field }) => (
              <ValidationTextField
                {...field}
                required
                id="email"
                name="email"
                type="email"
                size="small"
                label="Email"
                fullWidth
                error={!!errors.email}
                helpertext={errors.email && errors.email.message} // Check if errors.email exists before accessing its message property
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={3.5}>
          <FormControl size="small" fullWidth>
            <InputLabel id="province" required>
              Province
            </InputLabel>
            <Controller
              name="province"
              control={control}
              rules={{ required: "Province is required" }}
              defaultValue={selectedProvince || ""} // Set the defaultValue to the selected province or an empty string
              render={({ field }) => (
                <ValidationSelect
                  {...field}
                  labelId="provinceCode"
                  label="Province"
                  size="small"
                  onChange={(e) => {
                    const value = e.target.value;
                    setSelectedProvince(value);
                    setSelectedDistrict(null);
                    field.onChange(value); // Manually trigger the field's onChange event
                  }}
                  error={!!errors.province}
                  helpertext={errors.province ? errors.province.message : ""}
                >
                  {uniqueProvinces.map((province) => {

                    return (
                      <MenuItem key={province} value={province}>
                        {province}
                      </MenuItem>
                    );
                  })}
                </ValidationSelect>
              )}
            />
          </FormControl>
        </Grid>
        {/* District select */}
        <Grid item xs={12} sm={3}>
          <FormControl size="small" fullWidth>
            <InputLabel id="district" required>
              District
            </InputLabel>
            <Controller
              name="district"
              control={control}
              rules={{ required: "District is required" }}
              defaultValue={selectedDistrict || ""}
              render={({ field }) => (
                <ValidationSelect
                  {...field}
                  labelId="district"
                  label="District"
                  size="small"
                  // value={selectedDistrict || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    setSelectedDistrict(value);
                    field.onChange(value); // Manually trigger the field's onChange event
                  }}
                  error={!!errors.district}
                  helpertext={errors.district ? errors.district.message : ""}
                >
                  {uniqueDistricts.map((district) => {

                    return (
                      <MenuItem key={district} value={district}>
                        {district}
                      </MenuItem>
                    );
                  })}
                </ValidationSelect>
              )}
            />
          </FormControl>
        </Grid>
        {/* Local Level select */}

        <Grid item xs={12} sm={3.5}>
          <FormControl size="small" fullWidth>
            <InputLabel id="localLevel" required>
              Local Level
            </InputLabel>
            <Controller
              name="localLevel"
              control={control}
              rules={{ required: "Local Level is required" }}
              defaultValue={universityInfo.localLevel || ""}
              render={({ field }) => (
                <ValidationSelect
                  {...field}
                  labelId="localLevel"
                  label="Local Level"
                  size="small"
                  onChange={(e) => {
                    const value = e.target.value;
                    field.onChange(value); // Update the form field value
                    onChange(null, 'localLevel', value); // Update the state value
                  }}
                  error={!!errors.localLevel}
                  helperText={errors.localLevel ? errors.localLevel.message : ""}
                >
                  {uniqueLocalLevels.map((localLevel) => (
                    <MenuItem key={localLevel} value={localLevel}>
                      {localLevel}
                    </MenuItem>
                  ))}
                </ValidationSelect>
              )}
            />
          </FormControl>
        </Grid>


        <Grid item xs={12} sm={2}>
          <FormControl size="small" fullWidth>
            <InputLabel id="wardNo" required>
              Ward No
            </InputLabel>
            <Controller
              name="wardNo"
              control={control}
              defaultValue={universityInfo.wardNo || ""}
              render={({ field }) => (
                <ValidationSelect
                  {...field}
                  labelId="wardNo"
                  label="Ward No"
                  size="small"
                  onChange={(e) => {
                    const value = e.target.value;
                    field.onChange(value); // Manually trigger the field's onChange event
                  }}
                  error={!!errors.wardNo}
                  helperText={errors.wardNo ? errors.wardNo.message : ""}
                >
                  {getWardOptions(universityInfo.localLevel).map((ward) => (
                    <MenuItem key={ward} value={ward}>
                      {ward}
                    </MenuItem>
                  ))}
                </ValidationSelect>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3.5}>
          <Controller
            name="locality"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <ValidationTextField
                {...field}

                id="locality"
                name="locality"
                size="small"
                label="Tole"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="url"
            control={control}
            defaultValue=""
            rules={{
              pattern: {
                value: /^(ftp|http|https):\/\/[^ "]+$/i,
                message: "Invalid URL",
              },
            }}
            render={({ field }) => (
              <ValidationTextField
                {...field}
                id="url"
                name="url"
                size="small"
                label="URL"
                placeholder="https://domain.com"
                fullWidth
                error={!!errors.url}
                helpertext={errors.url && errors.url.message}
              />
            )}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <FormControl>
            <ImageUploader
              allowMultiple={false}
              onImagesChange={(newImages) => {
                if (newImages.length > 0) {
                  onChange(null, "universityLogo", newImages[0]);
                } else {
                  onChange(null, "universityLogo", null);
                }
              }}
              name="universityLogo"
              placeholder="University Logo"
              required
            />
          </FormControl>
        </Grid>
      </Grid>
      <Box mt={2} display="flex" justifyContent="space-between">
        <Button
          variant="outlined"
          color="error"
          size="small"
          type="submit"
          disabled
          sx={{
          }}
          startIcon={<ChevronLeftRoundedIcon />}
        >
          Back
        </Button>
        <Button
          variant="outlined"
          size="small"
          type="submit"
          sx={{
            marginLeft: "10px",
          }}
          endIcon={<ChevronRightRoundedIcon />}
        >
          Next
        </Button>
      </Box>
    </Grid>
  );
};
export { UniversityInfoProvider, UniversityInfoContext };
export default UniversityInfo;
