import React from "react";
import StudentAppBar from "../navbar/studentAppBar";
import { Box } from "@mui/material";
import StudentStepper from "../../components/studentRegistration/StudentStepper";
import { useSelector } from "react-redux";

const StudentManagementHome = () => {
  const { currentUser } = useSelector((state) => state.user);
  const roleName = currentUser?.listUser?.[0]?.roleName || currentUser?.role;
  return (
    <Box
      sx={{
        margin: 0,
        padding: 0,
        height: "100%",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box mt={0}>
        <StudentAppBar />
        {roleName === "CollegeAdmin" && <StudentStepper />}
      </Box>
    </Box>
  );
};

export default StudentManagementHome;
