import * as React from "react";
import Autocomplete from '@mui/material/Autocomplete';
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useForm } from "react-hook-form";
import {  getUniversityById } from "../../services/services";
import { getEmployees } from "../../components/dashboard/services/service";
import ViewUniversityRegisterUser from "./ViewUniversityRegisterUser";

const defaultTheme = createTheme();

const RegisterUniversityUser = ({ id }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [universityData, setUniversityData] = React.useState([]);
  const [studentData, setStudentData] = React.useState([]);

  // Fetch campus and student data on component mount or when ID changes
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getUniversityById(id);
        const getStudentData = await getEmployees();
        setStudentData(getStudentData.filter(data => data && data.campus.universityId === id));
        setUniversityData(Array.isArray(response) ? response : [response]);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [id]);

  // Prepare options for Autocomplete based on student data
  const allOptions = studentData.map(data => ({
    label: `${data.firstName} ${data.middleName} ${data.lastName}, ${data.email}, ${data.phoneNumber}`,
    value: data.id, 
  }));

  const onSubmit = async (data) => {
    try {
      
      reset();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <div style={{ marginTop: 8, display: "flex", flexDirection: "column", alignItems: "center" }}>
          {universityData.length > 0 && (
            <Typography variant="h6" color="primary" style={{ textDecoration: "underline", marginBottom: 10 }}>
              Register User in {universityData[0].name}
            </Typography>
          )}
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
                <Autocomplete
                  disablePortal
                  id="student-autocomplete"
                  options={allOptions}
                  size="small"
                  getOptionLabel={(option) => option.label}
                  sx={{ width: "100%" }}
                  renderInput={(params) => <TextField {...params} label="Select User" />}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth size="small">
                  <InputLabel>User Type</InputLabel>
                  <Select
                    {...register("userType", { required: true })}
                    fullWidth
                    size="small"
                    name="userType"
                    label='User Type'
                    id="userType"
                    error={!!errors.userType}
                    defaultValue=""
                  >
                    <MenuItem value="Admin">Admin</MenuItem>
                    <MenuItem value="User">User</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container justifyContent="center" style={{ marginTop: 20 }}>
              <Grid item>
                <Button type="submit" variant="contained" color="primary">
                  Register User
                </Button>
              </Grid>
            </Grid>
          </form>
          <ViewUniversityRegisterUser/>
        </div>
      </Container>
    </ThemeProvider>
  );
};

export default RegisterUniversityUser;
