import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Box,
  Button,
  Popover,
  Grid,
} from "@mui/material";
import { FileDownload, InsertDriveFile, PictureAsPdf } from "@mui/icons-material";
import { blue } from "@mui/material/colors";
import React, { useState } from "react";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";

const CampusTypeEnrollmentByYear = () => {
  const [selectedLevel, setSelectedLevel] = useState("All");
  const [anchorEl, setAnchorEl] = useState(null);

  const rows = [
    {
      id: 1,
      campusType: "Constituent",
      university: "TU",
      "2016/17": 1200,
      "2017/18": 1300,
      "2018/19": 1400,
      "2019/20": 1500,
      "2020/21": 1600,
      total: 8000,
      level: "Undergraduate",
    },
    {
      id: 2,
      campusType: "Community",
      university: "KU",
      "2016/17": 1000,
      "2017/18": 1100,
      "2018/19": 1200,
      "2019/20": 1300,
      "2020/21": 1400,
      total: 7000,
      level: "Masters",
    },
    {
      id: 3,
      campusType: "Private",
      university: "PU",
      "2016/17": 900,
      "2017/18": 950,
      "2018/19": 1000,
      "2019/20": 1100,
      "2020/21": 1200,
      total: 5650,
      level: "Undergraduate",
    },
  ];

  // Filter rows based on selected level
  const filteredRows = rows.filter((row) => selectedLevel === "All" || row.level === selectedLevel);

  // Calculate totals for each column and the overall total
  const totals = filteredRows.reduce(
    (acc, row) => {
      acc["2016/17"] += row["2016/17"];
      acc["2017/18"] += row["2017/18"];
      acc["2018/19"] += row["2018/19"];
      acc["2019/20"] += row["2019/20"];
      acc["2020/21"] += row["2020/21"];
      acc.total += row.total;
      return acc;
    },
    {
      "2016/17": 0,
      "2017/18": 0,
      "2018/19": 0,
      "2019/20": 0,
      "2020/21": 0,
      total: 0,
    }
  );

  // Open the export popover
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Close the export popover
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "export-popover" : undefined;

  // Export data to Excel
  const exportToExcel = () => {
    const header = [
      [
        "S.No.",
        "Campus Type",
        "2016/17",
        "2017/18",
        "2018/19",
        "2019/20",
        "2020/21",
        "Total",
      ],
    ];

    const data = filteredRows.map((row, index) => [
      index + 1,
      row.campusType,
      row["2016/17"],
      row["2017/18"],
      row["2018/19"],
      row["2019/20"],
      row["2020/21"],
      row.total,
    ]);

    const ws = XLSX.utils.aoa_to_sheet([...header, ...data]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Campus Enrollment");
    XLSX.writeFile(wb, "campus_enrollment_by_year.xlsx");
  };

  // Export data to PDF
  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.setFontSize(12);
    const columns = [
      "S.No.",
      "Campus Type",
      "2016/17",
      "2017/18",
      "2018/19",
      "2019/20",
      "2020/21",
      "Total",
    ];
    const data = filteredRows.map((row, index) => [
      index + 1,
      row.campusType,
      row["2016/17"],
      row["2017/18"],
      row["2018/19"],
      row["2019/20"],
      row["2020/21"],
      row.total,
    ]);
    doc.autoTable(columns, data);
    doc.save("campus_enrollment_by_year.pdf");
  };

  return (
    <div>
      {/* Level Filter */}
      <Box sx={{ marginBottom: "10px", display: "flex", gap: 2 }}>
        <FormControl fullWidth sx={{ maxWidth: "30%" }} size="small">
          <InputLabel>Level</InputLabel>
          <Select
            size="small"
            sx={{
              backgroundColor: '#fff',
              borderRadius: 2,
              borderColor: '#c2c2c2',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#c2c2c2',
                },
                '&:hover fieldset': {
                  borderColor: blue[500], 
                },
                '&.Mui-focused fieldset': {
                  borderColor: blue[700], 
                  boxShadow: `0 0 0 2px ${blue[100]}`,
                },
              },
              '& .MuiInputLabel-root': {
                fontSize: '1rem',
              },
              '& .MuiSelect-icon': {
                color: blue[700], 
              },
            }}
            value={selectedLevel}
            onChange={(e) => setSelectedLevel(e.target.value)}
            label="Level"
          >
            <MenuItem value="All">All Level</MenuItem>
            <MenuItem value="Undergraduate">Undergraduate</MenuItem>
            <MenuItem value="Masters">Masters</MenuItem>
          </Select>
        </FormControl>
        {/* Export Button with Popover */}
        <Grid container justifyContent="right">
        <Button 
          variant="contained" 
          color="primary" 
          startIcon={<FileDownload />} 
          onClick={handleClick}
        >
          Export
        </Button>

        {/* Popover for Excel and PDF options */}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Box sx={{ padding: "10px" }}>
            <Button
              variant="contained"
              color="primary"
              sx={{ backgroundColor: "#5FAD41" }}
              startIcon={<InsertDriveFile />}
              onClick={exportToExcel}
              fullWidth
              style={{ marginBottom: "10px" }}
            >
              Excel
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: "#272727" }}
              startIcon={<PictureAsPdf />}
              onClick={exportToPDF}
              fullWidth
            >
              PDF
            </Button>
          </Box>
        </Popover>
        </Grid>
      </Box>

      {/* Table */}
      <TableContainer sx={{ border: "1px solid #ddd" }}>
        <Table style={{ borderCollapse: "collapse", border: "1px solid #ddd" }}>
          <TableHead style={{ backgroundColor: "#2A629A" }}>
            <TableRow>
              <TableCell sx={{ border: "1px solid #ddd", color: "#ffffff", padding: "4px", textAlign: "center" }}>
                S.No.
              </TableCell>
              <TableCell sx={{ border: "1px solid #ddd", color: "#ffffff", padding: "4px", textAlign: "center" }}>
                Campus Type
              </TableCell>
              <TableCell sx={{ border: "1px solid #ddd", color: "#ffffff", padding: "4px", textAlign: "center" }}>
                2016/17
              </TableCell>
              <TableCell sx={{ border: "1px solid #ddd", color: "#ffffff", padding: "4px", textAlign: "center" }}>
                2017/18
              </TableCell>
              <TableCell sx={{ border: "1px solid #ddd", color: "#ffffff", padding: "4px", textAlign: "center" }}>
                2018/19
              </TableCell>
              <TableCell sx={{ border: "1px solid #ddd", color: "#ffffff", padding: "4px", textAlign: "center" }}>
                2019/20
              </TableCell>
              <TableCell sx={{ border: "1px solid #ddd", color: "#ffffff", padding: "4px", textAlign: "center" }}>
                2020/21
              </TableCell>
              <TableCell sx={{ border: "1px solid #ddd", color: "#ffffff", padding: "4px", textAlign: "center" }}>
                Total
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {filteredRows.map((row, index) => (
              <TableRow key={row.id}>
                <TableCell sx={{ border: "1px solid #c2c2c2", padding: "4px", textAlign: "center" }}>
                  {index + 1}
                </TableCell>
                <TableCell sx={{ border: "1px solid #c2c2c2", padding: "4px", textAlign: "left" }}>
                  {row.campusType}
                </TableCell>
                <TableCell sx={{ border: "1px solid #c2c2c2", padding: "4px", textAlign: "right" }}>
                  {row["2016/17"]}
                </TableCell>
                <TableCell sx={{ border: "1px solid #c2c2c2", padding: "4px", textAlign: "right" }}>
                  {row["2017/18"]}
                </TableCell>
                <TableCell sx={{ border: "1px solid #c2c2c2", padding: "4px", textAlign: "right" }}>
                  {row["2018/19"]}
                </TableCell>
                <TableCell sx={{ border: "1px solid #c2c2c2", padding: "4px", textAlign: "right" }}>
                  {row["2019/20"]}
                </TableCell>
                <TableCell sx={{ border: "1px solid #c2c2c2", padding: "4px", textAlign: "right" }}>
                  {row["2020/21"]}
                </TableCell>
                <TableCell sx={{ border: "1px solid #c2c2c2", padding: "4px", textAlign: "right" }}>
                  {row.total}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>

          {/* Grand Total Row */}
          <TableRow style={{ backgroundColor: "#c2c2c2" }}>
            <TableCell
              colSpan={2}
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Grand Total
            </TableCell>
            <TableCell sx={{ border: "1px solid #ddd", padding: "4px", textAlign: "right" }}>
              {totals["2016/17"]}
            </TableCell>
            <TableCell sx={{ border: "1px solid #ddd", padding: "4px", textAlign: "right" }}>
              {totals["2017/18"]}
            </TableCell>
            <TableCell sx={{ border: "1px solid #ddd", padding: "4px", textAlign: "right" }}>
              {totals["2018/19"]}
            </TableCell>
            <TableCell sx={{ border: "1px solid #ddd", padding: "4px", textAlign: "right" }}>
              {totals["2019/20"]}
            </TableCell>
            <TableCell sx={{ border: "1px solid #ddd", padding: "4px", textAlign: "right" }}>
              {totals["2020/21"]}
            </TableCell>
            <TableCell sx={{ border: "1px solid #ddd", padding: "4px", textAlign: "right" }}>
              {totals.total}
            </TableCell>
          </TableRow>
        </Table>
      </TableContainer>
    </div>
  );
};

export default CampusTypeEnrollmentByYear;
