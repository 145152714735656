import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import { Paper, Typography, Grid } from "@mui/material";
import { getStudentByPrograms } from "./services/service";

const CampusProgram = ({ authToken }) => {
  const [studentsByPrograms, setStudentsByPrograms] = useState([]);

  useEffect(() => {
    if (!authToken) return;

    const fetchData = async () => {
      try {
        const byPrograms = await getStudentByPrograms(authToken);
        setStudentsByPrograms(byPrograms);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [authToken]);

  const generateChartOption = () => {
    const categories = ["Male", "Female", "Other"];
    const colors = ["#1976d2", "#FABC3F", "#5e5e5e"];
    const programs = studentsByPrograms.map((item) => item.title);

    const seriesData = categories
      .map((category) => ({
        name: category,
        type: "bar",
        barWidth: 30,
        barGap: "10%",
        itemStyle: {
          color: colors[categories.indexOf(category)],
        },
        label: { show: true, position: "top", color: "#000" },
        data: studentsByPrograms.map((item) => {
          switch (category) {
            case "Male":
              return item.male;
            case "Female":
              return item.female;
            case "Other":
              return item.other;
            default:
              return 0;
          }
        }),
      }))
      .filter((series) => series.data.some((value) => value > 0)); // Filter out series with all zero data

    // Filter out xAxis data for any programs that have zero values across all categories
    const xAxisData = programs.filter((_, index) =>
      seriesData.some((series) => series.data[index] > 0)
    );

    return {
      tooltip: { trigger: "axis" },
      legend: { data: seriesData.map((s) => s.name) },
      toolbox: {
        show: true,
        feature: {
          dataView: { show: true, readOnly: false },
          restore: { show: true },
          saveAsImage: { show: true },
        },
      },
      grid: {
        top: "10%",
        bottom: "15%",
        left: "10%",
        right: "10%",
      }, // Added grid spacing to avoid bars being too close to the edge
      xAxis: [
        {
          type: "category",
          data: xAxisData,
          name: "Programs",
          nameLocation: "middle",
          nameGap: 35,
        },
      ],
      yAxis: [
        {
          type: "value",
          axisLine: {
            show: true,
          },
          name: "No. of Students",
          nameLocation: "middle",
          nameGap: 35,
        },
      ],
      series: seriesData,
    };
  };

  return (
    <Grid container justifyContent="center" alignItems="center" spacing={2}>
      <Grid item xs={12}>
        <Paper elevation={5} sx={{ padding: "20px" }}>
          <Typography
            variant="body1"
            align="center"
            fontWeight={900}
            style={{ padding: "1rem" }}
          >
            Students on the basis of Program
          </Typography>
          <ReactECharts
            option={generateChartOption()}
            style={{ height: "400px" }}
            opts={{ renderer: "canvas" }}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default CampusProgram;
