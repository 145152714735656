import React from 'react';
import { Grid, FormControl, InputLabel, Select, MenuItem, Typography, Box } from '@mui/material';
import { blue } from '@mui/material/colors';
import ProgramAppBar from '../../../modules/navbar/ProgramSetupAppBar';

const AddSections = () => {
  return (
    <div>
    <Box sx={{
      margin: 0, padding: 0,
      height: '100%',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    }}>
      <Box mt={8}>
      <ProgramAppBar/>
      
      </Box>
     </Box>
      <Typography 
        variant="h6" 
        component="p" 
        style={{ fontWeight: 'bold', color: blue[700], textAlign: 'center', padding: '20px' }}
      >
        Add Sections
      </Typography>

      <Grid container sx={{justifyContent: 'center', alignItems: 'center', gap: '20px'}}>
      <Grid xs={12} md={4}>
            <FormControl fullWidth size="small">
                <InputLabel id="programName" required>Program Name</InputLabel>
                <Select
                    required
                    id="programName"
                    size="small"
                    name="programName"
                    label="Program Name"
                    fullWidth
                >
                    <MenuItem value="1">Program 1</MenuItem>
                    <MenuItem value="2">Program 2</MenuItem>
                    <MenuItem value="3">Program 3</MenuItem>
                </Select>
            </FormControl>
        </Grid>
        <Grid xs={12} md={4} >
            <FormControl fullWidth size="small">
                <InputLabel id="sectionName" required>Section Name</InputLabel>
                <Select
                    required
                    id="sectionName"
                    size="small"
                    name="sectionName"
                    label="Section Name"
                    fullWidth
                    autoComplete="given-name"
                >
                    <MenuItem value="1">Section 1</MenuItem>
                    <MenuItem value="2">Section 2</MenuItem>
                    <MenuItem value="3">Section 3</MenuItem>
                </Select>
            </FormControl>
        </Grid>
      </Grid>
    </div>
  );
};

export default AddSections;
