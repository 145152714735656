import React, { useState } from "react";
import {
  Box,
  Step,
  StepLabel,
  Stepper,
  Typography,
  Grid
} from "@mui/material";
import UniversityInfo from "../../components/universityRegister/UniversityInfo";
import ContactDetails from "../../components/universityRegister/ContactDetails";
import ReviewDetails from "../../components/universityRegister/ReviewDetails";
import { UniversityInfoProvider } from "../../components/universityRegister/UniversityInfo";
import { UniversityContactProvider } from "../../components/universityRegister/ContactDetails";
import OrganizationAppBar from "../../modules/navbar/OrganizationAppBar";

const steps = ["University Info", "Estd. & Bank Details", "Review Details"];

export default function UniversityRegister() {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => setActiveStep((prevStep) => prevStep + 1);
  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);

  return (
    <div style={{marginBottom:'2rem'}}>
      <Box
        mb={2}
        sx={{
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box mt={8}>
          <OrganizationAppBar />
        </Box>
      </Box>
      <UniversityInfoProvider>
        <UniversityContactProvider>
          <Grid sm={12} md={10} lg={9} direction='column' container sx={{ mx: "auto" }}>
            <Typography
              variant="h5"
              mt={2}
              textAlign="center"
              p="10px"
              sx={{ color: "#2b6eb5" }}
            >
              Register University
            </Typography>
            <Stepper style={{ marginTop: '20px', marginBottom: '-15px' }} activeStep={activeStep} alternativeLabel>
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel>
                    <span
                      style={{
                        color: index <= activeStep ? "#2b6eb5" : "inherit",
                      }}
                    >
                      {label}
                    </span>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            <Box mt={2} mx={3}>
              {activeStep === 0 && (
                <UniversityInfo
                  handleNext={handleNext}
                  handleBack={handleBack}
                />
              )}
              {activeStep === 1 && (
                <ContactDetails
                  handleNext={handleNext}
                  handleBack={handleBack}
                />
              )}
              {activeStep === 2 && (
                <ReviewDetails handleBack={handleBack} />
              )}
            </Box>
          </Grid>
        </UniversityContactProvider>
      </UniversityInfoProvider>
    </div>
  );
}
