import React from "react";
import EmployeeAppBar from "../navbar/employeeAppBar";
import { Box } from "@mui/material";
import EmployeeRegister from "../../components/employeeRegister/EmployeeRegister";
import { useSelector } from "react-redux";
import TeachingStaff from "../../pages/employee/TeachingStaff";

const EmployeeManagementHome = () => {
  const { currentUser } = useSelector((state) => state.user);
  const roleName = currentUser?.listUser?.[0]?.roleName || currentUser?.role;
  return (
    <Box
      sx={{
        margin: 0,
        padding: 0,
        height: "100%",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box mt={0}>
        <EmployeeAppBar />
        {roleName === "CollegeAdmin" && <EmployeeRegister />}
        {roleName === 'UniAdmin' && <TeachingStaff/>}
      </Box>
    </Box>
  );
};

export default EmployeeManagementHome;
