// 2.3 Report
import {
  Box,
  Button,
  Grid,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import React, { useState } from "react";
import {
  FileDownload,
  PictureAsPdf,
  InsertDriveFile,
} from "@mui/icons-material";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";

const CampusInFaculty = () => {
  const rows = [
    {
      id: 1,
      university: "TU",
      agriculture: 10,
      avf: 20,
      Ayurveda: 30,
      forestry: 40,
      medicine: 40,
      engineering: 40,
      sAndT: 40,
      hss: 40,
      management: 40,
      education: 40,
      law: 50,
      buddhism: 90,
      sanskrit: 50,
    },
    {
      id: 2,
      university: "KU",
      agriculture: 10,
      avf: 20,
      Ayurveda: 30,
      forestry: 40,
      medicine: 40,
      engineering: 40,
      sAndT: 40,
      hss: 40,
      management: 40,
      education: 40,
      law: 50,
      buddhism: 90,
      sanskrit: 50,
    },
    {
      id: 3,
      university: "PU",
      agriculture: 10,
      avf: 20,
      Ayurveda: 30,
      forestry: 40,
      medicine: 40,
      engineering: 40,
      sAndT: 40,
      hss: 40,
      management: 40,
      education: 40,
      law: 50,
      buddhism: 90,
      sanskrit: 50,
    },
  ];

  // Calculate the totals for each column and the overall total
  const totals = rows.reduce(
    (acc, row) => {
      acc.agriculture += row.agriculture;
      acc.avf += row.avf;
      acc.ayurveda += row.Ayurveda;
      acc.forestry += row.forestry;
      acc.medicine += row.medicine;
      acc.engineering += row.engineering;
      acc.sAndT += row.sAndT;
      acc.hss += row.hss;
      acc.management += row.management;
      acc.education += row.education;
      acc.law += row.law;
      acc.buddhism += row.buddhism;
      acc.sanskrit += row.sanskrit;

      return acc;
    },
    {
      agriculture: 0,
      avf: 0,
      ayurveda: 0,
      forestry: 0,
      medicine: 0,
      engineering: 0,
      sAndT: 0,
      hss: 0,
      management: 0,
      education: 0,
      law: 0,
      buddhism: 0,
      sanskrit: 0,
    }
  );
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget); // Open the popover when button is clicked
  };

  const handleClose = () => {
    setAnchorEl(null); // Close the popover
  };
  const open = Boolean(anchorEl);
  const id = open ? "export-popover" : undefined;
  const exportToExcel = () => {
    const header = [
      [
        "S.No.",
        "University",
        "Agriculture",
        "AVF",
        "Ayurveda",
        "Forestry",
        "Medicine",
        "Enginnering",
        "S&T",
        "Hss",
        "Management",
        "Education",
        "Law",
        "Buddhism",
        "Sanskrit",
      ],
    ];

    const data = rows.map((row, index) => [
      index + 1,
      row.university,
      row.agriculture,
      row.avf,
      row.ayurveda,
      row.forestry,
      row.medicine,
      row.engineering,
      row.sAndT,
      row.hss,
      row.management,
      row.education,
      row.law,
      row.buddhism,
      row.sanskrit,
    ]);

    const ws = XLSX.utils.aoa_to_sheet([...header, ...data]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Faculty Campus");
    XLSX.writeFile(wb, "faculty_campus.xlsx");
  };

  // Export data to PDF
  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.setFontSize(12);
    const columns = [
      "S.No.",
      "University",
      "Agriculture",
      "AVF",
      "Ayurveda",
      "Forestry",
      "Medicine",
      "Enginnering",
      "S&T",
      "Hss",
      "Management",
      "Education",
      "Law",
      "Buddhism",
      "Sanskrit",
    ];
    const data = rows.map((row, index) => [
      index + 1,
      row.agriculture,
      row.avf,
      row.Ayurveda,
      row.forestry,
      row.medicine,
      row.engineering,
      row.sAndT,
      row.hss,
      row.management,
      row.education,
      row.law,
      row.buddhism,
      row.sanskrit,
    ]);
    doc.autoTable(columns, data);
    doc.save("institutions_status.pdf");
  };

  return (
    <div>
      {/* Export Button with Popover */}
      <Grid container justifyContent="right">
        <Button
          variant="contained"
          color="primary"
          startIcon={<FileDownload />}
          onClick={handleClick}
          style={{ marginBottom: "10px" }}
        >
          Export
        </Button>

        {/* Popover for Excel and PDF options */}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Box sx={{ padding: "10px" }}>
            <Button
              variant="contained"
              color="primary"
              sx={{ backgroundColor: "#5FAD41" }}
              startIcon={<InsertDriveFile />}
              onClick={exportToExcel}
              fullWidth
              style={{ marginBottom: "10px" }}
            >
              Excel
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: "#272727" }}
              startIcon={<PictureAsPdf />}
              onClick={exportToPDF}
              fullWidth
            >
              PDF
            </Button>
          </Box>
        </Popover>
      </Grid>
      <TableContainer sx={{ border: "1px solid #ddd" }}>
        <Table style={{ borderCollapse: "collapse", border: "1px solid #ddd" }}>
          <TableHead style={{ backgroundColor: "#2A629A" }}>
            <TableRow>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
                rowSpan={2}
              >
                S.No.
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
                rowSpan={2}
              >
                University
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
                colSpan={14}
              >
                Faculty
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Agriculture
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                AVF*
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Ayurveda
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Forestry
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Medicine
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Engineering
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                S & T
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                HSS
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Management
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Education
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Law
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Buddhism
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Sanskrit
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Total
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.map((row, index) => {
              const rowTotal =
                row.agriculture +
                row.avf +
                row.Ayurveda +
                row.forestry +
                row.medicine +
                row.engineering +
                row.sAndT +
                row.hss +
                row.management +
                row.education +
                row.law +
                row.buddhism +
                row.sanskrit;

              return (
                <TableRow key={row.id}>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "center",
                    }}
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "left",
                    }}
                  >
                    {row.university}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "right",
                    }}
                  >
                    {row.agriculture}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "right",
                    }}
                  >
                    {row.avf}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "right",
                    }}
                  >
                    {row.Ayurveda}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "right",
                    }}
                  >
                    {row.forestry}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "right",
                    }}
                  >
                    {row.medicine}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "right",
                    }}
                  >
                    {row.engineering}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "right",
                    }}
                  >
                    {row.sAndT}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "right",
                    }}
                  >
                    {row.hss}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "right",
                    }}
                  >
                    {row.management}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "right",
                    }}
                  >
                    {row.education}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "right",
                    }}
                  >
                    {row.law}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "right",
                    }}
                  >
                    {row.buddhism}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "right",
                    }}
                  >
                    {row.sanskrit}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "right",
                    }}
                  >
                    {rowTotal}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>

          <TableRow style={{ backgroundColor: "#c2c2c2" }}>
            <TableCell
              colSpan={2}
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Grand Total
            </TableCell>
            <TableCell
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "right",
              }}
            >
              {totals.agriculture}
            </TableCell>
            <TableCell
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "right",
              }}
            >
              {totals.avf}
            </TableCell>
            <TableCell
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "right",
              }}
            >
              {totals.ayurveda}
            </TableCell>
            <TableCell
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "right",
              }}
            >
              {totals.forestry}
            </TableCell>
            <TableCell
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "right",
              }}
            >
              {totals.medicine}
            </TableCell>
            <TableCell
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "right",
              }}
            >
              {totals.engineering}
            </TableCell>
            <TableCell
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "right",
              }}
            >
              {totals.sAndT}
            </TableCell>
            <TableCell
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "right",
              }}
            >
              {totals.hss}
            </TableCell>
            <TableCell
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "right",
              }}
            >
              {totals.management}
            </TableCell>
            <TableCell
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "right",
              }}
            >
              {totals.education}
            </TableCell>
            <TableCell
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "right",
              }}
            >
              {totals.law}
            </TableCell>
            <TableCell
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "right",
              }}
            >
              {totals.buddhism}
            </TableCell>
            <TableCell
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "right",
              }}
            >
              {totals.sanskrit}
            </TableCell>
            <TableCell
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "right",
              }}
            >
              {Object.values(totals).reduce((a, b) => a + b, 0)}
            </TableCell>
          </TableRow>
        </Table>
      </TableContainer>
    </div>
  );
};

export default CampusInFaculty;
