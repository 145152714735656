import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Grid,
  Typography,
  TextField,
  CardContent,
  Paper,
  Button,
  Select,
  MenuItem,
  InputLabel,
  TableBody,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  FormControl,
  Dialog,
  Box,
} from "@mui/material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import axios from "axios";
import EditArthikBarsa from "./EditArthikBarsa";
import { toast } from "react-toastify";
import OtherSetupAppBar from "../../../modules/navbar/OtherSetupAppBar";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const ArthikBarsa = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [fiscalYears, setFiscalYears] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedFiscalYearId, setSelectedFiscalYearId] = useState(null);

  const onSubmit = async (data) => {
    try {
      const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;
      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      };
      await axios.post(`${backendUrl}/FiscalYear`, data, config);
      fetchFiscalYears();
    } catch (err) {
      if (err.response && err.response.status === 409) {
        toast.error("Level already exists!", {
          autoClose: 2000,
        });
      } else {
        toast.error("Failed to add data!", {
          autoClose: 2000,
        });
      }
    }
  };

  const handleEditClick = (id) => {
    setSelectedFiscalYearId(id);
    setOpenEditDialog(true);
  };

  const fetchFiscalYears = async () => {
    try {
      const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;
      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios.get(`${backendUrl}/FiscalYear`, config);
      setFiscalYears(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchFiscalYears();
  }, []);

  const handleUpdate = () => {
    fetchFiscalYears();
  };
  console.log(fiscalYears)
  return (
    <>
      <Box
        sx={{
          margin: 0,
          padding: 0,
          height: "100%",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box mt={8}>
          <OtherSetupAppBar />
        </Box>
      </Box>
      <Grid mt='20px' container justifyContent='center' mx='auto' md={8}>
        <Paper elevation={5} sx={{ borderRadius: "20px" }}>
          <CardContent>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ textAlign: "center", color: "#2A629A" }}
            >
              Fiscal Year Setup
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                  <TextField
                    required
                    {...register("yearNepali", { required: true })}
                    id="yearNepali"
                    size="small"
                    name="yearNepali"
                    label="Fiscal Year (B.S.)"
                    fullWidth
                    autoComplete="given-name"
                    error={!!errors.yearNepali}
                    helperText={
                      errors.yearNepali ? "Fiscal Year required" : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    required
                    {...register("yearEnglish", { required: true })}
                    id="yearEnglish"
                    size="small"
                    name="yearEnglish"
                    label="Fiscal Year (A.D.)"
                    fullWidth
                    error={!!errors.yearEnglish}
                    helperText={
                      errors.yearEnglish ? "Fiscal Year required" : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="activeFiscalYear" required>
                      Status
                    </InputLabel>
                    <Select
                      required
                      {...register("activeFiscalYear", { required: true })}
                      id="activeFiscalYear"
                      size="small"
                      name="activeFiscalYear"
                      label="Status"
                      fullWidth
                      error={!!errors.activeFiscalYear}
                    >
                      <MenuItem value={true}>Active</MenuItem>
                      <MenuItem value={false}>Inactive</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    required
                    {...register("index", { required: true })}
                    id="index"
                    size="small"
                    name="index"
                    type="number"
                    label="Index"
                    fullWidth
                    error={!!errors.index}
                    helperText={
                      errors.index ? "Index is required" : ""
                    }
                  />
                </Grid>
              </Grid>
              <Grid
                container
                style={{
                  margin: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: "#007aff", color: "#inherit" }}
                >
                  Submit
                </Button>
              </Grid>
            </form>
          </CardContent>
        </Paper>
        <Grid item md={12} margin="10px">
          <Typography
            variant="h6"
            gutterBottom
            sx={{ textAlign: "center", color: "#2A629A" }}
          >
            Fiscal Year List
          </Typography>
          <TableContainer>
            <Table
              style={{ borderCollapse: "collapse", border: "1px solid #ddd" }}
            >
              <TableHead style={{ backgroundColor: "#2A629A" }}>
                <TableRow>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    S.No:
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    Fiscal Year (B.S.)
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    Fiscal Year (A.D.)
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    Index
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fiscalYears.map((year, index) => (
                  <TableRow key={year.id}>
                    <TableCell
                      style={{ border: "1px solid #ddd", padding: "8px" }}
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell
                      style={{ border: "1px solid #ddd", padding: "8px" }}
                    >
                      {year.yearNepali}
                    </TableCell>
                    <TableCell
                      style={{ border: "1px solid #ddd", padding: "8px" }}
                    >
                      {year.yearEnglish}
                    </TableCell>
                    <TableCell
                      style={{ border: "1px solid #ddd", padding: "8px" }}
                    >
                      {year.index}
                    </TableCell>


                    <TableCell
                      style={{ border: "1px solid #ddd", padding: "8px" }}
                    >
                      {year.activeFiscalYear ? (
                        <Typography color="green">Active</Typography>
                      ) : (
                        <Typography color="red">Inactive</Typography>
                      )}
                    </TableCell>
                    <TableCell
                      style={{ border: "1px solid #ddd", padding: "8px" }}
                    >
                      <Button onClick={() => handleEditClick(year.id)}>
                        <EditNoteIcon /> Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Dialog
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        maxWidth="md"
      >
        <EditArthikBarsa
          id={selectedFiscalYearId}
          onClose={() => setOpenEditDialog(false)}
          onUpdate={handleUpdate}
        />
      </Dialog>
    </>
  );
};

export default ArthikBarsa;
