import { useForm, Controller } from "react-hook-form";
import React from "react";
import {
  Grid,
  Typography,
  TextField,
  CardContent,
  Paper,
  Button,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import InfrastructureAppBar from "../../../modules/navbar/infrastuctureAppBar";

const HostelMgmt = () => {
  const { control, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = (data) => {
    console.log('Form data:', data);
  };

  return (
    <>
    <Box sx={{
      margin: 0, padding: 0,
      height: '100%',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    }}>
      <Box mt={8}>
      <InfrastructureAppBar/>
      </Box>
     </Box>
    <Grid container spacing={2}>
      <Grid item xs={false} md={2} />
      <Grid item xs={12} md={8}>
        <Paper elevation={5} sx={{ borderRadius: "20px" }}>
          <CardContent>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ textAlign: "center", color: "#2A629A" }}
            >
              Hostel Management
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                {/* Hostel Type */}
                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth size="small" error={!!errors.hostelType}>
                    <InputLabel id="hostel-type-label">Hostel Type</InputLabel>
                    <Controller
                      name="hostelType"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Hostel Type is required" }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelId="hostel-type-label"
                          label="Hostel Type"
                          autoComplete="hostelType"
                        >
                          <MenuItem value="male">Male</MenuItem>
                          <MenuItem value="female">Female</MenuItem>
                        </Select>
                      )}
                    />
                    {errors.hostelType && (
                      <FormHelperText>{errors.hostelType.message}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                {/* Number of Rooms in Hostel */}
                <Grid item xs={12} sm={3}>
                  <Controller
                    name="numberOfRooms"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Number of Rooms is required",
                      pattern: {
                        value: /^[1-9][0-9]*$/,
                        message: "Please enter a positive integer",
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="number"
                        size="small"
                        label="Number of Rooms in Hostel"
                        fullWidth
                        autoComplete="numberOfRooms"
                        error={!!errors.numberOfRooms}
                        inputProps={{ step: "1", min: "1" }} // Allows only positive integers
                      />
                    )}
                  />
                  {errors.numberOfRooms && (
                    <FormHelperText error>{errors.numberOfRooms.message}</FormHelperText>
                  )}
                </Grid>

                {/* Number of Seats */}
                <Grid item xs={12} sm={3}>
                  <Controller
                    name="numberOfSeats"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Number of Seats is required",
                      pattern: {
                        value: /^[1-9][0-9]*$/,
                        message: "Please enter a positive integer",
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="number"
                        size="small"
                        label="Number of Seats"
                        fullWidth
                        autoComplete="numberOfSeats"
                        error={!!errors.numberOfSeats}
                        inputProps={{ step: "1", min: "1" }} // Allows only positive integers
                      />
                    )}
                  />
                  {errors.numberOfSeats && (
                    <FormHelperText error>{errors.numberOfSeats.message}</FormHelperText>
                  )}
                </Grid>

                {/* Area Covered by Hostel */}
                <Grid item xs={12} sm={3}>
                  <Controller
                    name="areaCovered"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Area Covered by Hostel is required",
                      pattern: {
                        value: /^[0-9]*\.?[0-9]+$/,
                        message: "Please enter a valid decimal number",
                      },
                      min: {
                        value: 0.01,
                        message: "Area Covered by Hostel must be a positive number",
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="number"
                        size="small"
                        label="Area Covered by Hostel (sq.ft)"
                        fullWidth
                        autoComplete="areaCovered"
                        error={!!errors.areaCovered}
                        inputProps={{ step: "0.01", min: "0.01" }} // Allows only positive decimal numbers
                      />
                    )}
                  />
                  {errors.areaCovered && (
                    <FormHelperText error>{errors.areaCovered.message}</FormHelperText>
                  )}
                </Grid>

                {/* Building Name (Block No) */}
                <Grid item xs={12} sm={5}>
                  <FormControl fullWidth size="small" error={!!errors.buildingName}>
                    <InputLabel id="building-name-label">Building Name (Block No)</InputLabel>
                    <Controller
                      name="buildingName"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Building Name (Block No) is required" }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelId="building-name-label"
                          label="Building Name (Block No)"
                          autoComplete="buildingName"
                        >
                          {/* Populate with actual building names */}
                          <MenuItem value="Building1">Building1</MenuItem>
                          <MenuItem value="Building2">Building2</MenuItem>
                          {/* Add more MenuItems as needed */}
                        </Select>
                      )}
                    />
                    {errors.buildingName && (
                      <FormHelperText>{errors.buildingName.message}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                {/* Has Playground */}
                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth size="small" error={!!errors.hasPlayground}>
                    <InputLabel id="playground-label">Has Playground</InputLabel>
                    <Controller
                      name="hasPlayground"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Playground status is required" }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelId="playground-label"
                          label="Has Playground"
                          autoComplete="hasPlayground"
                        >
                          <MenuItem value="yes">Yes</MenuItem>
                          <MenuItem value="no">No</MenuItem>
                        </Select>
                      )}
                    />
                    {errors.hasPlayground && (
                      <FormHelperText>{errors.hasPlayground.message}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                {/* Has Internet */}
                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth size="small" error={!!errors.hasInternet}>
                    <InputLabel id="internet-label">Has Internet Connection</InputLabel>
                    <Controller
                      name="hasInternet"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Internet Connection status is required" }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelId="internet-label"
                          label="Has Internet Connection"
                          autoComplete="hasInternet"
                        >
                          <MenuItem value="yes">Yes</MenuItem>
                          <MenuItem value="no">No</MenuItem>
                        </Select>
                      )}
                    />
                    {errors.hasInternet && (
                      <FormHelperText>{errors.hasInternet.message}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                {/* Has Drinking Water */}
                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth size="small" error={!!errors.hasDrinkingWater}>
                    <InputLabel id="drinking-water-label">Has Drinking Water</InputLabel>
                    <Controller
                      name="hasDrinkingWater"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Drinking Water status is required" }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelId="drinking-water-label"
                          label="Has Drinking Water"
                          autoComplete="hasDrinkingWater"
                        >
                          <MenuItem value="yes">Yes</MenuItem>
                          <MenuItem value="no">No</MenuItem>
                        </Select>
                      )}
                    />
                    {errors.hasDrinkingWater && (
                      <FormHelperText>{errors.hasDrinkingWater.message}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                {/* Has Toilet */}
                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth size="small" error={!!errors.hasToilet}>
                    <InputLabel id="toilet-label">Has Toilet</InputLabel>
                    <Controller
                      name="hasToilet"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Toilet status is required" }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelId="toilet-label"
                          label="Has Toilet"
                          autoComplete="hasToilet"
                        >
                          <MenuItem value="yes">Yes</MenuItem>
                          <MenuItem value="no">No</MenuItem>
                        </Select>
                      )}
                    />
                    {errors.hasToilet && (
                      <FormHelperText>{errors.hasToilet.message}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                {/* Remarks */}
                <Grid item xs={12} >
                  <Controller
                    name="remarks"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        label="Remarks"
                        fullWidth
                        autoComplete="remarks"
                        multiline
                        rows={4}
                        InputProps={{ style: { minWidth: '100%' } }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
                  <Button type="submit" variant="contained" color="primary">
                    Submit
                  </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Paper>
      </Grid>
    </Grid>
    </>
  );
};

export default HostelMgmt;
