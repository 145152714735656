import React, { useContext, useState } from "react";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { CampusInfoContext } from "./CampusInfo";
import { CampusContactContext } from "./CampusContactDetails";
import { Button, Box, CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import { useSelector } from "react-redux";
import axios from "axios";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { LoadingOverlay } from "@mantine/core";
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const CampusReviewDetails = ({ handleBack }) => {
  const { campusInfo, getLogoURL } = useContext(CampusInfoContext);
  const { contactDetails } = useContext(CampusContactContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const { currentUser } = useSelector((state) => state.user);
  const UniId = currentUser?.institution?.id || 0;
  const UniName = currentUser?.institution?.name || "";
  const profileURL = getLogoURL();
  const handleRegister = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("universityId", UniId || 0);
    formData.append("typeOfCampus", campusInfo.type);
    formData.append("campusName", campusInfo.name);
    formData.append("code", campusInfo.code);
    formData.append("shortName", campusInfo.shortCode);
    formData.append("alias", campusInfo.alias);
    formData.append("phoneNo", campusInfo.phone);
    formData.append("contactNo1", campusInfo.contact1);
    formData.append("contactNo2", campusInfo.contact2);
    formData.append("email", campusInfo.email);
    formData.append("url", campusInfo.url);
    formData.append("province", campusInfo.province);
    formData.append("district", campusInfo.district);
    formData.append("localLevel", campusInfo.localLevel);
    formData.append("wardNo", campusInfo.wardNo);
    formData.append("locality", campusInfo.locality);
    if (campusInfo.logo) {
      formData.append("logo", campusInfo.logo);
    }
    if (campusInfo.campusLogo) {
      formData.append("campusLogo", campusInfo.campusLogo);
    }
    formData.append(
      "yearOfEstd",
      contactDetails.yearOfEstd ? contactDetails.yearOfEstd.toString() : ""
    );
    formData.append(
      "dateOfEstd",
      formatDetail(new Date(contactDetails.dateOfEstd).toLocaleDateString())
    );
    formData.append("accreditationStatus", contactDetails.accreditationStatus);
    formData.append("principalName", contactDetails.campusChief);
    formData.append("principalPhoneNo", contactDetails.principalNumber);
    formData.append("principalPhoneNo1", contactDetails.principalNumber2);
    formData.append("principalEmail", contactDetails.contactEmail1);
    formData.append("contactName", contactDetails.focalPersonName);
    formData.append("contactPersonPosition", contactDetails.position);
    formData.append("contactPhoneNo", contactDetails.contactNumber);
    formData.append("contactPhoneNo1", contactDetails.contactNumber2);
    formData.append("contactPhoneNo2", contactDetails.contactNumber3);
    formData.append("contactEmail", contactDetails.contactEmail2);
    formData.append("bankName", contactDetails.bankName);
    formData.append("branch", contactDetails.branchName);
    formData.append("accountNo", contactDetails.accountNumber);
    try {
      const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;
      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
      };
      await axios.post(`${backendUrl}/Campus`, formData, config);
      toast.success("Campus Successfully Registered", {
        autoClose: 2000,
      });
      navigate("/campus-list");
    } catch (error) {
      if (error.response && error.response.status === 409) {
        toast.error("University already exists", {
          autoClose: 2000,
        });
      } else {
        const errorMessage = error.response
          ? error.response.data
          : error.message;
        toast.error(`Campus Failed to Register: ${errorMessage}`, {
          autoClose: 2000,
        });
      }
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const formatDetail = (detail) => {
    if (detail === null || detail === undefined) return "N/A";
    if (typeof detail === "object") return JSON.stringify(detail); // For complex objects
    return detail.toString(); // For primitive values
  };

  const CampusInfo = campusInfo
    ? [
        { name: "Campus Type:", detail: campusInfo.type },
        { name: "Campus Name:", detail: campusInfo.name },
        {
          name: "Year of Establishment:",
          detail: contactDetails.yearOfEstd
            ? contactDetails.yearOfEstd.toString()
            : "",
        },
        {
          name: "Date of Establishment:",
          detail: formatDetail(
            new Date(contactDetails.dateOfEstd).toLocaleDateString()
          ),
        },
        { name: "Affiliated University:", detail: UniName },
        { name: "Campus Code:", detail: campusInfo.code },
        { name: "Phone Number:", detail: campusInfo.phone },
        { name: "Email:", detail: campusInfo.email },
        { name: "URL:", detail: campusInfo.url },
        { name: "Short Name:", detail: campusInfo.shortCode },
        { name: "Alias:", detail: campusInfo.alias },
      ]
    : [];

  // Construct contact person details
  const contactPerson = contactDetails
    ? [
        {
          name: "Accreditation Status:",
          detail: contactDetails.accreditationStatus,
        },
        { name: "Full Name:", detail: contactDetails.focalPersonName },
        { name: "Contact Number:", detail: contactDetails.contactNumber },
        { name: "Position:", detail: contactDetails.position },
        { name: "Email:", detail: contactDetails.contactEmail },
        { name: "Bank Name:", detail: contactDetails.bankName },
        { name: "Account Number:", detail: contactDetails.accountNumber },
        { name: "Branch:", detail: contactDetails.branchName },
      ]
    : [];

  const logoURL = getLogoURL();

  return (
    <>
      {loading ? (
        <LoadingOverlay
          visible={loading}
          zIndex={100}
          overlayProps={{ radius: "sm", blur: 1 }}
          loaderProps={{ color: "#1976d2", type: "bars" }}
        />
      ) : (
        <>
          <Grid container mb={2} spacing={2} justifyContent="center">
            <Grid item xs={12} md={12}>
              <Grid item xs={12} md={12} mt="1.5rem">
                <Box
                  border="1px solid #8c8d90"
                  borderRadius="10px"
                  position="relative"
                  paddingBottom="15px"
                >
                  <Typography
                    variant="body1"
                    borderRadius="10px"
                    display="inline-block"
                    padding="3px 5px"
                    position="relative"
                    left="20px"
                    bottom="17px"
                    border="1px solid #8c8d90"
                    bgcolor="#e2e4e8"
                    color="#8c8d90"
                  >
                    Campus Details
                  </Typography>
                  {profileURL && (
                    <Box paddingLeft="2.5rem">
                      <img
                        src={profileURL}
                        alt="University Logo"
                        style={{
                          width: "80px",
                          height: "80px",
                          borderRadius: "50%",
                          objectFit: "cover",
                        }}
                      />
                    </Box>
                  )}
                  <Grid
                    container
                    paddingLeft="2.5rem"
                    justifyContent="flex-start"
                  >
                    {CampusInfo.map((details, index) => (
                      <Grid key={index} item xs={5}>
                        <Stack
                          direction="row"
                          spacing={1}
                          sx={{ width: "100%", mb: 1 }}
                        >
                          <Typography variant="body1" gap={1}>
                            {details.name}
                          </Typography>
                          <Typography variant="subtitle" color="#1976d2">
                            {details.detail}
                          </Typography>
                        </Stack>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} md={12} mt="1.5rem">
                <Box
                  border="1px solid #8c8d90"
                  borderRadius="10px"
                  position="relative"
                  paddingBottom="15px"
                >
                  <Typography
                    variant="body1"
                    borderRadius="10px"
                    display="inline-block"
                    padding="3px 5px"
                    position="relative"
                    left="20px"
                    bottom="17px"
                    border="1px solid #8c8d90"
                    bgcolor="#e2e4e8"
                    color="#8c8d90"
                  >
                    Contact Person Details
                  </Typography>
                  <Grid
                    container
                    paddingLeft="2.5rem"
                    justifyContent="flex-start"
                  >
                    {contactPerson.map((contact, index) => (
                      <Grid key={index} item xs={5}>
                        <Stack
                          direction="row"
                          spacing={1}
                          sx={{ width: "100%" }}
                        >
                          <Typography variant="body1">
                            {contact.name}
                          </Typography>
                          <Typography variant="body2" color="#1976d2">
                            {contact.detail}
                          </Typography>
                        </Stack>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Grid>
              <Grid
                mt="2rem"
                color="#8c8d90"
                borderRadius="10px"
                position="relative"
                paddingBottom="15px"
                border="1px solid #8c8d90"
                xs={12}
              >
                <Typography
                  variant="body1"
                  borderRadius="10px"
                  display="inline-block"
                  color="#8c8d90"
                  border="1px solid #8c8d90"
                  padding="3px 5px"
                  position="relative"
                  left="20px"
                  bottom="17px"
                  bgcolor="#e2e4e8"
                >
                  Address details
                </Typography>
                <Typography marginLeft="2rem" color="#1976d2">
                  {campusInfo &&
                    [
                      campusInfo.province,
                      campusInfo.district,
                      `${campusInfo.localLevel}-${campusInfo.wardNo}`,
                      campusInfo.locality,
                    ].join(", ")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}

      {/* Buttons */}
      <Box mt={2} display="flex" justifyContent="flex-start">
        <Button
          onClick={handleBack}
          variant="outlined"
          color="error"
          type="reset"
          size="small"
          startIcon={<ChevronLeftRoundedIcon />}
        >
          Back
        </Button>
        <Button
          variant="outlined"
          size="small"
          type="submit"
          sx={{
            marginLeft: "10px",
            pointerEvents: loading ? "none" : "auto",
            opacity: loading ? 0.6 : 1,
          }}
          onClick={handleRegister}
          endIcon={
            loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              <ChevronRightRoundedIcon />
            )
          }
        >
          {loading ? "Submitting..." : "Register"}
        </Button>
      </Box>
    </>
  );
};

export default CampusReviewDetails;
