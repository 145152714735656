import React, { useEffect } from "react";
import { Avatar, Tooltip, Whisper } from "rsuite"; // Importing Tooltip from rsuite
import {
  FaUserGraduate,
  FaUsers,
  FaWpforms,
  FaClipboardList,
  FaBuilding,
  FaTachometerAlt,
  FaUserShield,
  FaCogs,
  FaCog,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import HomeAppBar from "../../modules/navbar/HomeAppBar";
import { Box } from "@mui/material";
import Attendance from "./Attendance";

const modules = [
  {
    title: "Student Management",
    icon: <FaUserGraduate size={40} />,
    bgColor: "#1C375B",
    color: "#1C375B",
    link: "/student-management/verified-students",
    disabledRoles: ["UniAdmin"],
  },
  {
    title: "Employee Management ",
    icon: <FaUsers size={40} />,
    bgColor: "#1C375B",
    color: "#1C375B",
    link: "/employee-management/teaching-staff",
    disabledRoles: [""],
  },
  {
    title: "University/Campus Management",
    icon: <FaClipboardList size={40} />,
    bgColor: "#1C375B",
    color: "#1C375B",
    link: "/organization-management/private-campus",
    disabledRoles: ["CollegeAdmin"],
  },
  {
    title: "Infrastructure Management",
    icon: <FaBuilding size={40} />,
    bgColor: "#1C375B",
    color: "#1C375B",
    link: "/infrastructure-management",
    disabledRoles: [],
  },
  {
    title: "Dashboard",
    icon: <FaTachometerAlt size={40} />,
    bgColor: "#1C375B",
    color: "#1C375B",
    link: "/",
    disabledRoles: [],
  },
  {
    title: "Role/User Management",
    icon: <FaUserShield size={40} />,
    bgColor: "#1C375B",
    color: "#1C375B",
    link: "/user-management",
    disabledRoles: ["CollegeAdmin"],
  },
  {
    title: "Program Setup Management",
    icon: <FaCogs size={40} />,
    bgColor: "#1C375B",
    color: "#1C375B",
    link: "/program-management",
    disabledRoles: ["SuperAdmin"],
  },
  {
    title: "Other Setup Management",
    icon: <FaCog size={40} />,
    bgColor: "#1C375B",
    color: "#1C375B",
    link: "/other-setup",
    disabledRoles: ["SuperAdmin"],
  },
  {
    title: "Exam/Form Management",
    icon: <FaWpforms size={40} />,
    bgColor: "#1C375B",
    color: "#1C375B",
    link: "/form-management",
    disabledRoles: [],
  },
];

const HomePage = () => {
  const { currentUser } = useSelector((state) => state.user);
  const roleName = currentUser && currentUser.listUser[0].roleName;

  useEffect(() => {
    const moduleBoxes = document.querySelectorAll(".moduleBox");
    moduleBoxes.forEach((box) => {
      box.addEventListener("mouseenter", () => {
        if (!box.classList.contains("disabled")) {
          box.style.transform = "translateY(-8px)";
          box.style.boxShadow = "0 12px 24px rgba(0, 0, 0, 0.15)";
        }
      });
      box.addEventListener("mouseleave", () => {
        if (!box.classList.contains("disabled")) {
          box.style.transform = "translateY(0)";
          box.style.boxShadow = "0 6px 18px rgba(0, 0, 0, 0.1)";
        }
      });
    });
    return () => {
      moduleBoxes.forEach((box) => {
        box.removeEventListener("mouseenter", () => {});
        box.removeEventListener("mouseleave", () => {});
      });
    };
  }, []);

  const isDisabled = (module) => {
    return module.disabledRoles.includes(roleName);
  };

  return (
    <>
      <Box
        sx={{
          margin: 0,
          padding: 0,
          height: "100%",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box mt={10}>
          <HomeAppBar modules={modules} />
        </Box>
      </Box>

      <div style={styles.container}>
        {modules.map((module, index) => {
          const disabled = isDisabled(module);
          return (
            <div key={index} style={{ textDecoration: "none" }}>
              {disabled ? (
                <>
                  <Tooltip
                    title={disabled ? "Permission not given" : ""}
                    arrow
                    placement="top"
                  >
                    <div
                      className="moduleBox disabled"
                      style={{
                        ...styles.moduleBox,
                        backgroundColor: module.bgColor,
                        color: module.color,
                        cursor: "not-allowed",
                        opacity: 0.5,
                        transition: "none",
                        transform: "none",
                      }}
                    >
                      <div style={styles.avatarContainer}>
                        <Avatar style={styles.avatar}>{module.icon}</Avatar>
                      </div>
                      <p style={styles.moduleTitle}>{module.title}</p>
                    </div>
                  </Tooltip>
                </>
              ) : (
                <Link to={module.link} style={{ textDecoration: "none" }}>
                  <div
                    className="moduleBox"
                    style={{
                      ...styles.moduleBox,
                      backgroundColor: module.bgColor,
                      color: module.color,
                      cursor: "pointer",
                      opacity: 1,
                    }}
                  >
                    <div style={styles.avatarContainer}>
                      <Avatar style={styles.avatar}>{module.icon}</Avatar>
                    </div>
                    <p style={styles.moduleTitle}>{module.title}</p>
                  </div>
                </Link>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

const styles = {
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "20px",
    alignItems: "center",
    padding: "20px",
    margin: "0 10%",
  },

  moduleBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "45px",
    borderRadius: "12px",
    boxShadow: "0 6px 16px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
    cursor: "pointer",
    textAlign: "center",
    overflow: "hidden",
    marginLeft: "auto",
  },

  avatarContainer: {
    position: "relative",
    padding: "8px",
    backgroundColor: "#fff",
    borderRadius: "50%",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    margin: "0 auto",
  },

  avatar: {
    width: "40px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
    borderRadius: "50%",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.2s ease-in-out",
  },
  moduleTitle: {
    fontSize: "15px",
    fontWeight: "500",
    color: "#ffffff",
    marginTop: "8px",
    fontFamily: "'Poppins', sans-serif",
    letterSpacing: "0.3px",
    lineHeight: "1.4",
  },
};

export default HomePage;
