import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Popover,
  Box,
  Grid,
} from "@mui/material";
import { FileDownload, InsertDriveFile, PictureAsPdf } from "@mui/icons-material";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";

const RegionWiseStudentCampus = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const localStorageData = JSON.parse(
          localStorage.getItem("persist:root")
        );
        const userState = JSON.parse(localStorageData.user);
        const authToken = userState.currentUser.tokenString;
        const config = {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        };
        const response = await fetch(
          "http://apiheims.dibugsoft.com/api/Dashboard/GenerateStudentByBeltReport?fiscalyearid=4",
          config
        );
        const data = await response.json();
        console.log(data)
        if (Array.isArray(data)) {
          setRows(data);
        } else {
          console.error("Data is not an array", data);
          setRows([]); // Optionally set rows to an empty array if the data is not valid
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setRows([]); // Optionally set rows to an empty array in case of error
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);


  const rows1 = [
    {
      id: 1,
      region: "mountain",
      noOfCampus: 10,
      male: 1000,
      female: 800,
    },
    {
      id: 2,
      region: "hill",
      noOfCampus: 20,
      male: 1200, // Male students
      female: 1000, // Female students
    },
    {
      id: 3,
      region: "terai",
      noOfCampus: 30,
      male: 900,
      female: 700,
    },
  ];

  const realData = rows.map((item, index) => ({
    id: index,
    region: item.title,
    noOfCampus: item.noOfCampus,
    male: item.male,
    others: item.others,
    percentage: item.percentage,
    total: item.total,
    female: item.female,

  }))

  // Calculate the totals for each column and the overall total
  const totals = rows.reduce(
    (acc, row) => {
      acc.noOfCampus += row.noOfCampus;
      acc.male += row.male;
      acc.female += row.female;
      acc.total += row.male + row.female;

      return acc;
    },
    {
      noOfCampus: 0,
      male: 0,
      female: 0,
      total: 0,
    }
  );

  // Calculate the total percentage of male and female students combined in all regions
  const totalPercentage = ((totals.total / totals.total) * 100).toFixed(2);
  console.log(totalPercentage)
  // Open the export popover
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Close the export popover
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "export-popover" : undefined;

  // Export data to Excel
  const exportToExcel = () => {
    const header = [
      [
        "S.No.",
        "Region",
        "No. of Campuses",
        "Male",
        "Female",
        "Others",
        "Number of Students",
        "Percent of Students",
        "Total",
      ],
    ];

    const data = rows.map((row, index) => [
      index + 1,
      row.region,
      row.noOfCampus,
      row.male,
      row.female,
      row.male + row.female,
      ((row.male + row.female) / (row.male + row.female) * 100).toFixed(2),
      row.male + row.female,
    ]);

    const ws = XLSX.utils.aoa_to_sheet([...header, ...data]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Region Wise Students");
    XLSX.writeFile(wb, "region_wise_students.xlsx");
  };

  // Export data to PDF
  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.setFontSize(12);
    const columns = [
      "S.No.",
      "Region",
      "No. of Campuses",
      "Male",
      "Female",
      "Number of Students",
      "Percent of Students",
      "Total",
    ];
    const data = rows.map((row, index) => [
      index + 1,
      row.region,
      row.noOfCampus,
      row.male,
      row.female,
      row.male + row.female,
      ((row.male + row.female) / (row.male + row.female) * 100).toFixed(2),
      row.male + row.female,
    ]);
    doc.autoTable(columns, data);
    doc.save("region_wise_students.pdf");
  };
  return (
    <div>
      <Grid container justifyContent="right">
        <Button
          variant="contained"
          color="primary"
          startIcon={<FileDownload />}
          onClick={handleClick}
          style={{ marginBottom: '10px' }}
        >
          Export
        </Button>

        {/* Popover for Excel and PDF options */}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Box sx={{ padding: '10px' }}>
            <Button
              variant="contained"
              color="primary"
              sx={{ backgroundColor: '#5FAD41' }}
              startIcon={<InsertDriveFile />}
              onClick={exportToExcel}
              fullWidth
              style={{ marginBottom: '10px' }}
            >
              Excel
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: '#272727' }}
              startIcon={<PictureAsPdf />}
              onClick={exportToPDF}
              fullWidth
            >
              PDF
            </Button>
          </Box>
        </Popover>
      </Grid>

      <TableContainer sx={{ border: "1px solid #ddd" }}>
        <Table style={{ borderCollapse: "collapse", border: "1px solid #ddd" }}>
          <TableHead style={{ backgroundColor: "#2A629A" }}>
            <TableRow>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                S.No.
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Region
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                No. of Campuses
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Male
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Female
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Number of Students
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Percent of Students
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Total
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {realData.map((row, index) => {
              const totalStudents = row.male + row.female;
              const totalPercentageForRow = ((totalStudents / totalStudents) * 100).toFixed(2);

              return (
                <TableRow key={row.id}>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "center",
                    }}
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "left",
                    }}
                  >
                    {row.region}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "right",
                    }}
                  >
                    {row.noOfCampus}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "right",
                    }}
                  >
                    {row.male}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "right",
                    }}
                  >
                    {row.female}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "right",
                    }}
                  >
                    {totalStudents}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "right",
                    }}
                  >
                    {`${((row.total / totals.total) * 100).toFixed(2)}%`}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "right",
                    }}
                  >
                    {totalStudents}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>

          <TableRow style={{ backgroundColor: "#c2c2c2" }}>
            <TableCell
              colSpan={2}
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Grand Total
            </TableCell>
            <TableCell
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "right",
              }}
            >
              {totals.noOfCampus}
            </TableCell>
            <TableCell
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "right",
              }}
            >
              {totals.male}
            </TableCell>
            <TableCell
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "right",
              }}
            >
              {totals.female}
            </TableCell>
            <TableCell
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "right",
              }}
            >
              {totals.total}
            </TableCell>
            <TableCell
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "right",
              }}
            >
              {totalPercentage}
            </TableCell>
            <TableCell
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "right",
              }}
            >
              {totals.total}
            </TableCell>
          </TableRow>
        </Table>
      </TableContainer>
    </div>
  );
};

export default RegionWiseStudentCampus;
