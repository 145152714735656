import React, { useState } from 'react';
import {
  Box,
  Grid,
  Step,
  StepLabel,
  Paper,
  Stepper,
  Typography,
} from '@mui/material';
import CampusInfo from '../../components/campusRegister/CampusInfo';
import CampusContactDetails from '../../components/campusRegister/CampusContactDetails';
import CampusReviewDetails from '../../components/campusRegister/CampusDetailsReview';
import { CampusInfoProvider, } from '../../components/campusRegister/CampusInfo';
import { CampusContactProvider } from '../../components/campusRegister/CampusContactDetails';
import OrganizationAppBar from '../../modules/navbar/OrganizationAppBar';

const steps = ['Campus Info', 'Estd. & Bank Details', 'Review Details'];

export default function CampusRegister() {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => setActiveStep((prevStep) => prevStep + 1);
  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);

  return (
    <>
      <Box
        sx={{
          height: '100%',
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        <Box mt={8}>
          <OrganizationAppBar />
        </Box>
      </Box>
      <CampusInfoProvider>
        <CampusContactProvider>
          <Grid sm={12} md={10} lg={9} direction='column' container sx={{ mx: "auto", marginTop: '20px' }}>
            <h1 className="text-3xl  text-center  mt-4 mb-2">
              Campus Registration
            </h1>
            <h1 className="text-lg text-center tracking-tight text-[#2b6eb5]">
              {steps[activeStep]}
            </h1>
            <Stepper style={{ marginTop: '20px', marginBottom: '-15px' }} activeStep={activeStep} alternativeLabel>
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel>
                    <span
                      style={{
                        color: index <= activeStep ? "#2b6eb5" : "inherit",
                      }}
                    >
                      {label}
                    </span>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            <Box component={Paper} elevation={1} padding='10px 20px' mt={2} mb={3}>
              {activeStep === 0 && (
                <CampusInfo
                  handleNext={handleNext}
                  handleBack={handleBack}
                />
              )}
              {activeStep === 1 && (
                <CampusContactDetails
                  handleNext={handleNext}
                  handleBack={handleBack}
                />
              )}
              {activeStep === 2 && (
                <CampusReviewDetails handleBack={handleBack} />
              )}
            </Box>
            <Grid item xs={false} md={2} />
          </Grid>
        </CampusContactProvider>
      </CampusInfoProvider>
    </>
  );
}
