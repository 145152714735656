import React, { useEffect, useState, createContext, useContext } from "react";
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Button,
  FormControl,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Controller, useForm } from "react-hook-form";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import { getCampusById } from "../../../services/services";
import useAddressData from "../../address/address";

const EditCampusInfoContext = createContext();

const EditCampusInfoProvider = ({ children }) => {
  const methods = useForm();
  const [campusInfo, setCampusInfo] = useState({});

  const onChange = (event, name, value) => {
    if (event && event.target && event.target.files) {
      const file = event.target.files[0];
      setCampusInfo((prevState) => ({
        ...prevState,
        campusLogo: file,
        logo: file.name,
      }));
    } else {
      setCampusInfo((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  return (
    <EditCampusInfoContext.Provider
      value={{ ...methods, campusInfo, onChange }}
    >
      {children}
    </EditCampusInfoContext.Provider>
  );
};

const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": {
    borderColor: "#c2c2c2",
    borderWidth: 1,
  },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 4,
    padding: "4px !important",
  },
});

const ValidationSelect = styled(Select)({
  "& select:valid + fieldset": {
    borderColor: "#3572EF",
    borderWidth: 1,
  },
  "& select:invalid + fieldset": {
    borderColor: "#ff0000",
    borderWidth: 1,
  },
  "& select:valid:focus + fieldset": {
    borderLeftWidth: 4,
    padding: "4px !important",
  },
});

const EditCampusInfo = ({ handleNext, handleBack, id }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useContext(EditCampusInfoContext);
  const { onChange } = useContext(EditCampusInfoContext);
  const [campusInfo, setCampusInfo] = useState({
    type: "",
    name: "",
    code: "",
    universityId: "",
    shortCode: "",
    alias: "",
    phone: "",
    contact1: "",
    contact2: "",
    email: "",
    url: "",
    province: "",
    district: "",
    localLevel: "",
    wardNo: "",
    locality: "",
    logo: "",
    campusLogo: null,
  });

  const fetchData = async () => {
    try {
      const getCampusData = await getCampusById(id);
      const { province, district, localLevel } = getCampusData;
      setSelectedProvince(province);
      setSelectedDistrict(district);
      setValue("id", getCampusData.id);
      setValue("universityId", getCampusData.university.id);
      setValue("name", getCampusData.campusName);
      setValue("code", getCampusData.code);
      setValue("alias", getCampusData.alias);
      setValue("shortCode", getCampusData.code);
      setValue("email", getCampusData.email);
      setValue("phone", getCampusData.phoneNo);
      setValue("wardNo", getCampusData.wardNo);
      setValue("locality", getCampusData.locality);
      setValue("url", getCampusData.url);
      setValue("type", getCampusData.typeOfCampus);
      setValue("province", getCampusData.province);
      setValue("district", getCampusData.district);
      setValue("localLevel", getCampusData.localLevel);
      setValue("wardNo", getCampusData.wardNo);
      setValue("logo", getCampusData.logo);

      setCampusInfo({
        code: getCampusData.code,
        alias: getCampusData.alias,
        shortCode: getCampusData.code,
        email: getCampusData.email,
        phone: getCampusData.phoneNo,
        locality: getCampusData.locality,
        url: getCampusData.url,
        type: getCampusData.typeOfCampus,
        province: getCampusData.province,
        district: getCampusData.district,
        localLevel: getCampusData.localLevel,
        wardNo: getCampusData.wardNo,
        logo: getCampusData.logo,
      });
    } catch (error) {
      console.log(error);
    }
  };

  console.log(campusInfo)
  
  useEffect(() => {
    fetchData();
  }, [id]);

  const {
    uniqueProvinces,
    uniqueDistricts,
    uniqueLocalLevels,
    selectedProvince,
    setSelectedProvince,
    selectedDistrict,
    setSelectedDistrict,
    noOfWards,
  } = useAddressData();
  

  const onSubmit = (data) => {
    Object.keys(data).forEach((key) => {
      onChange(null, key, data[key]);
    });
    handleNext();
  };

  const onBack = () => {
    handleBack();
  };
  const getWardOptions = (localLevel) => {
    const wards = noOfWards[localLevel] || 0;
    return wards > 0 ? Array.from({ length: wards }, (_, i) => i + 1) : [];
  };

  return (
    <Grid
      container
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      onReset={onBack}
    >
      <Grid mt=".5rem" container spacing={1}>
        <Grid item xs={12} sm={4.5}>
          <Controller
            name="name"
            control={control}
            rules={{ required: "Campus Name is required" }}
            defaultValue=""
            render={({ field }) => (
              <ValidationTextField
                {...field}
                id="name"
                size="small"
                name="name"
                label="Campus Name"
                fullWidth
                error={!!errors.name}
                helperText={errors.name ? "Campus Name is required" : ""}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller
            name="shortCode"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <ValidationTextField
                {...field}
                id="shortCode"
                size="small"
                name="shortCode"
                label="Short Code"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={2.25}>
          <Controller
            name="code"
            control={control}
            rules={{ required: "Code is required" }}
            defaultValue=""
            render={({ field }) => (
              <ValidationTextField
                {...field}
                id="code"
                size="small"
                name="code"
                label="Campus Code"
                fullWidth
                error={!!errors.code}
                helperText={errors.code ? "Code is required" : ""}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={2.25}>
          <FormControl size="small" fullWidth>
            <InputLabel id="type">Campus Type</InputLabel>
            <Controller
              name="type"
              control={control}
              rules={{ required: "Campus type is required" }}
              defaultValue=""
              render={({ field }) => (
                <ValidationSelect
                  {...field}
                  labelId="type"
                  id="type"
                  name="type"
                  label="Campus Type"
                  fullWidth
                  error={!!errors.type}
                  helperText={errors.type ? "Campus type is required" : ""}
                >
                  <MenuItem value="" disabled>
                    Select an option
                  </MenuItem>
                  <MenuItem value="constituent">Constituent</MenuItem>
                  <MenuItem value="community">Community</MenuItem>
                  <MenuItem value="private">Private</MenuItem>
                </ValidationSelect>
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Controller
            name="alias"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <ValidationTextField
                {...field}
                id="alias"
                size="small"
                name="alias"
                label="Alias"
                fullWidth
                error={!!errors.alias}
                helpertext={errors.alias}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller
            name="phone"
            control={control}
            rules={{
              required: "Phone Number is required",
              validate: (value) => {
                const isValidPhone = /^[9]\d{9}$/.test(value);
                return isValidPhone || "invalid phone umber";
              },
            }}
            defaultValue=""
            render={({ field }) => (
              <ValidationTextField
                {...field}
                id="phone"
                type="text"
                name="phone"
                label="Phone Number"
                InputProps={{
                  placeholder: "98XXXXXXXX",
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                  onInput: (e) => {
                    e.target.value = e.target.value
                      .replace(/[^0-9]/g, "")
                      .slice(0, 10);
                  },
                }}
                fullWidth
                autoComplete="phone number"
                size="small"
                error={!!errors.phone}
                helpertext={errors.phone ? errors.phone.message : ""}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <Controller
            name="email"
            control={control}
            rules={{
              required: "Email is required",
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Email format not matched",
              },
            }}
            defaultValue=""
            render={({ field }) => (
              <ValidationTextField
                {...field}
                id="email"
                name="email"
                type="email"
                size="small"
                label="Email"
                fullWidth
                error={!!errors.email}
                helpertext={errors.email && errors.email.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl size="small" fullWidth>
            <InputLabel id="province" shrink={true}>
              Province
            </InputLabel>
            <Controller
              name="province"
              control={control}
              defaultValue={selectedProvince || ""}
              render={({ field }) => (
                <Select
                  {...field}
                  labelId="province"
                  label="Province"
                  size="small"
                  value={field.value || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    setSelectedProvince(value);
                    setSelectedDistrict(null);
                    field.onChange(value); 
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  {uniqueProvinces.map((province) => (
                    <MenuItem key={province} value={province}>
                      {province}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl size="small" fullWidth>
            <InputLabel id="district" shrink={true}>
              District
            </InputLabel>
            <Controller
              name="district"
              control={control}
              defaultValue={selectedDistrict || ""}
              render={({ field }) => (
                <Select
                  {...field}
                  labelId="district"
                  label="District"
                  size="small"
                  value={field.value || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    setSelectedDistrict(value);
                    field.onChange(value);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  {uniqueDistricts.map((district) => (
                    <MenuItem key={district} value={district}>
                      {district}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        {/* Local Level select */}
        <Grid item xs={12} sm={4}>
          <FormControl size="small" fullWidth>
            <InputLabel id="localLevel">Local Level</InputLabel>
            <Controller
              name="localLevel"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select
                  {...field}
                  labelId="localLevel"
                  label="Local Level"
                  size="small"
                  value={field.value}
                  onChange={(e) => {
                    const value = e.target.value;
                    setValue("localLevel", value);
                    onChange(null, "localLevel", value); // Ensure onChange updates state
                    field.onChange(value); // Trigger the field's onChange
                  }}
                >
                  {uniqueLocalLevels.map((localLevel, index, array) => (
                    <MenuItem key={localLevel} value={localLevel}>
                      {localLevel}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={2}>
          <FormControl size="small" fullWidth>
            <InputLabel id="wardNo" required>
              Ward No
            </InputLabel>
            <Controller
              name="wardNo"
              control={control}
              defaultValue={campusInfo.wardNo}
              render={({ field }) => (
                <ValidationSelect
                  {...field}
                  labelId="wardNo"
                  label="Ward No"
                  size="small"
                  value={field.value || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    field.onChange(value);
                  }}
                  error={!!errors.wardNo}
                  helperText={errors.wardNo ? errors.wardNo.message : ""}
                >
                  {getWardOptions(campusInfo.localLevel).map((ward) => (
                    <MenuItem key={ward} value={ward}>
                      {ward}
                    </MenuItem>
                  ))}
                </ValidationSelect>
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={3}>
          <Controller
            name="locality"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <ValidationTextField
                {...field}
                id="locality"
                name="locality"
                size="small"
                label="Tole"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller
            name="url"
            control={control}
            defaultValue=""
            rules={{
              pattern: {
                value: /^(ftp|http|https):\/\/[^ "]+$/i,
                message: "Invalid URL",
              },
            }}
            render={({ field }) => (
              <ValidationTextField
                {...field}
                id="url"
                name="url"
                size="small"
                label="URL"
                placeholder="https://domain.com"
                fullWidth
                error={!!errors.url}
                helpertext={errors.url && errors.url.message}
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <FormControl>
            <InputLabel size="small" shrink>
              Campus logo
            </InputLabel>
            <TextField
              id="campusLogo"
              type="file"
              size="small"
              variant="standard"
              name="campusLogo"
              accept="image/*"
              onChange={(e) => onChange(e, "campusLogo", e.target.files[0])}
              
              style={{
                color: "#FFFFFF",
                padding: "10px 15px",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Box mt={4} display="flex" justifyContent="space-between">
        <Button
          variant="contained"
          size="small"
          type="submit"
          disabled
          sx={{
            bgcolor: "#2b6eb5",
            marginBottom: "10px",
            marginLeft: "10px",
          }}
          startIcon={<ChevronLeftRoundedIcon />}
        >
          Back
        </Button>
        <Button
          variant="contained"
          size="small"
          type="submit"
          sx={{
            bgcolor: "#2b6eb5",
            marginBottom: "10px",
            marginLeft: "10px",
          }}
          endIcon={<ChevronRightRoundedIcon />}
        >
          Next
        </Button>
      </Box>
    </Grid>
  );
};
export { EditCampusInfoProvider, EditCampusInfoContext };
export default EditCampusInfo;
