import { Box, Button, CardContent, Dialog, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { getBatch, getFiscalYear, getProgram, postFee } from '../../../services/services';
import EditFeeSetup from './EditFeeSetup';
import FeeSetupList from './FeeSetupList';
import axios from 'axios';
import { data } from 'autoprefixer';
import OtherSetupAppBar from '../../../modules/navbar/OtherSetupAppBar';
const backendUrl = process.env.REACT_APP_BACKEND_URL;


const FeeSetup = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [getProgramData, setGetProgramData] = useState([]); // Initialize as an empty array
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedProgramId, setSelectedProgramId] = useState(null);
  const [programType, setProgramType] = useState('');
  const [instituteName, setInstituteName] = useState(''); // Fixed variable name casing
  const [duration, setDuration] = useState(0)
  const [feeType, setFeeType] = useState('');
  const [feeTypeData, setFeeTypeData] = useState([]);
  const [fiscalYear, setFiscalYear] = useState([])
  const [defaultFiscal, setDefaultFiscal] = useState(''); 
  const [batchYear, setBatchYear] = useState([])


  const fetchData = async () => {
    try {
      const data = await getProgram();
      const fiscalYearData = await getFiscalYear();
      const batchYear = await getBatch();
      const feeTypes = await axios.get(`${backendUrl}/FeeType`)
      console.log(feeTypes)
      setFeeTypeData(feeTypes.data)
      setBatchYear(batchYear)
      setGetProgramData(data || []);
      setFiscalYear(fiscalYearData);
  
      // Find the active fiscal year and set it as defaultFiscal
      const activeFiscalYear = fiscalYearData.find(data => data && data.activeFiscalYear === true);
      if (activeFiscalYear) {
        setDefaultFiscal(activeFiscalYear.id); // Use the ID or value that uniquely identifies the default fiscal year
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const onSubmit = async (data) => {
    try {
      // Construct the data object for the API
      const payload = {
        programId: Number(data.programId) || 0,
        instituteId: Number(instituteName) || 0,
        batchID: 0, // Add if you have a batchID field or set it accordingly
        fiscalYearID: Number(data.fiscalYearId) || 0,
        feeTypeId: feeType || '', // Map feeType to a suitable ID if required by API
        annualFee1: feeType === 'tutionFee' && programType === 'annual' ? Number(data.year1fee) || 0 : 0,
        annualFee2: feeType === 'tutionFee' && programType === 'annual' ? Number(data.year2fee) || 0 : 0,
        annualFee3: feeType === 'tutionFee' && programType === 'annual' ? Number(data.year3fee) || 0 : 0,
        annualFee4: feeType === 'tutionFee' && programType === 'annual' ? Number(data.year4fee) || 0 : 0,
        annualFee5: feeType === 'tutionFee' && programType === 'annual' ? Number(data.year5fee) || 0 : 0,
        sem1Fee: feeType === 'tutionFee' && programType === 'semester' ? Number(data.sem1fee) || 0 : 0,
        sem2Fee: feeType === 'tutionFee' && programType === 'semester' ? Number(data.sem2fee) || 0 : 0,
        sem3Fee: feeType === 'tutionFee' && programType === 'semester' ? Number(data.sem3fee) || 0 : 0,
        sem4Fee: feeType === 'tutionFee' && programType === 'semester' ? Number(data.sem4fee) || 0 : 0,
        sem5Fee: feeType === 'tutionFee' && programType === 'semester' ? Number(data.sem5fee) || 0 : 0,
        sem6Fee: feeType === 'tutionFee' && programType === 'semester' ? Number(data.sem6fee) || 0 : 0,
        sem7Fee: feeType === 'tutionFee' && programType === 'semester' ? Number(data.sem7fee) || 0 : 0,
        sem8Fee: feeType === 'tutionFee' && programType === 'semester' ? Number(data.sem8fee) || 0 : 0,
        sem9Fee: feeType === 'tutionFee' && programType === 'semester' ? Number(data.sem9fee) || 0 : 0,
        sem10Fee: feeType === 'tutionFee' && programType === 'semester' ? Number(data.sem10fee) || 0 : 0,
        feeAmount: 0, 
        isExamFee: feeType === 'examFee'
      }
  
      // Post data to the API
      await postFee(payload);
      fetchData();
      toast.success('Data Added successfully', {
        autoClose: 2000,
      });
      reset();
    } catch (err) {
      toast.error('Failed to add!!', {
        autoClose: 2000,
      });
    }
  };
  

  const handleProgramChange = (event) => {
    const selectedProgram = getProgramData.find(program => program.id === event.target.value);
    setProgramType(selectedProgram ? selectedProgram.programType : '');
    setInstituteName(selectedProgram ? selectedProgram.campus : '');
    setDuration(selectedProgram ? selectedProgram.duration : '')
  };

  

  const handleUpdate = () => {
    fetchData();
  };
  const handleFeeTypeChange = (event) => {
    setFeeType(event.target.value);
  };

  return (
    <>
    <Box sx={{
        margin: 0, padding: 0,
        height: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}>
        <Box mt={8}>
        <OtherSetupAppBar/>
        </Box>
       </Box>
    <Grid container spacing={1}>
      <Grid item xs={false} md={1} />
      <Grid item xs={12} md={10}>
        <Paper elevation={5} sx={{ borderRadius: "20px" }}>
          <CardContent>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ textAlign: "center", color: "#2A629A" }}
            >
              Program Fee Setup
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="programId" required>
                      Program Name
                    </InputLabel>
                    <Select
                      required
                      {...register("programId", { required: true })}
                      id="programId"
                      size="small"
                      name="programId"
                      label="Program Name"
                      fullWidth
                      autoComplete="given-name"
                      error={!!errors.programId}
                      helpertext={errors.programId ? "Program is required" : ""}
                      onChange={handleProgramChange}
                    >
                      {getProgramData.map((data) => (
                        <MenuItem value={data.id} key={data.id}>{data.shortName}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                
                    {...register("programType")}
                    id="programType"
                    size="small"
                    name="programType"
                    label="Program Type"
                    fullWidth
                    disabled
                    value={programType}
                    error={!!errors.programType}
                    helpertext={errors.programType ? "Program Type required" : ""}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    {...register("duration")}
                    id="duration"
                    size="small"
                    name="duration"
                    label="Duration"
                    fullWidth
                    disabled
                    value={duration}
                    error={!!errors.duration}
                    helpertext={errors.duration ? "Program Type required" : ""}
                  />
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                  <TextField
                    id="instituteId"
                    size="small"
                    name="instituteId"
                    label="Institute Name"
                    value={instituteName} // Fixed variable name casing
                    fullWidth
                  />
                </Grid> */}
                <Grid item xs={12} sm={2}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="feeType" required>
Fee Type                    </InputLabel>
                    <Select
                      required
                      {...register("feeType", { required: true })}
                      id="feeType"
                      size="small"
                      name="feeType"
                      label="Fee Type"
                      fullWidth
                      autoComplete="given-name"
                      error={!!errors.feeType}
                      helpertext={errors.feeType ? "Program is required" : ""}
                      onChange={handleFeeTypeChange}
                    >
                    {feeTypeData && feeTypeData.map((data) => (
                        <MenuItem value={data.id}>{data.name}</MenuItem>
                        
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {feeType === 'admissionFee' && (
  <>
    <Box
      border="1px solid #c2c2c2"
      borderRadius="10px"
      position="relative"
      padding="20px"
      width='100%'
      marginTop="20px"
      marginLeft={2}
    >
      <Typography
        variant="subtitle1"
        display="inline-block"
        bgcolor="white"
        padding="0 5px"
        position="absolute"
         color='gray'
        top="-12px"
        left="20px"
       
      >
        Admission Fee
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <TextField
            required
            id="admissionFee"
            {...register('admissionFee')}
            name="admissionFee"
            size="small"
            label="Admission Fee"
            fullWidth
            InputProps={{
                        startAdornment: <InputAdornment position="start">Rs.</InputAdornment>,
                      }}
            
            error={!!errors.admissionFee}
            helperText={errors.admissionFee ? 'Admission Fee is required' : ''}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            required
            id="openDate"
            {...register('openDate')}
            name="openDate"
            size="small"
            label="Admission Open Date"
            fullWidth
            error={!!errors.openDate}
            helperText={errors.openDate ? 'Admission Open Date is required' : ''}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            required
            id="closeDate"
            {...register('closeDate')}
            name="closeDate"
            size="small"
            label="Admission Close Date"
            fullWidth
            error={!!errors.closeDate}
            helperText={errors.closeDate ? 'Admission Close Date is required' : ''}
          />
        </Grid>
      </Grid>
    </Box>
  </>
)}

                {feeType === 'formFee' && (
                  <>
                  <Box
      border="1px solid #c2c2c2"
      borderRadius="10px"
      position="relative"
      padding="20px"
      width='100%'
      marginTop="20px"
      marginLeft={2}
    >
      <Typography
        variant="subtitle1"
        display="inline-block"
        bgcolor="white"
        padding="0 5px"
        position="absolute"
        color='gray'
        top="-12px"
        left="20px"
      >
        Form Fee
      </Typography>
      <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      required
                      id="formFee"
                      {...register('formFee')}
                      name="formFee"
                      size="small"
                      label="Form Fee"
                      fullWidth
                      error={!!errors.formFee}
                      helpertext={errors.formFee ? 'formFee Fee is required' : ''}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      required
                      id="openDate"
                      {...register('openDate')}
                      name="openDate"
                      size="small"
                      label="Form Open Date"
                      fullWidth
                      error={!!errors.openDate}
                      helpertext={errors.openDate ? 'Opendate Fee is required' : ''}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      required
                      id="closeDate"
                      {...register('closeDate')}
                      name="closeDate"
                      size="small"
                      label="form Close Date"
                      fullWidth
                      error={!!errors.closeDate}
                      helpertext={errors.closeDate ? 'CloseDate Fee is required' : ''}
                    />
                  </Grid>
                  </Grid>
                  </Box>
                  </>
                )}
                {feeType === 'examFee' && (
                  <>
                  <Box
      border="1px solid #c2c2c2"
      borderRadius="10px"
      position="relative"
      padding="20px"
      width='100%'
      marginTop="20px"
      marginLeft={2}
    >
      <Typography
        variant="subtitle1"
        display="inline-block"
        bgcolor="white"
        padding="0 5px"
        color='gray'
        position="absolute"
        top="-12px"
        left="20px"
      >
        Examination Form Fee
      </Typography>
      <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      required
                      id="examFee"
                      {...register('examFee')}
                      name="examFee"
                      size="small"
                      label="Exam Fee"
                      fullWidth
                      error={!!errors.examFee}
                      helpertext={errors.examFee ? 'Exam Fee is required' : ''}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      required
                      id="openDate"
                      {...register('openDate')}
                      name="openDate"
                      size="small"
                      label="Form Open Date"
                      fullWidth
                      error={!!errors.openDate}
                      helpertext={errors.openDate ? 'openDate Fee is required' : ''}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      required
                      id="closeDate"
                      {...register('closeDate')}
                      name="closeDate"
                      size="small"
                      label="Form Close Date"
                      fullWidth
                      error={!!errors.closeDate}
                      helpertext={errors.closeDate ? 'closeDate Fee is required' : ''}
                    />
                  </Grid>
                  </Grid>
                </Box>
                  </>
                )}
                {feeType === 'otherFee' && (
                  <>
                  <Box
      border="1px solid #c2c2c2"
      borderRadius="10px"
      position="relative"
      padding="20px"
      width='100%'
      color='gray'
      marginTop="20px"
      marginLeft={2}
    >
      <Typography
        variant="subtitle1"
        display="inline-block"
        bgcolor="white"
        padding="0 5px"
        position="absolute"
        top="-12px"
        left="20px"
      >
        Other Fee
      </Typography>
      <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      required
                      id="otherFee"
                      {...register('otherFee')}
                      name="otherFee"
                      size="small"
                      label="Other Fee"
                      fullWidth
                      error={!!errors.otherFee}
                      helpertext={errors.otherFee ? 'otherFee is required' : ''}
                    />
                  </Grid>
                 </Grid>
                 </Box>

                  </>
                )}
                {feeType === 'tutionFee' && (
<>
                  <Box
      border="1px solid #c2c2c2"
      borderRadius="10px"
      position="relative"
      padding="15px"
      width='100%'
      marginTop="20px"
      marginLeft={2}
    >
      <Typography
        variant="subtitle1"
        display="inline-block"
        bgcolor="white"
        padding="0 5px"
        color='gray'
        position="absolute"
        top="-12px"
        left="20px"
      >
        Semester/Annual Fee
      </Typography>
      <Grid container spacing={2}>
      {programType === 'annual' && (
        <>
                <Grid item xs={12} sm={2.5}>
                  <TextField
                    required
                    id="year1fee"
                    {...register("year1fee")}
                    name="year1fee"
                    size="small"
                    label="First year Fee"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={2.5}>
                  <TextField
                    required
                    id="year2fee"
                    {...register("year2fee")}
                    name="year2fee"
                    size="small"
                    label="Second Year Fee"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={2.5}>
                  <TextField
                    required
                    id="year3fee"
                    {...register("year3fee")}
                    name="year3fee"
                    size="small"
                    label="Thirt year Fee"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={2.5}>
                  <TextField
                    required
                    id="year4fee"
                    {...register("year4fee")}
                    name="year4fee"
                    size="small"
                    label="Forth Year Fee"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    required
                    id="year5fee"
                    {...register("year5fee")}
                    name="year5fee"
                    size="small"
                    label="Fifth Year Fee"
                    fullWidth
                  />
                </Grid>
                </>
      )}
      {programType === 'semester' && (
                <>
                <Grid item xs={12} sm={2.5}>
                  <TextField
                    required
                    id="sem1fee"
                    {...register("sem1fee")}
                    name="sem1fee"
                    size="small"
                    label="First Sem."
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={2.5}>
                  <TextField
                    required
                    id="sem2fee"
                    {...register("sem2fee")}
                    name="sem2fee"
                    size="small"
                    label="Second Sem."
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={2.5}>
                  <TextField
                    required
                    {...register("sem3fee")}
                    id="sem3fee"
                    size="small"
                    name="sem3fee"
                    label="Third Sem."
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={2.5}>
                  <TextField
                    required
                    id="sem4fee"
                    {...register("sem4fee")}
                    name="sem4fee"
                    size="small"
                    label="Fourth Sem."
                    fullWidth
                    error={!!errors.sem3fee}
                    helpertext={errors.sem3fee ? "Fee required" : ""}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    required
                    id="sem5fee"
                    {...register("sem5fee", { required: true })}
                    name="sem5fee"
                    size="small"
                    label="Fifth Sem."
                    fullWidth
                    error={!!errors.sem4fee}
                    helpertext={errors.sem4fee ? "Fee 4 required" : ""}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    required
                    id="sem6fee"
                    {...register("sem6fee", { required: true })}
                    name="sem6fee"
                    size="small"
                    label="Sixth Sem."
                    fullWidth
                    error={!!errors.sem5fee}
                    helpertext={errors.sem5fee ? "Fee 6 required" : ""}
                  />
                </Grid>
                <Grid item xs={12} sm={2.5}>
                  <TextField
                    required
                    id="sem7fee"
                    {...register("sem7fee", { required: true })}
                    name="sem7fee"
                    size="small"
                    label="Seventh Sem."
                    fullWidth
                    error={!!errors.sem6fee}
                    helpertext={errors.sem6fee ? "Fee 6 required" : ""}
                  />
                </Grid>
                <Grid item xs={12} sm={2.5}>
                  <TextField
                    required
                    id="sem8fee"
                    {...register("sem8fee", { required: true })}
                    name="sem8fee"
                    size="small"
                    label="Eigth Sem."
                    fullWidth
                    error={!!errors.sem8fee}
                    helpertext={errors.sem8fee ? "Fee 8 required" : ""}
                  />
                </Grid>
                <Grid item xs={12} sm={2.5}>
                  <TextField
                    required
                    id="sem9fee"
                    {...register("sem9fee", { required: true })}
                    name="sem9fee"
                    size="small"
                    label="Ninth Sem"
                    fullWidth
                    error={!!errors.sem9fee}
                    helpertext={errors.sem9fee ? "Fee 8 required" : ""}
                  />
                </Grid>
                <Grid item xs={12} sm={2.5}>
                  <TextField
                    required
                    id="sem10fee"
                    {...register("sem10fee", { required: true })}
                    name="sem10fee"
                    size="small"
                    label="Tenth Sem"
                    fullWidth
                    error={!!errors.sem10fee}
                    helpertext={errors.sem10fee ? "Fee 8 required" : ""}
                  />
                </Grid>
                </>
      )}
                </Grid>
                </Box>
                </>
                )}

                {fiscalYear.length > 0 && (
  <Grid item xs={12} sm={2}>
    <FormControl fullWidth size="small">
      <InputLabel id="fiscalYearId" required>
        Fiscal Year
      </InputLabel>
      <Select
        required
        {...register("fiscalYearId", { required: true })}
        id="fiscalYearId"
        size="small"
        name="fiscalYearId"
        label="Fiscal Year"
        fullWidth
        value={defaultFiscal} // Use value instead of defaultValue for controlled component
        autoComplete="given-name"
        error={!!errors.fiscalYearId}
        helperText={errors.fiscalYearId ? "Fiscal year is required" : ""}
      >
        {fiscalYear.map((data) => (
          <MenuItem value={data.id} key={data.id}>{data.yearNepali}</MenuItem>
        ))}
      </Select>
    </FormControl>
  </Grid>
)}

                <Grid item xs={12} sm={2}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="academicYear" required>
                    Acad. Year                    </InputLabel>
                    <Select
                      required
                      {...register("academicYear", { required: true })}
                      id="academicYear"
                      size="small"
                      name="academicYear"
                      label="Academic Year "
                      fullWidth
                      autoComplete="given-name"
                      error={!!errors.academicYear}
                      helpertext={errors.academicYear ? "Program is required" : ""}
                    >
                      {batchYear.map((data) => (
                        <MenuItem value={data.id} key={data.id}>{data.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="remark"
                    size="small"
                    name="remark"
                    label="Remarks"
                    value={instituteName} // Fixed variable name casing
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                container
                style={{
                  margin: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: "#007aff", color: "#inherit" }}
                >
                  Submit
                </Button>
              </Grid>
            </form>
          </CardContent>
        </Paper>
        <FeeSetupList/>
      </Grid>
      <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)} maxWidth="md">
        <EditFeeSetup id={selectedProgramId} onClose={() => setOpenEditDialog(false)} onUpdate={handleUpdate} />
      </Dialog>
    </Grid>
    </>
  );
};

export default FeeSetup;

