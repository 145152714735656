import React, { useRef, useEffect, useState } from "react";
import {
  Box,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
  DialogContent,
  IconButton,
} from "@mui/material";
import EditUniversityInfo from "../../components/universityRegister/edit/EditUniversityInfo";

import { EditUniversityInfoProvider } from "../../components/universityRegister/edit/EditUniversityInfo";
import CloseIcon from "@mui/icons-material/Close";
import EditContactDetails, {
  EditUniversityContactProvider,
} from "../../components/universityRegister/edit/EditContactDetails";
import EditReviewDetails from "../../components/universityRegister/edit/EditReviewDetails";
import { getUniversityById } from "../../services/services";

const steps = ["University Info", "Estd. & Bank Details", "Review Details"];

export default function EditUniversity({ setOpenEditDialog, id, onClose }) {
  const [activeStep, setActiveStep] = useState(0);
  const dialogRef = useRef(null);
  const [universityById, setUniversityById] = useState([]);

  const fetchData = async () => {
    try {
      const getUniversityData = await getUniversityById(id);
      setUniversityById(
        Array.isArray(getUniversityData)
          ? getUniversityData
          : [getUniversityData]
      );
    } catch (error) {
      console.log("error");
    }
  };
  useEffect(() => {
    fetchData();
  }, [id]);

  const handleClose = () => {
    setOpenEditDialog(false);
  };

  const handleNext = () => setActiveStep((prevStep) => prevStep + 1);
  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dialogRef.current && !dialogRef.current.contains(event.target)) {
        event.preventDefault();
        event.stopPropagation();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <EditUniversityInfoProvider>
      <EditUniversityContactProvider>
        <DialogContent ref={dialogRef}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
              {universityById &&
                universityById.map((data) => (
                  <Typography
                    key={data.id}
                    variant="h5"
                    mb={2}
                    textAlign="center"
                    sx={{ color: "#2A629A" }}
                  >
                    Edit {data.name}
                  </Typography>
                ))}
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepLabel>
                      <span
                        style={{
                          fontSize: "0.8rem",
                          color: index <= activeStep ? "#007aff" : "inherit",
                        }}
                      >
                        {label}
                      </span>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
              <Box mt={2}>
                {activeStep === 0 && (
                  <EditUniversityInfo
                    handleNext={handleNext}
                    handleBack={handleBack}
                    id={id}
                  />
                )}
                {activeStep === 1 && (
                  <EditContactDetails
                    handleNext={handleNext}
                    handleBack={handleBack}
                    id={id}
                  />
                )}
                {activeStep === 2 && (
                  <EditReviewDetails handleBack={handleBack} id={id}                 onClose={() => setOpenEditDialog(false)}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </EditUniversityContactProvider>
    </EditUniversityInfoProvider>
  );
}
