import { useForm, Controller } from "react-hook-form";
import React, { useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  CardContent,
  Paper,
  Button,
  FormControl,
  InputLabel,
  Select,
  Box,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import InfrastructureAppBar from "../../../modules/navbar/infrastuctureAppBar";

const EquipMgmt = () => {
  const { control, handleSubmit, formState: { errors } } = useForm();

  // State to manage selected category and item options
  const [selectedCategory, setSelectedCategory] = useState('');
  const [itemOptions, setItemOptions] = useState([]);

  // Define the options for each category
  const categoryOptions = {
    furniture: ['Desk/Bench', 'Tables', 'Chairs', 'Others'],
    equipment: ['Computer', 'Printer', 'Photocopy Machine', 'Camera', 'Projector', 'Others'],
    vehicle: ['Car', 'Bus', 'Jeep', 'Motorcycle', 'Bicycle', 'Scooter', 'Others']
  };

  // Handle change event for category selection
  const handleCategoryChange = (event) => {
    const category = event.target.value;
    setSelectedCategory(category);
    setItemOptions(categoryOptions[category] || []);
  };

  // Handle form submission
  const onSubmit = (data) => {
    console.log('Form data:', data);
  };

  return (
    <>
    <Box sx={{
      margin: 0, padding: 0,
      height: '100%',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    }}>
      <Box mt={8}>
      <InfrastructureAppBar/>
      </Box>
     </Box>
    <Grid container spacing={2}>
      <Grid item xs={false} md={2} />
      <Grid item xs={12} md={8}>
        <Paper elevation={5} sx={{ borderRadius: "20px" }}>
          <CardContent>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ textAlign: "center", color: "#2A629A" }}
            >
              Furniture, Equipment, and Vehicle Management
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                {/* Category Dropdown */}
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth size="small" error={!!errors.category}>
                    <InputLabel id="category-label">Category</InputLabel>
                    <Controller
                      name="category"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Category is required" }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelId="category-label"
                          label="Category"
                          onChange={(e) => {
                            handleCategoryChange(e);
                            field.onChange(e); // Ensure form state is updated
                          }}
                        >
                          <MenuItem value="furniture">Furniture</MenuItem>
                          <MenuItem value="equipment">Equipment</MenuItem>
                          <MenuItem value="vehicle">Vehicle</MenuItem>
                        </Select>
                      )}
                    />
                    {errors.category && (
                      <FormHelperText>{errors.category.message}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                {/* Item Dropdown */}
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth size="small" error={!!errors.itemName}>
                    <InputLabel id="item-name-label">Item Name</InputLabel>
                    <Controller
                      name="itemName"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Item name is required" }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelId="item-name-label"
                          label="Item Name"
                        >
                          {itemOptions.map((item, index) => (
                            <MenuItem key={index} value={item}>{item}</MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    {errors.itemName && (
                      <FormHelperText>{errors.itemName.message}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                {/* Quantity */}
                <Grid item xs={12} sm={6} md={4}>
                  <Controller
                    name="quantity"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Quantity is required",
                      pattern: {
                        value: /^[1-9][0-9]*$/,
                        message: "Please enter a positive integer",
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="number"
                        size="small"
                        label="Quantity"
                        fullWidth
                        autoComplete="quantity"
                        error={!!errors.quantity}
                        inputProps={{ step: "1", min: "1" }} // Allows only positive integers
                      />
                    )}
                  />
                  {errors.quantity && (
                    <FormHelperText error>{errors.quantity.message}</FormHelperText>
                  )}
                </Grid>

                {/* Item Description */}
                <Grid item xs={12}>
                  <Controller
                    name="itemDescription"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        label="Item Description"
                        fullWidth
                        autoComplete="itemDescription"
                        multiline
                        rows={4}
                      />
                    )}
                  />
                </Grid>

                {/* Remarks */}
                <Grid item xs={12}>
                  <Controller
                    name="remarks"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        label="Remarks"
                        fullWidth
                        autoComplete="remarks"
                        multiline
                        rows={4}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
                    <Button type="submit" variant="contained" color="primary">
                      Submit
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Paper>
      </Grid>
      <Grid item xs={false} md={2} />
    </Grid>
    </>
  );
};

export default EquipMgmt;
