import {
    Box,
    Grid,
    Step,
    StepLabel,
    Stepper,
    Typography,
  } from "@mui/material";
  import React, { useState } from "react";
 
  import EditStudentGeneralInfo, { EditStudentInfoProvider } from "./EditGeneralInfo";
  import EditStudentAddressInfo, { EditStudentAddressProvider } from "./EditStudentAddressInfo";
  import EditStudentGuardianInfo, { EditStudentGuardianProvider } from "./EditGuardianInfo";
  import EditStudentAcademicInfo, { EditStudentAcademicProvider } from "./EditStudentAcademicsInfo";
  import EditStudentRegistrationInfo, { EditStudentRegProvider } from "./EditStudentRegistration";
import EditReviewDetails from "./EditReviewStudentInfo";
  
  
  const steps = [
    "General Info",
    "Address Info",
    "Guardian Info",
    "Registration",
    "Review all",
    "Student Academics"
  ];
  
  
  export default function EditStudentRegister({id, onClose, onUpdate}) {
    const [activeStep, setActiveStep] = useState(0);
    const [studentId, setStudentId] = useState(null);

    const handleNext = () => setActiveStep((prevStep) => prevStep + 1);
    const handleBack = () => setActiveStep((prevStep) => prevStep - 1);
    const handleRegisterSuccess = (id) => {
      setStudentId(id);
      handleNext(); // Move to the next step
    };
    return (
      <EditStudentInfoProvider>
      <EditStudentAddressProvider>
        <EditStudentGuardianProvider>
          {/* <EditStudentAcademicProvider> */}
          <EditStudentRegProvider>
          <Grid container sx={{ minHeight: "0", maxWidth:'100%' }} spacing={0} marginTop={2}>
            <Grid item xs={false} md={0} />
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              sx={{ backgroundColor: "background.default", p: 0 ,  borderRadius: "20px"}}
            >
                <Box sx={{ maxWidth: '100%' }}>
                  <Typography
                    variant="h5"
                    mb={2}
                    textAlign="center"
                    p="10px"
                    sx={{ color: "#2b6eb5",  }}
                  >
                    Register Student
                  </Typography>
                  <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label, index) => (
                      <Step key={label}>
                        <StepLabel>
                          <span
                            style={{
                              color: index <= activeStep ? "#2b6eb5" : "inherit",
                            }}
                          >
                            {label}
                          </span>
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                  <Box mt={2} mx={0}>
                      {activeStep === 0 && (<EditStudentGeneralInfo
                      handleNext={handleNext}
                      handleBack={handleBack}
                      id={id}
                    />
                    )  }
                      {activeStep === 1 && (<EditStudentAddressInfo 
                      handleNext={handleNext}
                      handleBack={handleBack}
                      id={id}
                    />
                  )}
                 
                      {activeStep === 2 && (<EditStudentGuardianInfo
                        handleNext={handleNext}
                        handleBack={handleBack}
                        id={id} />)}
  
                      {/* {activeStep === 3 && (<EditStudentAcademicInfo
                        handleNext={handleNext}
                        handleBack={handleBack}
                        id={id} />)} */}
  
                      {activeStep === 3 && (<EditStudentRegistrationInfo
                        handleNext={handleNext}
                        handleBack={handleBack}
                        id={id} />)}
  
                      {activeStep === 4 && <EditReviewDetails  handleBack={handleBack}  id={id} onClose={onClose} onUpdate={onUpdate} 
                       onRegisterSuccess={handleRegisterSuccess}
                      />}
                      {activeStep === 5 && <EditStudentAcademicInfo  handleBack={handleBack}  id={id} onClose={onClose} onUpdate={onUpdate} studentId={studentId} />}

                    </Box>
                  
                </Box>
            </Grid>
            <Grid item xs={false} md={2} />
          </Grid>
          </EditStudentRegProvider> 
            {/* </EditStudentAcademicProvider> */}
          </EditStudentGuardianProvider>
        </EditStudentAddressProvider>
      </EditStudentInfoProvider>
    );
  }
  
  