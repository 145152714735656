import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Button,
  Popover,
  Typography,
  Grid
} from "@mui/material";
import { blue } from "@mui/material/colors";
import React, { useState } from "react";
import * as XLSX from "xlsx";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { FileDownload, InsertDriveFile, PictureAsPdf } from "@mui/icons-material";

const TeacherByCampusType = () => {
  const rows = [
    {
      id: 1,
      campusType: "Constituent",
      university: "TU",
      professor: 1200,
      associateProfessor: 1000,
      lecturer: 900,
      assistLecturer: 700,
      instructor: 500,
    },
    {
      id: 2,
      campusType: "Community",
      university: "KU",
      professor: 1200,
      associateProfessor: 1000,
      lecturer: 900,
      assistLecturer: 700,
      instructor: 500,
    },
    {
      id: 3,
      campusType: "Private",
      university: "PU",
      professor: 1200,
      associateProfessor: 1000,
      lecturer: 900,
      assistLecturer: 700,
      instructor: 500,
    },
  ];

  const [filteredCampusType, setFilteredCampusType] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const handleFilterChange = (event) => {
    setFilteredCampusType(event.target.value);
  };

  const filteredRows = filteredCampusType
    ? rows.filter((row) => row.campusType === filteredCampusType)
    : rows;

  const totals = filteredRows.reduce(
    (acc, row) => {
      acc.professor += row.professor;
      acc.associateProfessor += row.associateProfessor;
      acc.lecturer += row.lecturer;
      acc.assistLecturer += row.assistLecturer;
      acc.instructor += row.instructor;

      acc.total +=
        row.professor +
        row.associateProfessor +
        row.lecturer +
        row.assistLecturer +
        row.instructor;

      return acc;
    },
    {
      professor: 0,
      associateProfessor: 0,
      lecturer: 0,
      assistLecturer: 0,
      instructor: 0,
      total: 0,
    }
  );

  // Excel Export Function
  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(filteredRows.map((row) => ({
      University: row.university,
      Professor: row.professor,
      AssociateProfessor: row.associateProfessor,
      Lecturer: row.lecturer,
      AssistLecturer: row.assistLecturer,
      Instructor: row.instructor,
      Total: row.professor + row.associateProfessor + row.lecturer + row.assistLecturer + row.instructor,
    })));
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Teacher Data");
    XLSX.writeFile(wb, "teacher_data.xlsx");
  };

  // PDF Export Function
  const exportToPDF = () => {
    const doc = new jsPDF();
    const tableData = filteredRows.map((row) => [
      row.university,
      row.professor,
      row.associateProfessor,
      row.lecturer,
      row.assistLecturer,
      row.instructor,
      row.professor + row.associateProfessor + row.lecturer + row.assistLecturer + row.instructor,
    ]);
    const tableHead = [
      "University",
      "Professor",
      "Associate Professor",
      "Lecturer",
      "Assit. Lecturer",
      "Instructor and Others",
      "Total",
    ];
    doc.autoTable({
      head: [tableHead],
      body: tableData,
      startY: 20,
    });
    doc.save("teacher_data.pdf");
  };

  // Handle Export Button Click
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "export-popover" : undefined;
  return (
    <div>
      {/* Filter by Campus Type */}
      <Box sx={{ marginBottom: "0px", display: "flex", gap: 2 }}>
        <FormControl fullWidth sx={{ maxWidth: "30%" }} size="small">
          <InputLabel>Filter by Campus Type</InputLabel>
          <Select
            size="small"
            sx={{
              backgroundColor: "#fff",
              borderRadius: 2,
              borderColor: "#c2c2c2",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#c2c2c2",
                },
                "&:hover fieldset": {
                  borderColor: blue[500],
                },
                "&.Mui-focused fieldset": {
                  borderColor: blue[700],
                  boxShadow: `0 0 0 2px ${blue[100]}`,
                },
              },
              "& .MuiInputLabel-root": {
                fontSize: "1rem",
              },
              "& .MuiSelect-icon": {
                color: blue[700],
              },
            }}
            value={filteredCampusType}
            onChange={handleFilterChange}
            label="Filter by Campus Type"
          >
            <MenuItem value="">All Campus Type</MenuItem>
            <MenuItem value="Constituent">Constituent</MenuItem>
            <MenuItem value="Community">Community</MenuItem>
            <MenuItem value="Private">Private</MenuItem>
          </Select>
        </FormControl>

      {/* Export Button */}
      <Grid container justifyContent="right">
      <Button 
        variant="contained" 
        color="primary" 
        startIcon={<FileDownload />} 
        onClick={handleClick}
        style={{ marginBottom: '10px' }}
      >
        Export
      </Button>

      {/* Popover for Excel and PDF options */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box sx={{ padding: '10px' }}>
          <Button 
            variant="contained" 
            color="primary" 
            sx={{ backgroundColor: '#5FAD41' }}
            startIcon={<InsertDriveFile />} 
            onClick={exportToExcel} 
            fullWidth
            style={{ marginBottom: '10px' }}
          >
            Excel
          </Button>
          <Button 
            variant="contained" 
            sx={{ backgroundColor: '#272727' }}
            startIcon={<PictureAsPdf />} 
            onClick={exportToPDF} 
            fullWidth
          >
           PDF
          </Button>
        </Box>
      </Popover>
      </Grid>
      </Box>


      {/* Table */}
      <TableContainer sx={{ border: "1px solid #ddd" }}>
        <Table style={{ borderCollapse: "collapse", border: "1px solid #ddd" }}>
          <TableHead style={{ backgroundColor: "#2A629A" }}>
            <TableRow>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                S.No.
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                University
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Professor
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Associate Professor
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Lecturer
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Assit. Lecturer
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Instructor and Others
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Total
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {filteredRows.map((row, index) => {
              const totalTeachers =
                row.professor +
                row.associateProfessor +
                row.lecturer +
                row.assistLecturer +
                row.instructor;

              return (
                <TableRow key={row.id}>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "center",
                    }}
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "left",
                    }}
                  >
                    {row.university}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "right",
                    }}
                  >
                    {row.professor}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "right",
                    }}
                  >
                    {row.associateProfessor}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "right",
                    }}
                  >
                    {row.lecturer}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "right",
                    }}
                  >
                    {row.assistLecturer}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "right",
                    }}
                  >
                    {row.instructor}
                  </TableCell>

                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "right",
                    }}
                  >
                    {totalTeachers}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>

          {/* Grand Total */}
          <TableRow style={{ backgroundColor: "#c2c2c2" }}>
            <TableCell
              colSpan={2}
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Grand Total
            </TableCell>
            <TableCell
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "right",
              }}
            >
              {totals.professor}
            </TableCell>
            <TableCell
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "right",
              }}
            >
              {totals.associateProfessor}
            </TableCell>
            <TableCell
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "right",
              }}
            >
              {totals.lecturer}
            </TableCell>
            <TableCell
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "right",
              }}
            >
              {totals.assistLecturer}
            </TableCell>
            <TableCell
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "right",
              }}
            >
              {totals.instructor}
            </TableCell>
            <TableCell
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "right",
              }}
            >
              {totals.total}
            </TableCell>
          </TableRow>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TeacherByCampusType;
