import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Box,
  IconButton,
  Popover,
  Button,
  Grid,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import React, { useState } from "react";
import { FileDownload, InsertDriveFile, PictureAsPdf } from "@mui/icons-material";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const DetailEnrollmentByCampus = () => {
  const [selectedUniversity, setSelectedUniversity] = useState("All");
  const [selectedCampusType, setSelectedCampusType] = useState("All");
  const [anchorEl, setAnchorEl] = useState(null);

  const rows = [
    {
      id: 1,
      campusName: "Amrit Campus",
      district: "Kathmandu",
      province: "Bagmati",
      faculty: "Science and Technology",
      level: "Undergraduate", // "Undergraduate" or "Masters"
      programName: "Computer Science",
      male: 1000,
      female: 800,
      university: "TU", // Added university field
      campusType: "Constituent", // Added campus type field
    },
    {
      id: 2,
      campusName: "Patan Campus",
      district: "Lalitpur",
      province: "Bagmati",
      faculty: "Science and Technology",
      level: "Undergraduate",
      programName: "Computer Science",
      male: 1200,
      female: 1000,
      university: "KU",
      campusType: "Community",
    },
    {
      id: 3,
      campusName: "Bhairahawa Campus",
      district: "Rupandehi",
      province: "Bagmati",
      faculty: "Management",
      level: "Masters",
      programName: "MBA",
      male: 900,
      female: 700,
      university: "PU",
      campusType: "Private",
    },
  ];

  // Filter rows based on the selected filters
  const filteredRows = rows.filter((row) => {
    return (
      (selectedUniversity === "All" || row.university === selectedUniversity) &&
      (selectedCampusType === "All" || row.campusType === selectedCampusType)
    );
  });

  // Calculate the totals for male, female, and overall student count
  const totals = filteredRows.reduce(
    (acc, row) => {
      acc.male += row.male;
      acc.female += row.female;
      acc.total += row.male + row.female;

      return acc;
    },
    { male: 0, female: 0, total: 0 }
  );

  // Export to Excel
  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(filteredRows.map(row => ({
      'Campus Name': row.campusName,
      "District": row.district,
      'Province': row.province,
      'Faculty': row.faculty,
      'Level': row.level,
      'Program Name': row.programName,
      'Male': row.male,
      'Female': row.female,
      'Total': row.male + row.female,
    })));
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Enrollment Data");
    XLSX.writeFile(wb, "enrollment_data.xlsx");
  };

  // Export to PDF
  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [
        ["S.No.", "Campus Name","District", "Province", "Faculty", "Level", "Program Name", "Male", "Female", "Total"]
      ],
      body: filteredRows.map((row, index) => [
        index + 1,
        row.campusName,
        row.district,
        row.province,
        row.faculty,
        row.level,
        row.programName,
        row.male,
        row.female,
        row.male + row.female,
      ]),
    });
    doc.save("enrollment_data.pdf");
  };

  // Handle Export Button Popover
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "export-popover" : undefined;
  return (
    <div>
      {/* Filter Dropdowns */}
      <Box sx={{ marginBottom: "5px", display: "flex", gap: 2 }}>
        {/* University Dropdown */}
        <FormControl fullWidth sx={{ maxWidth: "30%" }} size="small">
          <InputLabel>University</InputLabel>
          <Select
            size="small"
            sx={{
              backgroundColor: '#fff',
              borderRadius: 2,
              borderColor: '#c2c2c2',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#c2c2c2',
                },
                '&:hover fieldset': {
                  borderColor: blue[500], 
                },
                '&.Mui-focused fieldset': {
                  borderColor: blue[700], 
                  boxShadow: `0 0 0 2px ${blue[100]}`,
                },
              },
              '& .MuiInputLabel-root': {
                fontSize: '1rem',
              },
              '& .MuiSelect-icon': {
                color: blue[700],
              },
            }}
            labelId="university-select-label"
            id="university-select"
            value={selectedUniversity}
            onChange={(e) => setSelectedUniversity(e.target.value)}
            label="University"
          >
            <MenuItem value="All">All University</MenuItem>
            <MenuItem value="TU">TU</MenuItem>
            <MenuItem value="KU">KU</MenuItem>
            <MenuItem value="PU">PU</MenuItem>
          </Select>
        </FormControl>

        {/* Campus Type Dropdown */}
        <FormControl fullWidth sx={{ maxWidth: "30%" }} size="small">
          <InputLabel>Campus Type</InputLabel>
          <Select
            size="small"
            sx={{
              backgroundColor: '#fff',
              borderRadius: 2,
              borderColor: '#c2c2c2',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#c2c2c2',
                },
                '&:hover fieldset': {
                  borderColor: blue[500],
                },
                '&.Mui-focused fieldset': {
                  borderColor: blue[700], 
                  boxShadow: `0 0 0 2px ${blue[100]}`,
                },
              },
              '& .MuiInputLabel-root': {
                fontSize: '1rem',
              },
              '& .MuiSelect-icon': {
                color: blue[700],
              },
            }}
            labelId="campus-type-select-label"
            id="campus-type-select"
            value={selectedCampusType}
            onChange={(e) => setSelectedCampusType(e.target.value)}
            label="Campus Type"
          >
            <MenuItem value="All">All Campuses</MenuItem>
            <MenuItem value="Constituent">Constituent</MenuItem>
            <MenuItem value="Community">Community</MenuItem>
            <MenuItem value="Private">Private</MenuItem>
          </Select>
        </FormControl>
        <Grid container justifyContent="right">
      <Button 
        variant="contained" 
        color="primary"
        size="small"
        startIcon={<FileDownload />} 
        onClick={handleClick}
        style={{ marginBottom: '10px' }}
      >
        Export
      </Button>

      {/* Popover for Excel and PDF options */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box sx={{ padding: '10px' }}>
          <Button 
            variant="contained" 
            color="primary" 
            size="small"
            sx={{ backgroundColor: '#5FAD41' }}
            startIcon={<InsertDriveFile />} 
            onClick={exportToExcel} 
            fullWidth
            style={{ marginBottom: '10px' }}
          >
            Excel
          </Button>
          <Button 
          size="small"
            variant="contained" 
            sx={{ backgroundColor: '#272727' }}
            startIcon={<PictureAsPdf />} 
            onClick={exportToPDF} 
            fullWidth
          >
           PDF
          </Button>
        </Box>
      </Popover>
      </Grid>

        
      </Box>

      <TableContainer sx={{ border: "1px solid #ddd" }}>
        <Table style={{ borderCollapse: "collapse", border: "1px solid #ddd" }}>
          <TableHead style={{ backgroundColor: "#2A629A" }}>
            <TableRow>
              <TableCell sx={{ border: "1px solid #ddd", color: "#ffffff", padding: "4px", textAlign: "center" }}>S.No.</TableCell>
              <TableCell sx={{ border: "1px solid #ddd", color: "#ffffff", padding: "4px", textAlign: "center" }}>Campus Name</TableCell>
              <TableCell sx={{ border: "1px solid #ddd", color: "#ffffff", padding: "4px", textAlign: "center" }}>District</TableCell>
              <TableCell sx={{ border: "1px solid #ddd", color: "#ffffff", padding: "4px", textAlign: "center" }}>Province</TableCell>
              <TableCell sx={{ border: "1px solid #ddd", color: "#ffffff", padding: "4px", textAlign: "center" }}>Faculty</TableCell>
              <TableCell sx={{ border: "1px solid #ddd", color: "#ffffff", padding: "4px", textAlign: "center" }}>Level</TableCell>
              <TableCell sx={{ border: "1px solid #ddd", color: "#ffffff", padding: "4px", textAlign: "center" }}>Program Name</TableCell>
              <TableCell sx={{ border: "1px solid #ddd", color: "#ffffff", padding: "4px", textAlign: "center" }}>Male</TableCell>
              <TableCell sx={{ border: "1px solid #ddd", color: "#ffffff", padding: "4px", textAlign: "center" }}>Female</TableCell>
              <TableCell sx={{ border: "1px solid #ddd", color: "#ffffff", padding: "4px", textAlign: "center" }}>Total</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {filteredRows.map((row, index) => {
              const totalStudents = row.male + row.female;

              return (
                <TableRow key={row.id}>
                  <TableCell sx={{ border: "1px solid #c2c2c2", padding: "4px", textAlign: "center" }}>{index + 1}</TableCell>
                  <TableCell sx={{ border: "1px solid #c2c2c2", padding: "4px", textAlign: "left" }}>{row.campusName}</TableCell>
                  <TableCell sx={{ border: "1px solid #c2c2c2", padding: "4px", textAlign: "left" }}>{row.district}</TableCell>
                  <TableCell sx={{ border: "1px solid #c2c2c2", padding: "4px", textAlign: "left" }}>{row.province}</TableCell>
                  <TableCell sx={{ border: "1px solid #c2c2c2", padding: "4px", textAlign: "left" }}>{row.faculty}</TableCell>
                  <TableCell sx={{ border: "1px solid #c2c2c2", padding: "4px", textAlign: "left" }}>{row.level}</TableCell>
                  <TableCell sx={{ border: "1px solid #c2c2c2", padding: "4px", textAlign: "left" }}>{row.programName}</TableCell>
                  <TableCell sx={{ border: "1px solid #c2c2c2", padding: "4px", textAlign: "right" }}>{row.male}</TableCell>
                  <TableCell sx={{ border: "1px solid #c2c2c2", padding: "4px", textAlign: "right" }}>{row.female}</TableCell>
                  <TableCell sx={{ border: "1px solid #c2c2c2", padding: "4px", textAlign: "right" }}>{totalStudents}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>

          <TableRow style={{ backgroundColor: "#c2c2c2" }}>
            <TableCell
              colSpan={6}
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Grand Total
            </TableCell>
            <TableCell sx={{ border: "1px solid #ddd", padding: "4px", textAlign: "right" }}>{totals.male}</TableCell>
            <TableCell sx={{ border: "1px solid #ddd", padding: "4px", textAlign: "right" }}>{totals.female}</TableCell>
            <TableCell sx={{ border: "1px solid #ddd", padding: "4px", textAlign: "right" }}>{totals.total}</TableCell>
          </TableRow>
        </Table>
      </TableContainer>
    </div>
  );
};

export default DetailEnrollmentByCampus;
