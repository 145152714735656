import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Grid,
  Typography,
  TextField,
  CardContent,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  styled,
} from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

// Create custom styled MenuItem components
const ActiveMenuItem = styled(MenuItem)({
  color: 'green',
});

const InactiveMenuItem = styled(MenuItem)({
  color: 'red',
});

const EditArthikBarsa = ({ id, onClose, onUpdate }) => {
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();
  const [fiscalYear, setFiscalYear] = useState(null); // Initialize as null for a single record

  const onSubmit = async (data) => {
    try {
      const localStorageData = JSON.parse(localStorage.getItem('persist:root'));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;
      const config = {
        headers: {
          'Authorization': `Bearer ${authToken}`,
        }
      };
      const payload = {
        id,
        ...data
      };

      const response = await axios.put(`${backendUrl}/FiscalYear/${id}`, payload, config);

      if (response.status === 200) {
        onUpdate();
        onClose();
        toast.success('Successfully Updated!!', {
          autoClose: 1500
        });
      } else {
        throw new Error('Update failed with status: ' + response.status);
      }
    } catch (err) {
      if (err.response && err.response.status === 409) {
        toast.error("Level already exists!", {
          autoClose: 2000,
        });
      } else {
        toast.error("Failed to add data!", {
          autoClose: 2000,
        });
      }
    }
  };

  const fetchFiscalYear = async () => {
    try {
      const localStorageData = JSON.parse(localStorage.getItem('persist:root'));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;
      const config = {
        headers: {
          'Authorization': `Bearer ${authToken}`,
        }
      };
      const response = await axios.get(`${backendUrl}/FiscalYear/${id}`, config);
      const yearData = response.data;
      setFiscalYear(yearData);
      setValue("yearNepali", yearData.yearNepali);
      setValue("yearEnglish", yearData.yearEnglish);
      setValue("index", yearData.index);
      setValue("activeFiscalYear", yearData.activeFiscalYear);
    } catch (err) {
      console.error('Error fetching fiscal year:', err);
    }
  };

  console.log(fiscalYear)
  useEffect(() => {
    fetchFiscalYear();
  }, [id]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={false} md={2} />
      <Grid item xs={12} md={12}>
        <CardContent>
          <Typography
            variant="h6"
            gutterBottom
            sx={{ textAlign: "center", color: "#2A629A" }}
          >
            Edit Fiscal Year
          </Typography>
          {fiscalYear ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={3}>
                  <TextField
                    required
                    {...register("yearNepali", { required: true })}
                    id="yearNepali"
                    size="small"
                    name="yearNepali"
                    label="Fiscal Year (B.S.)"
                    fullWidth
                    autoComplete="given-name"
                    error={!!errors.yearNepali}
                    helperText={errors.yearNepali ? "Fiscal Year required" : ""}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    required
                    {...register("yearEnglish", { required: true })}
                    id="yearEnglish"
                    size="small"
                    name="yearEnglish"
                    label="Fiscal Year (A.D.)"
                    fullWidth
                    error={!!errors.yearEnglish}
                    helperText={errors.yearEnglish ? "Fiscal Year required" : ""}
                  />
                </Grid>
                <Grid item sm='2'>
                  <TextField
                     required
                     {...register("index", { required: true })}
                     id="index"
                     size="small"
                     type="number"
                     name="index"
                     label="Index"
                     fullWidth
                     error={!!errors.index}
                     helperText={errors.index ? "index is  required" : ""}
                   
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Status</InputLabel>
                    <Select
                      {...register("activeFiscalYear", { required: true })}
                      id="activeFiscalYear"
                      size="small"
                      label="Status"
                      fullWidth
                      value={fiscalYear?.activeFiscalYear ?? ''}
                      onChange={(e) => setValue("activeFiscalYear", e.target.value)}
                    >
                      <ActiveMenuItem value={true}>Active</ActiveMenuItem>
                      <InactiveMenuItem value={false}>Inactive</InactiveMenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="center"
                sx={{ mt: 2 }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Update
                </Button>
              </Grid>
            </form>
          ) : (
            <Typography variant="body1" sx={{ textAlign: "center" }}>
              Loading...
            </Typography>
          )}
        </CardContent>
      </Grid>
    </Grid>
  );
};

export default EditArthikBarsa;
