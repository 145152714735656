// 3.9 Report
import {
  Box,
  Button,
  Grid,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";
import { FileDownload, InsertDriveFile, PictureAsPdf } from "@mui/icons-material";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";

const CampusTypeByFaculty = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const rows = [
    {
      id: 1,
      faculty: "Agriculture",
      constituteMale: 10,
      constituteFemale: 20,
      constituteTotal: 30,
      privateMale: 40,
      privateFemale: 50,
      privateTotal: 90,
      communityMale: 50,
      communityFemale: 50,
      communityTotal: 100,
    },
    {
      id: 2,
      faculty: "AVF",
      constituteMale: 10,
      constituteFemale: 20,
      constituteTotal: 30,
      privateMale: 40,
      privateFemale: 50,
      privateTotal: 90,
      communityMale: 50,
      communityFemale: 50,
      communityTotal: 100,
    },
    {
      id: 3,
      faculty: "Ayurvedic",
      constituteMale: 10,
      constituteFemale: 20,
      constituteTotal: 30,
      privateMale: 40,
      privateFemale: 50,
      privateTotal: 90,
      communityMale: 50,
      communityFemale: 50,
      communityTotal: 100,
    },
    {
      id: 4,
      faculty: "Forestry",
      constituteMale: 10,
      constituteFemale: 20,
      constituteTotal: 30,
      privateMale: 40,
      privateFemale: 50,
      privateTotal: 90,
      communityMale: 50,
      communityFemale: 50,
      communityTotal: 100,
    },
    {
      id: 5,
      faculty: "Medicine",
      constituteMale: 10,
      constituteFemale: 20,
      constituteTotal: 30,
      privateMale: 40,
      privateFemale: 50,
      privateTotal: 90,
      communityMale: 50,
      communityFemale: 50,
      communityTotal: 100,
    },
  ];

  // Calculate the totals for each column and the overall total
  const totals = rows.reduce(
    (acc, row) => {
      acc.constituteMale += row.constituteMale;
      acc.constituteFemale += row.constituteFemale;
      acc.constituteTotal += row.constituteTotal;
      acc.privateMale += row.privateMale;
      acc.privateFemale += row.privateFemale;
      acc.privateTotal += row.privateTotal;
      acc.communityMale += row.communityMale;
      acc.communityFemale += row.communityFemale;
      acc.communityTotal += row.communityTotal;
      acc.grandTotal +=
        row.constituteTotal + row.privateTotal + row.communityTotal; // Sum of all totals
      return acc;
    },
    {
      constituteMale: 0,
      constituteFemale: 0,
      constituteTotal: 0,
      privateMale: 0,
      privateFemale: 0,
      privateTotal: 0,
      communityMale: 0,
      communityFemale: 0,
      communityTotal: 0,
      grandTotal: 0,
    }
  );
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "CampusTypeByFaculty");
    XLSX.writeFile(workbook, "CampusTypeByFaculty.xlsx");
    handleClose();
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.setFont("helvetica", "normal");
    doc.text("Campus Type By Faculty", 14, 16);
    doc.autoTable({
      head: [
        [
          "S.No.",
          "Faculty",
          "Constitute Male",
          "Constitute Female",
          "Constitute Total",
          "Private Male",
          "Private Female",
          "Private Total",
          "Community Male",
          "Community Female",
          "Community Total",
          "Total",
        ],
      ],
      body: rows.map((row, index) => [
        index + 1,
        row.faculty,
        row.constituteMale,
        row.constituteFemale,
        row.constituteTotal,
        row.privateMale,
        row.privateFemale,
        row.privateTotal,
        row.communityMale,
        row.communityFemale,
        row.communityTotal,
        row.constituteTotal + row.privateTotal + row.communityTotal,
      ]),
      startY: 30,
    });
    doc.save("CampusTypeByFaculty.pdf");
    handleClose();
  };
  const open = Boolean(anchorEl);
  const id = open ? "export-popover" : undefined;


  return (
    <div>
    <Grid container justifyContent="right">
      <Button 
        variant="contained" 
        color="primary" 
        startIcon={<FileDownload />} 
        onClick={handleClick}
        style={{ marginBottom: '10px' }}
      >
        Export
      </Button>

      {/* Popover for Excel and PDF options */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box sx={{ padding: '10px' }}>
          <Button 
            variant="contained" 
            color="primary" 
            sx={{ backgroundColor: '#5FAD41' }}
            startIcon={<InsertDriveFile />} 
            onClick={exportToExcel} 
            fullWidth
            style={{ marginBottom: '10px' }}
          >
            Excel
          </Button>
          <Button 
            variant="contained" 
            sx={{ backgroundColor: '#272727' }}
            startIcon={<PictureAsPdf />} 
            onClick={exportToPDF} 
            fullWidth
          >
           PDF
          </Button>
        </Box>
      </Popover>
      </Grid>
      <TableContainer sx={{ border: "1px solid #ddd" }}>
        <Table style={{ borderCollapse: "collapse", border: "1px solid #ddd" }}>
          <TableHead style={{ backgroundColor: "#2A629A" }}>
            <TableRow>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
                rowSpan={3}
              >
                S.No.
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
                rowSpan={3}
              >
                Faculty
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
                colSpan={10}
              >
                Campus Type
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
                colSpan={3}
              >
                Constitue Campus
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
                colSpan={3}
              >
                Private Campus
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
                colSpan={3}
              >
                Community Campus
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
                rowSpan={2}
              >
                Total
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Male
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Female
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Total
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Male
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Female
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Total
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Male
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Female
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Total
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={row.id}>
                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "center",
                  }}
                >
                  {index + 1}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "left",
                  }}
                >
                  {row.faculty}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {row.constituteMale}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {row.constituteFemale}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {row.constituteTotal}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {row.privateMale}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {row.privateFemale}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {row.privateTotal}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {row.communityMale}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {row.communityFemale}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {row.communityTotal}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {row.constituteTotal + row.privateTotal + row.communityTotal}
                </TableCell>
              </TableRow>
            ))}
            <TableRow sx={{ backgroundColor: "#c2c2c2" }}>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  padding: "4px",
                  textAlign: "center",
                }}
                colSpan={2}
              >
                Grand Total
              </TableCell>

              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                {totals.constituteMale}
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                {totals.constituteFemale}
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                {totals.constituteTotal}
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                {totals.privateMale}
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                {totals.privateFemale}
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                {totals.privateTotal}
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                {totals.communityMale}
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                {totals.communityFemale}
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                {totals.communityTotal}
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                {totals.grandTotal}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default CampusTypeByFaculty;
