import React from 'react'
import UserAppBar from '../navbar/UserAppBar';
import AddModule from './AddModule';
import PermissionManagement from './PermissionManagement';
import UserPermissionManagement from './UserPermissionManagement';
import { Box } from '@mui/material';

const UserManagementHome = () => {
  return (
    <>
    <Box sx={{
      margin: 0, padding: 0,
      height: '100%',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    }}>
      <Box mt={0}>
      <UserAppBar/>
      </Box>
     </Box>
    <div>

    <AddModule/>
    {/* <PermissionManagement/>
    <UserPermissionManagement/> */}
    </div>
    </>
  )
}

export default UserManagementHome;