import React, { useState, useEffect } from 'react';
import { Button, Grid, Table, TableCell, TableContainer, TableRow, Typography, TableBody, TableHead, Select, MenuItem, OutlinedInput, InputLabel, FormControl } from '@mui/material';
import { styled } from "@mui/material";

const FeeSetupList = () => {
  const [getProgramData, setGetProgramData] = useState([]);
  const [filteredProgramData, setFilteredProgramData] = useState([]);
  const [selectedFeeType, setSelectedFeeType] = useState('');
  const [selectedProgramType, setSelectedProgramType] = useState('');
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedProgramId, setSelectedProgramId] = useState(null);

  useEffect(() => {
    // Fetch program data from your API or data source here
    // For example:
    // fetch('/api/programs')
    //   .then(response => response.json())
    //   .then(data => setGetProgramData(data))
    //   .catch(error => console.error('Error fetching data:', error));
  }, []);

  const handleEditClick = (programId) => {
    setSelectedProgramId(programId);
    setOpenEditDialog(true);
  };

  const handleFeeTypeChange = (event) => {
    setSelectedFeeType(event.target.value);
  };

  const handleProgramTypeChange = (event) => {
    setSelectedProgramType(event.target.value);
  };

  useEffect(() => {
    if (selectedFeeType && selectedProgramType) {
      const filteredData = getProgramData.filter(program => 
        program.feeType === selectedFeeType && program.programType === selectedProgramType
      );
      setFilteredProgramData(filteredData);
    } else {
      setFilteredProgramData(getProgramData);
    }
  }, [selectedFeeType, selectedProgramType, getProgramData]);

  return (
    <div>
      <Grid marginTop="20px">
        <Grid container spacing={2} justifyContent="left" alignItems="center">
          <Grid item>
            <FormControl variant="outlined" size="small" sx={{ minWidth: 150 }}>
              <InputLabel id="fee-type-label">Select Fee Type</InputLabel>
              <Select
                labelId="fee-type-label"
                value={selectedFeeType}
                onChange={handleFeeTypeChange}
                input={<OutlinedInput label="Select Fee Type" />}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '10px',
                  },
                }}
              >
                <MenuItem value=""><em>Select Fee Type</em></MenuItem>
                <MenuItem value="admissionFee">Admission Fee</MenuItem>
                <MenuItem value="tutionFee">Tuition Fee</MenuItem>
                <MenuItem value="formFee">Form Fee</MenuItem>
                <MenuItem value="examFee">Exam Form Fee</MenuItem>
                <MenuItem value="otherFee">Other Fee</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl variant="outlined" size="small" sx={{ minWidth: 150 }}>
              <InputLabel id="program-type-label">Select Program Type</InputLabel>
              <Select
                labelId="program-type-label"
                value={selectedProgramType}
                onChange={handleProgramTypeChange}
                input={<OutlinedInput label="Select Program Type" />}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '10px',
                  },
                }}
              >
                <MenuItem value=""><em>Select Program Type</em></MenuItem>
                <MenuItem value="annual">Annual</MenuItem>
                <MenuItem value="semester">Semester</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {/* Conditional rendering of tables based on selected fee type */}
        {selectedFeeType === 'tutionFee' && (
          <div>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ textAlign: "center", color: "#2A629A" }}
            >
              Tuition Fee Setup for Different Programs
            </Typography>
            <TableContainer>
              <Table style={{ borderCollapse: "collapse", border: "1px solid #ddd" }}>
                <TableHead style={{ backgroundColor: "#2A629A" }}>
                  <TableRow>
                    <TableCell style={{ color: "#FFFFFF", border: "1px solid #ddd", padding: "8px" }}>S.No:</TableCell>
                    <TableCell style={{ color: "#FFFFFF", border: "1px solid #ddd", padding: "8px" }}>Program Name</TableCell>
                    <TableCell style={{ color: "#FFFFFF", border: "1px solid #ddd", padding: "8px" }}>Program Type</TableCell>
                    <TableCell style={{ color: "#FFFFFF", border: "1px solid #ddd", padding: "8px" }}>Duration</TableCell>
                    <TableCell style={{ color: "#FFFFFF", border: "1px solid #ddd", padding: "8px" }}>Acad. Year</TableCell>
                    <TableCell colSpan={8} style={{ color: "#FFFFFF", border: "1px solid #ddd", padding: "8px", textAlign: 'center', position: 'relative' }}>
                      Fee Amount (NRs.) for each Semester/Year
                      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
                        <div style={{ flex: 1, textAlign: 'center' }}>1</div>
                        <div style={{ flex: 1, textAlign: 'center' }}>2</div>
                        <div style={{ flex: 1, textAlign: 'center' }}>3</div>
                        <div style={{ flex: 1, textAlign: 'center' }}>4</div>
                        <div style={{ flex: 1, textAlign: 'center' }}>5</div>
                        <div style={{ flex: 1, textAlign: 'center' }}>6</div>
                        <div style={{ flex: 1, textAlign: 'center' }}>7</div>
                        <div style={{ flex: 1, textAlign: 'center' }}>8</div>
                      </div>
                    </TableCell>
                    <TableCell style={{ color: "#FFFFFF", border: "1px solid #ddd", padding: "8px" }}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredProgramData.map((data, index) => (
                    <TableRow key={data.id}>
                      <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>{index + 1}</TableCell>
                      <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>{data.shortName}</TableCell>
                      <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>{data.faculty?.level?.levelName}</TableCell>
                      <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>{data.duration}</TableCell>
                      <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>{data.academicYear}</TableCell>
                      <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>{data.sem1Fee}</TableCell>
                      <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>{data.sem2Fee}</TableCell>
                      <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>{data.sem3Fee}</TableCell>
                      <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>{data.sem4Fee}</TableCell>
                      <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>{data.sem5Fee}</TableCell>
                      <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>{data.sem6Fee}</TableCell>
                      <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>{data.sem7Fee}</TableCell>
                      <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>{data.sem8Fee}</TableCell>
                      <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>
                        {data.status ? (
                          <span style={{ color: 'green' }}>Active</span>
                        ) : (
                          <span style={{ color: 'red' }}>Inactive</span>
                        )}
                      </TableCell>
                      <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>
                        <Button onClick={() => handleEditClick(data.id)}>Edit</Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}

        {selectedFeeType === 'admissionFee' && (
          <div>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ textAlign: "center", color: "#2A629A" }}
            >
              Admission Fee Setup for Different Programs
            </Typography>
            <TableContainer>
              <Table style={{ borderCollapse: "collapse", border: "1px solid #ddd" }}>
                <TableHead style={{ backgroundColor: "#2A629A" }}>
                  <TableRow>
                    <TableCell style={{ color: "#FFFFFF", border: "1px solid #ddd", padding: "8px" }}>S.No:</TableCell>
                    <TableCell style={{ color: "#FFFFFF", border: "1px solid #ddd", padding: "8px" }}>Program Name</TableCell>
                    <TableCell style={{ color: "#FFFFFF", border: "1px solid #ddd", padding: "8px" }}>Program Type</TableCell>
                    <TableCell style={{ color: "#FFFFFF", border: "1px solid #ddd", padding: "8px" }}>Duration</TableCell>
                    <TableCell style={{ color: "#FFFFFF", border: "1px solid #ddd", padding: "8px" }}>Acad. Year</TableCell>
                    <TableCell style={{ color: "#FFFFFF", border: "1px solid #ddd", padding: "8px" }}>Fee Amount (NRs.)</TableCell>
                    <TableCell style={{ color: "#FFFFFF", border: "1px solid #ddd", padding: "8px" }}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredProgramData.map((data, index) => (
                    <TableRow key={data.id}>
                      <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>{index + 1}</TableCell>
                      <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>{data.shortName}</TableCell>
                      <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>{data.faculty?.level?.levelName}</TableCell>
                      <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>{data.duration}</TableCell>
                      <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>{data.academicYear}</TableCell>
                      <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>{data.admissionFee}</TableCell>
                      <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>
                        {data.status ? (
                          <span style={{ color: 'green' }}>Active</span>
                        ) : (
                          <span style={{ color: 'red' }}>Inactive</span>
                        )}
                      </TableCell>
                      <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>
                        <Button onClick={() => handleEditClick(data.id)}>Edit</Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}

        {selectedFeeType === 'formFee' && (
          <div>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ textAlign: "center", color: "#2A629A" }}
            >
              Form Fee Setup for Different Programs
            </Typography>
            <TableContainer>
              <Table style={{ borderCollapse: "collapse", border: "1px solid #ddd" }}>
                <TableHead style={{ backgroundColor: "#2A629A" }}>
                  <TableRow>
                    <TableCell style={{ color: "#FFFFFF", border: "1px solid #ddd", padding: "8px" }}>S.No:</TableCell>
                    <TableCell style={{ color: "#FFFFFF", border: "1px solid #ddd", padding: "8px" }}>Program Name</TableCell>
                    <TableCell style={{ color: "#FFFFFF", border: "1px solid #ddd", padding: "8px" }}>Program Type</TableCell>
                    <TableCell style={{ color: "#FFFFFF", border: "1px solid #ddd", padding: "8px" }}>Duration</TableCell>
                    <TableCell style={{ color: "#FFFFFF", border: "1px solid #ddd", padding: "8px" }}>Acad. Year</TableCell>
                    <TableCell style={{ color: "#FFFFFF", border: "1px solid #ddd", padding: "8px" }}>Fee Amount (NRs.)</TableCell>
                    <TableCell style={{ color: "#FFFFFF", border: "1px solid #ddd", padding: "8px" }}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredProgramData.map((data, index) => (
                    <TableRow key={data.id}>
                      <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>{index + 1}</TableCell>
                      <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>{data.shortName}</TableCell>
                      <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>{data.faculty?.level?.levelName}</TableCell>
                      <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>{data.duration}</TableCell>
                      <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>{data.academicYear}</TableCell>
                      <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>{data.formFee}</TableCell>
                      <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>
                        {data.status ? (
                          <span style={{ color: 'green' }}>Active</span>
                        ) : (
                          <span style={{ color: 'red' }}>Inactive</span>
                        )}
                      </TableCell>
                      <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>
                        <Button onClick={() => handleEditClick(data.id)}>Edit</Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}

        {selectedFeeType === 'examFee' && (
          <div>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ textAlign: "center", color: "#2A629A" }}
            >
              Exam Fee Setup for Different Programs
            </Typography>
            <TableContainer>
              <Table style={{ borderCollapse: "collapse", border: "1px solid #ddd" }}>
                <TableHead style={{ backgroundColor: "#2A629A" }}>
                  <TableRow>
                    <TableCell style={{ color: "#FFFFFF", border: "1px solid #ddd", padding: "8px" }}>S.No:</TableCell>
                    <TableCell style={{ color: "#FFFFFF", border: "1px solid #ddd", padding: "8px" }}>Program Name</TableCell>
                    <TableCell style={{ color: "#FFFFFF", border: "1px solid #ddd", padding: "8px" }}>Program Type</TableCell>
                    <TableCell style={{ color: "#FFFFFF", border: "1px solid #ddd", padding: "8px" }}>Duration</TableCell>
                    <TableCell style={{ color: "#FFFFFF", border: "1px solid #ddd", padding: "8px" }}>Acad. Year</TableCell>
                    <TableCell style={{ color: "#FFFFFF", border: "1px solid #ddd", padding: "8px" }}>Fee Amount (NRs.)</TableCell>
                    <TableCell style={{ color: "#FFFFFF", border: "1px solid #ddd", padding: "8px" }}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredProgramData.map((data, index) => (
                    <TableRow key={data.id}>
                      <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>{index + 1}</TableCell>
                      <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>{data.shortName}</TableCell>
                      <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>{data.faculty?.level?.levelName}</TableCell>
                      <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>{data.duration}</TableCell>
                      <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>{data.academicYear}</TableCell>
                      <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>{data.examFee}</TableCell>
                      <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>
                        {data.status ? (
                          <span style={{ color: 'green' }}>Active</span>
                        ) : (
                          <span style={{ color: 'red' }}>Inactive</span>
                        )}
                      </TableCell>
                      <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>
                        <Button onClick={() => handleEditClick(data.id)}>Edit</Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}

        {selectedFeeType === 'otherFee' && (
          <div>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ textAlign: "center", color: "#2A629A" }}
            >
              Other Fee Setup for Different Programs
            </Typography>
            <TableContainer>
              <Table style={{ borderCollapse: "collapse", border: "1px solid #ddd" }}>
                <TableHead style={{ backgroundColor: "#2A629A" }}>
                  <TableRow>
                    <TableCell style={{ color: "#FFFFFF", border: "1px solid #ddd", padding: "8px" }}>S.No:</TableCell>
                    <TableCell style={{ color: "#FFFFFF", border: "1px solid #ddd", padding: "8px" }}>Program Name</TableCell>
                    <TableCell style={{ color: "#FFFFFF", border: "1px solid #ddd", padding: "8px" }}>Program Type</TableCell>
                    <TableCell style={{ color: "#FFFFFF", border: "1px solid #ddd", padding: "8px" }}>Duration</TableCell>
                    <TableCell style={{ color: "#FFFFFF", border: "1px solid #ddd", padding: "8px" }}>Acad. Year</TableCell>
                    <TableCell style={{ color: "#FFFFFF", border: "1px solid #ddd", padding: "8px" }}>Fee Amount (NRs.)</TableCell>
                    <TableCell style={{ color: "#FFFFFF", border: "1px solid #ddd", padding: "8px" }}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredProgramData.map((data, index) => (
                    <TableRow key={data.id}>
                      <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>{index + 1}</TableCell>
                      <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>{data.shortName}</TableCell>
                      <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>{data.faculty?.level?.levelName}</TableCell>
                      <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>{data.duration}</TableCell>
                      <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>{data.academicYear}</TableCell>
                      <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>{data.otherFee}</TableCell>
                      <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>
                        {data.status ? (
                          <span style={{ color: 'green' }}>Active</span>
                        ) : (
                          <span style={{ color: 'red' }}>Inactive</span>
                        )}
                      </TableCell>
                      <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>
                        <Button onClick={() => handleEditClick(data.id)}>Edit</Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </Grid>
    </div>
  );
};

export default FeeSetupList;
