import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import axios from "axios";
import StudentAppBar from "../../modules/navbar/studentAppBar";

const columns = [
  { name: "no", label: "S.No.", options: { sort: false, filter: false } },
  { name: "district", label: "District", options: { filter: false, sort: false } },
  { name: "male", label: "Male", options: { filter: false, sort: false } },
  { name: "female", label: "Female", options: { filter: false, sort: false } },
  { name: "totalGender", label: "Total Gender", options: { filter: false, sort: false } },
  { name: "madhesi", label: "Madhesi", options: { filter: false, sort: false } },
  { name: "janjati", label: "Janajati", options: { filter: false, sort: false } },
  { name: "tharu", label: "Tharu", options: { filter: false, sort: false } },
  { name: "edj", label: "EDJ", options: { filter: false, sort: false } },
  { name: "dalit", label: "Dalit", options: { filter: false, sort: false } },
  { name: "totalEthnicity", label: "Total Caste", options: { filter: false, sort: false } },
];

const options = {
  filterType: "",
  selectableRows: false,
  responsive: "standard",
  elevation: 0,
  pagination: true,
  search: true,
  searchPlaceholder: "Search...",
  searchProps: {
    style: {
      fontSize: 14,
      padding: 4,
      borderRadius: 4,
      width: 6,
    },
    inputProps: {
      placeholder: "Search...",
      style: {
        fontSize: 14,
        padding: 4,
      },
    },
  },
  print: true,
  download: true,
  viewColumns: false,
  rowsPerPage: 15,
};

const getMuiTheme = () =>
  createTheme({
    components: {
      MuiTableCell: {
        styleOverrides: {
          head: {
            backgroundColor: "#2b6eb5",
            color: "whitesmoke",
            textTransform: "capitalize",
            padding: "6px",
            fontSize: "12px",
            border: "1px solid #C2C2C2",
          },
          body: {
            fontSize: "12px",
            padding: "6px",
            border: "1px solid #C2C2C2",
          },
          root: {
            justifyContent: "center",
            "& input": {
              textAlign: "center",
            },
            padding: "0px",
          },
        },
      },
    },
  });

const CustomHeader = () => {
  return (
    <thead>
      <tr style={{ backgroundColor: "#2b6eb5", color: "whitesmoke" }}>
        <th rowSpan="2" style={{ border: "1px solid #c2c2c2", fontSize: "14px" }}>
          S.No.
        </th>
        <th rowSpan="2" style={{ border: "1px solid #c2c2c2", fontSize: "14px" }}>
          District
        </th>
        <th colSpan="3" style={{ border: "1px solid #c2c2c2", fontSize: "14px" }}>
          Gender
        </th>
        <th colSpan="6" style={{ border: "1px solid #c2c2c2", fontSize: "14px" }}>
          Caste Ethnicity
        </th>
      </tr>
      <tr style={{ backgroundColor: "#2b6eb5", color: "whitesmoke" }}>
        <th style={{ border: "1px solid #c2c2c2", padding: "5px", fontSize: "14px" }}>
          Male
        </th>
        <th style={{ border: "1px solid #c2c2c2", padding: "5px", fontSize: "14px" }}>
          Female
        </th>
        <th style={{ border: "1px solid #c2c2c2", padding: "5px", fontSize: "14px" }}>
          Total
        </th>
        <th style={{ border: "1px solid #c2c2c2", padding: "5px", fontSize: "14px" }}>
          Madhesi
        </th>
        <th style={{ border: "1px solid #c2c2c2", padding: "5px", fontSize: "14px" }}>
          Janajati
        </th>
        <th style={{ border: "1px solid #c2c2c2", padding: "5px", fontSize: "14px" }}>
          Tharu
        </th>
        <th style={{ border: "1px solid #c2c2c2", padding: "5px", fontSize: "14px" }}>
          EDJ
        </th>
        <th style={{ border: "1px solid #c2c2c2", padding: "5px", fontSize: "14px" }}>
          Dalit
        </th>
        <th style={{ border: "1px solid #c2c2c2", padding: "5px", fontSize: "14px" }}>
          Total
        </th>
      </tr>
    </thead>
  );
};

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const StudentByDistrict = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const localStorageData = JSON.parse(localStorage.getItem('persist:root'));
        const userState = JSON.parse(localStorageData.user);
        const authToken = userState.currentUser.tokenString;
        
        const config = {
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json'
          }
        };

        const response = await axios.get(
          `${backendUrl}/Student/Report/GetStudentByDistrict`, config
        );

        // Group data by district
        const groupedData = response.data.reduce((acc, student) => {
          const district = student.district;
          if (!acc[district]) {
            acc[district] = { ...student, male: 0, female: 0, totalGender: 0, madhesi: 0, janjati: 0, tharu: 0, edj: 0, dalit: 0 };
          }
          // Accumulate the data for each district
          acc[district].male += student.male;
          acc[district].female += student.female;
          acc[district].totalGender += student.totalGender;
          acc[district].madhesi += student.madhesi;
          acc[district].janjati += student.janjati;
          acc[district].tharu += student.tharu;
          acc[district].edj += student.edj;
          acc[district].dalit += student.dalit;

          return acc;
        }, {});

        // Convert grouped data into an array format
        const formattedData = Object.values(groupedData).map((district, index) => ({
          ...district,
          no: index + 1,
        }));

        setData(formattedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Box
        sx={{
          margin: 0,
          padding: 0,
          height: "100%",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box mt={8}>
          <StudentAppBar />
        </Box>
      </Box>
      <ThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title={
            <Typography
              variant="body1"
              style={{
                color: "#2b6eb5",
                padding: "5px",
                textAlign: "right",
              }}
            >
              Student By District
            </Typography>
          }
          data={data}
          columns={columns}
          options={options}
          components={{
            TableHead: CustomHeader,
          }}
        />
      </ThemeProvider>
    </>
  );
};

export default StudentByDistrict;
