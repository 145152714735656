import React, { useState } from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Checkbox,
  Chip,
  Grid,
  Tooltip,
} from '@mui/material';
import { grey, blue, red, green } from '@mui/material/colors';
import UserAppBar from '../navbar/UserAppBar';

const UserPermissionManagement = () => {
  // Example data structure for users with active/inactive status and remarks
  const users = [
    {
      name: 'Hari Ram',
      module: 'Student Management',
      status: 'Active',
      remarks: 'Admin User',
      permissions: ['Create', 'Update', 'Delete'],
    },
    {
      name: 'Sita Ram',
      module: 'employee Management',
      status: 'Inactive',
      remarks: 'Guest User',
      permissions: ['Read'],
    },
    {
      name: 'Govindam Shyam',
      module: 'User Management',
      status: 'Active',
      remarks: 'Manager',
      permissions: ['Create', 'Read'],
    },
  ];

  const [selectedUser, setSelectedUser] = useState('');
  const [message, setMessage] = useState('');
  const [selectedPermissions, setSelectedPermissions] = useState([]);

  // Handle user selection
  const handleUserChange = (event) => {
    const user = users.find((user) => user.name === event.target.value);
    setSelectedUser(user);
    setSelectedPermissions(user.permissions || []);

    // Show message for inactive users
    if (user.status === 'Inactive') {
      setMessage('Permission not given');
    } else {
      setMessage('');
    }
  };

  // Handle checkbox change
  const handleCheckboxChange = (moduleName) => {
    const updatedPermissions = selectedPermissions.includes(moduleName)
      ? selectedPermissions.filter((permission) => permission !== moduleName)
      : [...selectedPermissions, moduleName];

    setSelectedPermissions(updatedPermissions);
  };

  return (
    <div>
    <Box sx={{
      margin: 0, padding: 0,
      height: '100%',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    }}>
      <Box mt={8}>
      <UserAppBar/>
      </Box>
     </Box>
      <Box sx={{ mt: 3, maxWidth: 1200, margin: '0 auto' }}>
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'semibold', color: blue[700], textAlign:'center' }}>
          User Permission Management
        </Typography>

        {/* Select User Dropdown */}
        <FormControl fullWidth  sx={{ maxWidth: 500, pb:1 }} size="small">
          <InputLabel id="user-select-label" sx={{ fontWeight: 'semibold', color: blue[700] }}>
            Select User
          </InputLabel>
          <Select
            labelId="user-select-label"
            id="user-select"
            size="small"
            label="Select User"
            sx={{
              backgroundColor: '#fff',
              borderRadius: 2,
              borderColor: '#c2c2c2',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#c2c2c2',
                },
                '&:hover fieldset': {
                  borderColor: blue[500], 
                },
                '&.Mui-focused fieldset': {
                  borderColor: blue[700], 
                  boxShadow: `0 0 0 2px ${blue[100]}`,
                },
              },
              '& .MuiInputLabel-root': {
                fontSize: '1rem', // Slightly larger label
              },
              '& .MuiSelect-icon': {
                color: blue[700], // Color for the dropdown arrow
              },
            }}
            value={selectedUser.name || ''}
            onChange={handleUserChange}
          >
            {users.map((user) => (
              <MenuItem key={user.name} value={user.name}>
                {user.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Message for Inactive User */}
        {message && (
          <Box sx={{ mt: 2, backgroundColor: grey[200], padding: '10px', borderRadius: 1 }}>
            <Typography variant="body2" sx={{ color: red[600], fontWeight: 'bold' }}>
              {message}
            </Typography>
          </Box>
        )}

        {/* Table for Users */}
        <TableContainer component={Paper} sx={{ boxShadow: 3, borderRadius: 2, }}>
          <Table sx={{ minWidth: 650 }} aria-label="user-permission-management-table">
            <TableHead sx={{ backgroundColor: blue[600] }}>
              <TableRow>
              <TableCell
                  sx={{
                    fontWeight: 'semibold',
                    fontSize: '1rem',
                    color: 'white',
                    textAlign:'center',
                    border: `1px solid #c2c2c2`,
                    padding: '6px',
                  }}
                >
                 S.No.
                </TableCell>
              <TableCell
                  sx={{
                    fontWeight: 'semibold',
                    fontSize: '1rem',
                    color: 'white',
                    textAlign:'center',
                    border: `1px solid #c2c2c2`,
                    padding: '6px',
                  }}
                >
                 Users
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 'semibold',
                    fontSize: '1rem',
                    color: 'white',
                    textAlign:'center',
                    border: `1px solid #c2c2c2`,
                    padding: '6px',
                  }}
                >
                  Modules Name
                </TableCell>
                
                <TableCell
                  align="center"
                  sx={{
                    fontWeight: 'semibold',
                    fontSize: '1rem',
                    color: 'white',
                    border: `1px solid #c2c2c2`,
                    padding: '6px',
                  }}
                >
                  Status
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontWeight: 'semibold',
                    fontSize: '1rem',
                    color: 'white',
                    border: `1px solid #c2c2c2`,
                    padding: '6px', 
                  }}
                >
                  Remarks
                </TableCell>
              </TableRow>
            </TableHead>

            {/* Map over the users data */}
            {users.map((user, index) => {
              const isInactive = user.status === 'Inactive';

              return (
                <TableRow
                  key={index}
                  sx={{
                    backgroundColor: isInactive ? grey[100] : 'white',
                    cursor: isInactive ? 'not-allowed' : 'pointer',
                    opacity: isInactive ? 0.6 : 1,
                    '&:hover': {
                      backgroundColor: isInactive ? grey[100] : blue[50],
                    },
                  }}
                >
                <TableCell
                    align="left"
                    sx={{
                      padding: '6px', // Reduced padding to decrease row height
                      border: '1px solid #c2c2c2', // Border added between rows
                    }}
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      padding: '6px', // Reduced padding to decrease row height
                      border: '1px solid #c2c2c2', // Border added between rows
                    }}
                  >
                    {user.name}
                  </TableCell>
                  <TableCell
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '2px',
                      border: '1px solid #c2c2c2', 
                    }}
                  >
                    <Grid item key={user.module}>
                      <Tooltip title={isInactive ? 'Permission not given' : ''} arrow placement="top">
                        <span>
                          <Checkbox
                            checked={user.permissions.includes(user.module)}
                            onChange={() => handleCheckboxChange(user.module)}
                            disabled={isInactive} // Disable checkbox for inactive users
                            color="primary"
                          />
                          {user.module}
                        </span>
                      </Tooltip>
                    </Grid>
                  </TableCell>
                  

                  <TableCell
                    align="center"
                    sx={{
                      padding: '6px', // Reduced padding to decrease row height
                      border: '1px solid #c2c2c2', // Border added between rows
                    }}
                  >
                    <Chip
                      label={user.status}
                      color={user.status === 'Active' ? 'success' : 'error'}
                      size="small"
                      sx={{ textTransform: 'capitalize' }}
                    />
                  </TableCell>

                  <TableCell
                    align="left"
                    sx={{
                      padding: '6px', // Reduced padding to decrease row height
                      border: '1px solid #c2c2c2', // Border added between rows
                    }}
                  >
                    {user.remarks}
                  </TableCell>
                </TableRow>
              );
            })}
          </Table>
        </TableContainer>

        {/* Button to Assign Permissions (if active) */}
        <Box sx={{ mt: 3, textAlign: 'center' }}>
          <Button
            variant="contained"
            color="primary"
            
          >
            Assign Permissions
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default UserPermissionManagement;
