import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Typography,
  Divider,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Grid,
} from "@mui/material";
import InstitutionsStatus from "./InstitutionsStatus";
import CampusInFaculty from "./CampusInFaculty";
import ProvinceWiseCampus from "./ProvinceWiseCampus";
import GenderWiseEnrollment from "./GenderWiseEnrollment";
import CampusTypeByGender from "./CampusTypeByGender";
import ProgramsByLevels from "./ProgramsByLevels";
import CampusTypeByFaculty from "./CampusTypeByFaculty";
import TeacherByCampusType from "./TeacherByCampusType";
import UniversityEnrollmentByYear from "./UniversityEnrollmentByYear";
import CampusTypeEnrollmentByYear from "./CampusTypeEnrollmentByYear";
import { blue } from "@mui/material/colors";
import StudentAppBar from "../../modules/navbar/studentAppBar";
import DetailEnrollmentByCampus from "./DetailEnrollmentByCampus";
import RegionWiseStudentCampus from "./RegionWiseStudentCampus";
import { getFiscalYear } from "../../services/services";
import Report1 from "./PrabhatReport/Report1";
import ProvinceWiseStudents from "./ProvinceWiseStudents";

export default function AccordionUsage() {
  const [expanded, setExpanded] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [selectedYear, setSelectedYear] = React.useState(""); // State to store yearNepali

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value); // Set selectedYear to yearNepali directly
  };

  React.useEffect(() => {
    const response = async () => {
      try {
        const fiscalYear = await getFiscalYear();
        setData(fiscalYear);
        if (fiscalYear.length > 0) {
          setSelectedYear(fiscalYear[0].yearNepali); // Set the default yearNepali
        }
      } catch (err) {
        console.log(err);
      }
    };
    response();
  }, []);

  return (
    <>
      <Box
        sx={{
          margin: 0,
          padding: 0,
          height: "100%",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box mt={8}>
          <StudentAppBar />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          minHeight: "100vh",
          padding: 2,
        }}
      >
        <Typography
          variant="h6"
          align="center"
          gutterBottom
          sx={{ marginTop: 2, color: blue[700] }}
        >
          Higher Education Data in Nepal (Fiscal Year{" "}
          {selectedYear ? selectedYear : "Select Year"})
        </Typography>
        <Divider sx={{ marginBottom: 2, width: "100%" }} />
        <Grid container justifyContent="left" marginBottom={1}>
          <FormControl fullWidth sx={{ maxWidth: "20%" }} size="small">
            <InputLabel>Fiscal Year</InputLabel>
            <Select
              size="small"
              sx={{
                backgroundColor: "#fff",
                borderRadius: 2,
                borderColor: "#c2c2c2",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#c2c2c2",
                  },
                  "&:hover fieldset": {
                    borderColor: blue[500],
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: blue[700],
                    boxShadow: `0 0 0 2px ${blue[100]}`,
                  },
                },
                "& .MuiInputLabel-root": {
                  fontSize: "1rem",
                },
                "& .MuiSelect-icon": {
                  color: blue[700],
                },
              }}
              labelId="fiscal-year-select-label"
              id="fiscal-year-select"
              label="Fiscal Year"
              value={selectedYear} // Set value to yearNepali
              onChange={handleYearChange}
            >
              <MenuItem value="">Select Fiscal Year</MenuItem>
              {data.map((item) => (
                <MenuItem key={item.id} value={item.yearNepali}>
                  {" "}
                  {/* Set value to yearNepali */}
                  {item.yearNepali}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {[
          {
            title: "1. Total Studenst by college type  ",
            component: <InstitutionsStatus />,
          },
          {
            title:
              "2. Number of Campuses in Each faculty/institute of the Universities.",
            component: <CampusInFaculty />,
          },
          {
            title: "3. Number of Campuses in Each University by Provinces.",
            component: <ProvinceWiseCampus />,
          },
          {
            title: "4. Number of students in Each University by  Provinces.",
            component: <ProvinceWiseStudents />,
          },
          {
            title: "5. Gender-Wise enrollment in different Universities.",
            component: <GenderWiseEnrollment />,
          },
          // {
          //   title:
          //     "Gender-Wise student enrollment in three different Types of Campus.",
          //   component: <CampusTypeByGender />,
          // },
          {
            title: "6. Number of campuses and Students in the ecological Belts.",
            component: <RegionWiseStudentCampus />,
          },
          {
            title:
              "7. Student Enrollment in different levels of Programs in Universities.",
            component: <ProgramsByLevels />,
          },
          {
            title:
              "8. Student enrollment in different faculties by types of Campus.",
            component: <CampusTypeByFaculty />,
          },
          {
            title:
              "9. Number of different Level of teacher in different Types of Campus.",
            component: <TeacherByCampusType />,
          },
          {
            title:
              "10. University-Wise total Enrollment and their share (%) in different Years.",
            component: <UniversityEnrollmentByYear />,
          },
          {
            title:
              "11. Enrollment and share of students in different types of campuses in different years.",
            component: <CampusTypeEnrollmentByYear />,
          },
          {
            title: "12. Detail Enrollment Information In Each Campus.",
            component: <DetailEnrollmentByCampus />,
          },
        ].map((item, index) => (
          <Accordion
            key={index}
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
            sx={{ marginBottom: 1.5, width: "100%", borderRadius: 1 }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: blue[700] }} />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
              sx={{
                backgroundColor: "#ffffff",
                "&:hover": { backgroundColor: "#e0e0e0" },
                borderRadius: 1,
                padding: "5px 12px",
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontWeight: 500, color: blue[700] }}
              >
                {item.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 1, backgroundColor: "#fafafa" }}>
              {item.component}
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </>
  );
}
