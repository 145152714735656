import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { Box } from '@mui/material';
import axios from 'axios';

const columns1 = [
  { name: 'post', label: 'Post', options: { sort: false, filter: false } },
  { name: 'male', label: 'Male', options: { filter: false, sort: false } },
  { name: 'female', label: 'Female', options: { filter: false, sort: false } },
  { name: 'other', label: 'Other', options: { filter: false, sort: false } },
  { name: 'totalGender', label: ' Gender', options: { filter: false, sort: false } },


  { name: 'male', label: 'Male', options: { filter: false, sort: false } },
  { name: 'female', label: 'Female', options: { filter: false, sort: false } },
  { name: 'other', label: 'Other', options: { filter: false, sort: false } },
  { name: 'totalGender', label: ' Gender', options: { filter: false, sort: false } },


  { name: 'male', label: 'Male', options: { filter: false, sort: false } },
  { name: 'female', label: 'Female', options: { filter: false, sort: false } },
  { name: 'other', label: 'Other', options: { filter: false, sort: false } },
  { name: 'totalGender', label: ' Gender', options: { filter: false, sort: false } },

  { name: 'male', label: 'Male', options: { filter: false, sort: false } },
  { name: 'female', label: 'Female', options: { filter: false, sort: false } },
  { name: 'other', label: 'Other', options: { filter: false, sort: false } },
  { name: 'totalGender', label: ' Gender', options: { filter: false, sort: false } },


  { name: 'grandTotal', label: 'Grand Total', options: { sort: false, filter: false } },

];


const columns2 = [
  { name: 'ethnicity', label: 'Ethnicity', options: { sort: false, filter: false } },
  { name: 'male', label: 'Male', options: { filter: false, sort: false } },
  { name: 'female', label: 'Female', options: { filter: false, sort: false } },
  { name: 'other', label: 'Other', options: { filter: false, sort: false } },
  { name: 'totalGender', label: ' Gender', options: { filter: false, sort: false } },


  { name: 'male', label: 'Male', options: { filter: false, sort: false } },
  { name: 'female', label: 'Female', options: { filter: false, sort: false } },
  { name: 'other', label: 'Other', options: { filter: false, sort: false } },
  { name: 'totalGender', label: ' Gender', options: { filter: false, sort: false } },


  { name: 'male', label: 'Male', options: { filter: false, sort: false } },
  { name: 'female', label: 'Female', options: { filter: false, sort: false } },
  { name: 'other', label: 'Other', options: { filter: false, sort: false } },
  { name: 'totalGender', label: ' Gender', options: { filter: false, sort: false } },

  { name: 'male', label: 'Male', options: { filter: false, sort: false } },
  { name: 'female', label: 'Female', options: { filter: false, sort: false } },
  { name: 'other', label: 'Other', options: { filter: false, sort: false } },
  { name: 'totalGender', label: ' Gender', options: { filter: false, sort: false } },


  { name: 'grandTotal', label: 'Grand Total', options: { sort: false, filter: false } },
];



const options = {
  filterType: '',
  selectableRows: false,
  responsive: 'standard',
  elevation: 0,
  pagination: true,
  search: true,
  searchPlaceholder: 'Search...',
  searchProps: {
    style: {
      fontSize: 14,
      padding: 4,
      borderRadius: 4,
      width: 6,
    },
    inputProps: {
      placeholder: 'Search...',
      style: {
        fontSize: 14,
        padding: 4,
      },
    },
  },
  print: true,
  download: true,
  viewColumns: false,
  title: 'Teaching Staff', // Add title here
  rowsPerPage: 15,
};

const getMuiTheme = () =>
  createTheme({
    components: {
      MuiTableCell: {
        styleOverrides: {
          head: {
            backgroundColor: '#2b6eb5',
            color: 'whitesmoke',
            textTransform: 'capitalize',
            padding: '6px',
            fontSize: '14px',
            border: '1px solid #C2C2C2',
          },
          body: {
            fontSize: '14px',
            padding: '6px',
            border: '1px solid #C2C2C2',
          },
          root: {
            justifyContent: 'center',
            '& input': {
              textAlign: 'center',
            },
            padding: '0px',
          },
        },
      },
    },
  });

const CustomHeader1 = () => {
  return (
    <thead>
      <tr style={{ backgroundColor: '#2b6eb5', color: 'whitesmoke' }}>
        <th rowSpan="2" style={{ border: '1px solid #c2c2c2', backgroundColor: "#2b6eb5", color: "#FFFFFF", fontSize: '14px' }}>Post</th>
        <th colSpan="4" style={{ border: '1px solid #c2c2c2', backgroundColor: "#2b6eb5", color: "#FFFFFF", fontSize: '14px' }}>Permanent</th>
        <th colSpan="4" style={{ border: '1px solid #c2c2c2', backgroundColor: "#2b6eb5", color: "#FFFFFF", fontSize: '14px' }}>Temporary</th>
        <th colSpan="4" style={{ border: '1px solid #c2c2c2', backgroundColor: "#2b6eb5", color: "#FFFFFF", fontSize: '14px' }}>Contract</th>
        <th colSpan="4" style={{ border: '1px solid #c2c2c2', backgroundColor: "#2b6eb5", color: "#FFFFFF", fontSize: '14px' }}>Part-Time</th>
        <th rowSpan="2" style={{ border: '1px solid #c2c2c2', backgroundColor: "#2b6eb5", color: "#FFFFFF", fontSize: '14px' }}>Grand Total</th>
      </tr>
      <tr style={{ backgroundColor: '#2b6eb5', color: 'whitesmoke' }}>
        <th style={{ border: '1px solid #c2c2c2', padding: '4px', backgroundColor: "#2b6eb5", color: "#FFFFFF", fontSize: '14px' }}>Male</th>
        <th style={{ border: '1px solid #c2c2c2', padding: '4px', backgroundColor: "#2b6eb5", color: "#FFFFFF", fontSize: '14px' }}>Female</th>
        <th style={{ border: '1px solid #c2c2c2', padding: '4px', backgroundColor: "#2b6eb5", color: "#FFFFFF", fontSize: '14px' }}>Other</th>
        <th style={{ border: '1px solid #c2c2c2', padding: '4px', backgroundColor: "#2b6eb5", color: "#FFFFFF", fontSize: '14px' }}>Total</th>

        <th style={{ border: '1px solid #c2c2c2', padding: '4px', backgroundColor: "#2b6eb5", color: "#FFFFFF", fontSize: '14px' }}>Male</th>
        <th style={{ border: '1px solid #c2c2c2', padding: '4px', backgroundColor: "#2b6eb5", color: "#FFFFFF", fontSize: '14px' }}>Female</th>
        <th style={{ border: '1px solid #c2c2c2', padding: '4px', backgroundColor: "#2b6eb5", color: "#FFFFFF", fontSize: '14px' }}>Other</th>
        <th style={{ border: '1px solid #c2c2c2', padding: '4px', backgroundColor: "#2b6eb5", color: "#FFFFFF", fontSize: '14px' }}>Total</th>

        <th style={{ border: '1px solid #c2c2c2', padding: '4px', backgroundColor: "#2b6eb5", color: "#FFFFFF", fontSize: '14px' }}>Male</th>
        <th style={{ border: '1px solid #c2c2c2', padding: '4px', backgroundColor: "#2b6eb5", color: "#FFFFFF", fontSize: '14px' }}>Female</th>
        <th style={{ border: '1px solid #c2c2c2', padding: '4px', backgroundColor: "#2b6eb5", color: "#FFFFFF", fontSize: '14px' }}>Other</th>
        <th style={{ border: '1px solid #c2c2c2', padding: '4px', backgroundColor: "#2b6eb5", color: "#FFFFFF", fontSize: '14px' }}>Total</th>

        <th style={{ border: '1px solid #c2c2c2', padding: '4px', backgroundColor: "#2b6eb5", color: "#FFFFFF", fontSize: '14px' }}>Male</th>
        <th style={{ border: '1px solid #c2c2c2', padding: '4px', backgroundColor: "#2b6eb5", color: "#FFFFFF", fontSize: '14px' }}>Female</th>
        <th style={{ border: '1px solid #c2c2c2', padding: '4px', backgroundColor: "#2b6eb5", color: "#FFFFFF", fontSize: '14px' }}>Other</th>
        <th style={{ border: '1px solid #c2c2c2', padding: '4px', backgroundColor: "#2b6eb5", color: "#FFFFFF", fontSize: '14px' }}>Total</th>

      </tr>

    </thead>
  );
};
const CustomHeader2 = () => {
  return (

    <thead>
      <tr style={{ backgroundColor: '#2b6eb5', color: 'whitesmoke' }}>
        <th rowSpan="2" style={{ border: '1px solid #c2c2c2', backgroundColor: "#2b6eb5", color: "#FFFFFF", fontSize: '14px' }}>Ethnicity</th>
        <th colSpan="4" style={{ border: '1px solid #c2c2c2', backgroundColor: "#2b6eb5", color: "#FFFFFF", fontSize: '14px' }}>Permanent</th>
        <th colSpan="4" style={{ border: '1px solid #c2c2c2', backgroundColor: "#2b6eb5", color: "#FFFFFF", fontSize: '14px' }}>Temporary</th>
        <th colSpan="4" style={{ border: '1px solid #c2c2c2', backgroundColor: "#2b6eb5", color: "#FFFFFF", fontSize: '14px' }}>Contract</th>
        <th colSpan="4" style={{ border: '1px solid #c2c2c2', backgroundColor: "#2b6eb5", color: "#FFFFFF", fontSize: '14px' }}>Part-Time</th>
        <th rowSpan="2" style={{ border: '1px solid #c2c2c2', backgroundColor: "#2b6eb5", color: "#FFFFFF", fontSize: '14px' }}>Grand Total</th>
      </tr>

      <tr style={{ backgroundColor: '#2b6eb5', color: 'whitesmoke' }}>
        <th style={{ border: '1px solid #c2c2c2', padding: '4px', backgroundColor: "#2b6eb5", color: "#FFFFFF", fontSize: '14px' }}>Male</th>
        <th style={{ border: '1px solid #c2c2c2', padding: '4px', backgroundColor: "#2b6eb5", color: "#FFFFFF", fontSize: '14px' }}>Female</th>
        <th style={{ border: '1px solid #c2c2c2', padding: '4px', backgroundColor: "#2b6eb5", color: "#FFFFFF", fontSize: '14px' }}>Other</th>
        <th style={{ border: '1px solid #c2c2c2', padding: '4px', backgroundColor: "#2b6eb5", color: "#FFFFFF", fontSize: '14px' }}>Total</th>

        <th style={{ border: '1px solid #c2c2c2', padding: '4px', backgroundColor: "#2b6eb5", color: "#FFFFFF", fontSize: '14px' }}>Male</th>
        <th style={{ border: '1px solid #c2c2c2', padding: '4px', backgroundColor: "#2b6eb5", color: "#FFFFFF", fontSize: '14px' }}>Female</th>
        <th style={{ border: '1px solid #c2c2c2', padding: '4px', backgroundColor: "#2b6eb5", color: "#FFFFFF", fontSize: '14px' }}>Other</th>
        <th style={{ border: '1px solid #c2c2c2', padding: '4px', backgroundColor: "#2b6eb5", color: "#FFFFFF", fontSize: '14px' }}>Total</th>

        <th style={{ border: '1px solid #c2c2c2', padding: '4px', backgroundColor: "#2b6eb5", color: "#FFFFFF", fontSize: '14px' }}>Male</th>
        <th style={{ border: '1px solid #c2c2c2', padding: '4px', backgroundColor: "#2b6eb5", color: "#FFFFFF", fontSize: '14px' }}>Female</th>
        <th style={{ border: '1px solid #c2c2c2', padding: '4px', backgroundColor: "#2b6eb5", color: "#FFFFFF", fontSize: '14px' }}>Other</th>
        <th style={{ border: '1px solid #c2c2c2', padding: '4px', backgroundColor: "#2b6eb5", color: "#FFFFFF", fontSize: '14px' }}>Total</th>

        <th style={{ border: '1px solid #c2c2c2', padding: '4px', backgroundColor: "#2b6eb5", color: "#FFFFFF", fontSize: '14px' }}>Male</th>
        <th style={{ border: '1px solid #c2c2c2', padding: '4px', backgroundColor: "#2b6eb5", color: "#FFFFFF", fontSize: '14px' }}>Female</th>
        <th style={{ border: '1px solid #c2c2c2', padding: '4px', backgroundColor: "#2b6eb5", color: "#FFFFFF", fontSize: '14px' }}>Other</th>
        <th style={{ border: '1px solid #c2c2c2', padding: '4px', backgroundColor: "#2b6eb5", color: "#FFFFFF", fontSize: '14px' }}>Total</th>

      </tr>

    </thead>

  );
};
const backendUrl = process.env.REACT_APP_BACKEND_URL;


const TeachingStaffSummary = () => {

  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const localStorageData = JSON.parse(localStorage.getItem('persist:root'));
        const userState = JSON.parse(localStorageData.user);
        const authToken = userState.currentUser.tokenString;
        const config = {
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json'
          }
        };
        const response = await axios.get(`${backendUrl}/Employee/Report/GetTeachingStaffSummary`, config);
        const updatedData = response.data.map((employee, index) => ({
          ...employee,
          no: index + 1,
        }));
        setData1(updatedData);
        setData2(updatedData)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  console.log(data1)

  const reducedData1 = data1.reduce((acc, curr) => {
    const { joiningType, male, female, other } = curr;

    if (!acc[joiningType]) {
      acc[joiningType] = {
        male: 0,
        female: 0,
        other: 0,
        total: 0,
      };
    }
    acc[joiningType].male += male || 0;
    acc[joiningType].female += female || 0;
    acc[joiningType].other += other || 0;
    acc[joiningType].total += male + female + other;

    return acc;
  }, {});

  console.log(data2)
  return (
    <ThemeProvider theme={getMuiTheme()}>
      <Box marginTop='80px'>
        <MUIDataTable
          title={<Typography variant='body1' style={{ color: '#2b6eb5', padding: '5px', textAlign: 'right' }}>Teaching Staff Summary By Gender</Typography>}
          data={data1}
          columns={columns1}
          options={options}
          components={{
            TableHead: CustomHeader1
          }}
        />
      </Box>

      <MUIDataTable
        title={<Typography variant='body1' style={{ color: '#2b6eb5', padding: '5px', textAlign: 'right' }}>Teaching Staff Summary by Ethnicity </Typography>}
        data={data1}
        columns={columns2}
        options={options}
        components={{
          TableHead: CustomHeader2
        }}
      />
    </ThemeProvider>
  );
};

export default TeachingStaffSummary;