import React, { createContext, useContext, useState } from "react";
import {
  Grid,
  TextField,
  Button,
  Box,
  FormControl,
  Typography,
  MenuItem,
  Select,
  InputLabel,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import { DateField, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
const CampusContactContext = createContext();

const CampusContactProvider = ({ children }) => {
  const methods = useForm();
  const [contactDetails, setContactDetails] = useState({
    yearOfEstd: "",
    dateOfEstd: 0,
    accreditationStatus: "",
    campusChief: "",
    principalNumber: "",
    principalNumber2: "",
    contactEmail1: "",
    focalPersonName: "",
    position: "",
    contactNumber: "",
    contactNumber2: "",
    contactNumber3: "",
    contactEmail2: "",
    bankName: "",
    branchName: "",
    accountNumber: "",
  });

  const onChange = (name, value) => {
    setContactDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <CampusContactContext.Provider
      value={{ ...methods, contactDetails, onChange }}
    >
      {children}
    </CampusContactContext.Provider>
  );
};

const CampusContactDetails = ({ handleNext, handleBack }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useContext(CampusContactContext);
  const { onChange } = useContext(CampusContactContext);

  const onSubmit = (data) => {
    onChange("yearOfEstd", data.yearOfEstd);
    onChange("dateOfEstd", data.dateOfEstd);
    onChange("accreditationStatus", data.accreditationStatus);
    onChange("campusChief", data.campusChief);
    onChange("principalNumber", data.principalNumber);
    onChange("principalNumber2", data.principalNumber2);
    onChange("contactEmail1", data.contactEmail1);
    onChange("focalPersonName", data.focalPersonName);
    onChange("position", data.position);
    onChange("contactNumber2", data.contactNumber2);
    onChange("contactNumber3", data.contactNumber3);
    onChange("contactNumber", data.contactNumber);
    onChange("contactEmail2", data.contactEmail2);
    onChange("bankName", data.bankName);
    onChange("branchName", data.branchName);
    onChange("accountNumber", data.accountNumber);
    handleNext();
  };

  const onBack = (data) => {
    handleBack();
  };

  return (
    <Grid
      container
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      onReset={() => {
        onBack();
      }}
    >
      <Grid container mt=".6rem" spacing={1}>
        <Grid item xs={12} sm={4}>
          <Controller
            name="yearOfEstd"
            control={control}
            rules={{
              pattern: {
                value: /^\d{0,4}$/,
                message:
                  "Please enter only numeric values with maximum length of four digits",
              },
            }}
            defaultValue=""
            render={({ field }) => (
              <TextField
                required
                {...field}
                id="yearOfEstd"
                size="small"
                type="text"
                label="Year Of Establishment"
                name="yearOfEstd"
                InputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                  onInput: (e) => {
                    e.target.value = e.target.value.replace(/\D/g, ""); // Allow only numeric characters
                    if (e.target.value.length > 4) {
                      e.target.value = e.target.value.slice(0, 4); // Limit input to maximum of four characters
                    }
                  },
                }}
                fullWidth
                error={!!errors.yearOfEstd}
                helpertext={errors.yearOfEstd ? errors.yearOfEstd.message : ""}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer
              components={["DatePicker", "DateField"]}
              sx={{ mt: "-8px", overflow: "hidden" }}
            >
              <Controller
                name="dateOfEstd"
                control={control}
                defaultValue={null} 
                render={({ field }) => (
                  <DateField
                    {...field}
                    fullWidth
                    required
                    label="Date of establishment"
                    enableAccessibleFieldDOMStructure
                    slotProps={{ textField: { size: "small" } }}
                    format="YYYY/MM/DD"
                  />
                )}
              />
            </DemoContainer>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth size="small">
            <InputLabel>Accreditation Status</InputLabel>
            <Controller
              name="accreditationStatus"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select
                  {...field}
                  id="accreditationStatus"
                  size="small"
                  name="accreditationStatus"
                  label="Accreditation Status"
                  fullWidth
                >
                  <MenuItem value="yes">Yes</MenuItem>
                  <MenuItem value="no">No</MenuItem>
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        <Box
          border="1px solid #c2c2c2"
          borderRadius="10px"
          position="relative"
          width="100%"
          marginTop="30px"
          padding='22px 15px 15px 15px'
          marginLeft={1}
        >
          <Typography
            variant="body1"
            borderRadius='10px'
            display="inline-block"
            color='#8c8d90'
            border='1px solid #8c8d90'
            padding="3px 5px"
            position="absolute"
            left="20px"
            top="-20px"
            bgcolor='#e2e4e8'
          >
            Campus Chief/Principal
          </Typography>

          <Grid container spacing={1} direction="row">
            <Grid item xs={12} sm={4}>
              <Controller
                name="campusChief"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    id="campusChief"
                    size="small"
                    name="campusChief"
                    label="Chief/Principal Name"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={2.5}>
              <Controller
                name="principalNumber"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    required
                    {...field}
                    id="principalNumber"
                    size="small"
                    name="principalNumber"
                    label="Phone Number"
                    InputProps={{
                      placeholder: "XXXXXXXX",
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      onInput: (e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      },
                    }}
                    fullWidth
                    error={!!errors.principalNumber}
                    helperText={
                      errors.principalNumber
                        ? errors.principalNumber.message
                        : ""
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={2.5}>
              <Controller
                name="principalNumber2"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="principalNumber2"
                    size="small"
                    name="principalNumber2"
                    label="Landline Number"
                    InputProps={{
                      placeholder: "XXXXXXXX",
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      onInput: (e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      },
                    }}
                    fullWidth
                    error={!!errors.principalNumber2}
                    helperText={
                      errors.principalNumber2
                        ? errors.principalNumber2.message
                        : ""
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Controller
                name="contactEmail1"
                control={control}
                rules={{
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Email format not matched",
                  },
                }}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    required
                    {...field}
                    id="contactEmail1"
                    size="small"
                    name="contactEmail1"
                    label="Contact Email"
                    fullWidth
                    error={!!errors.contactEmail1}
                    helpertext={
                      errors.contactEmail1 && errors.contactEmail1.message
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
        <Box
           border="1px solid #c2c2c2"
           borderRadius="10px"
           position="relative"
           width="100%"
           marginTop="30px"
           padding='22px 15px 15px 15px'
           marginLeft={1}
        >
          <Typography
             variant="body1"
             borderRadius='10px'
             display="inline-block"
             color='#8c8d90'
             border='1px solid #8c8d90'
             padding="3px 5px"
             position="absolute"
             left="20px"
             top="-20px"
             bgcolor='#e2e4e8'
          >
            Contact Person
          </Typography>
          <Grid container spacing={1} direction="row">
            <Grid item xs={12} sm={3}>
              <Controller
                name="focalPersonName"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    id="focalPersonName"
                    size="small"
                    type="text"
                    name="focalPersonName"
                    label="Contact Person Name"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Controller
                name="position"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    id="position"
                    size="small"
                    name="position"
                    label="Position"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Controller
                name="contactNumber"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    required
                    {...field}
                    id="contactNumber"
                    size="small"
                    name="contactNumber"
                    label="Phone Number"
                    InputProps={{
                      placeholder: "XXXXXXXX",
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      onInput: (e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      },
                    }}
                    fullWidth
                    error={!!errors.contactNumber}
                    helpertext={
                      errors.contactNumber ? errors.contactNumber.message : ""
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Controller
                name="contactNumber2"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="contactNumber2"
                    size="small"
                    name="contactNumber2"
                    label="Landline Number 1"
                    InputProps={{
                      placeholder: "XXXXXXXX",
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      onInput: (e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      },
                    }}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Controller
                name="contactNumber3"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="contactNumber3"
                    size="small"
                    name="contactNumber3"
                    label="Landline Number 2"
                    InputProps={{
                      placeholder: "XXXXXXXX",
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      onInput: (e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      },
                    }}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Controller
                name="contactEmail2"
                control={control}
                rules={{
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Email format not matched",
                  },
                }}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    required
                    {...field}
                    id="contactEmail2"
                    size="small"
                    name="contactEmail2"
                    label="Contact Email"
                    fullWidth
                    error={!!errors.contactEmail2}
                    helpertext={
                      errors.contactEmail2 && errors.contactEmail2.message
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Controller
                name="bankName"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="bankName"
                    size="small"
                    name="bankName"
                    label="Bank Name"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Controller
                name="branchName"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="branchName"
                    name="branchName"
                    size="small"
                    label="Bank Branch"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="accountNumber"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="accountNumber"
                    name="accountNumber"
                    size="small"
                    label="Bank Account Number"
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Box mt={2} display="flex" justifyContent="space-between">
        <Button
          onClick={handleBack}
          variant="outlined"
          color="error"
          type="reset"
          size="small"
          startIcon={<ChevronLeftRoundedIcon />}
        >
          Back
        </Button>
        <Button
          variant="outlined"
          size="small"
          type="submit"
          sx={{
            marginLeft: "10px",
          }}
          endIcon={<ChevronRightRoundedIcon />}
        >
          Next
        </Button>
      </Box>
    </Grid>
  );
};
export { CampusContactProvider, CampusContactContext };
export default CampusContactDetails;
