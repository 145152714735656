import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import { getLevel } from "../../services/services";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const LevelAdd = () => {
  const [levelData, setLevelData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getLevel();
        setLevelData(data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  return (
    <div>
      <TableContainer style={{ maxHeight: 300 }}>
        <Table style={{ borderCollapse: "collapse", border: "1px solid #ddd" }}>
          <TableHead style={{ backgroundColor: "#2A629A" }}>
            <TableRow>
              <TableCell
                style={{
                  color: "#FFFFFF",
                  border: "1px solid #ddd",
                  padding: "8px",
                }}
              >
                S.No:
              </TableCell>
              <TableCell
                style={{
                  color: "#FFFFFF",
                  border: "1px solid #ddd",
                  padding: "8px",
                }}
              >
                Level Name
              </TableCell>
              <TableCell
                style={{
                  color: "#FFFFFF",
                  border: "1px solid #ddd",
                  padding: "8px",
                }}
              >
                Short Name
              </TableCell>
              <TableCell
                style={{
                  color: "#FFFFFF",
                  border: "1px solid #ddd",
                  padding: "8px",
                }}
              >
                Code
              </TableCell>
              <TableCell
                style={{
                  color: "#FFFFFF",
                  border: "1px solid #ddd",
                  padding: "8px",
                }}
              >
                Alias
              </TableCell>
              <TableCell
                style={{
                  color: "#FFFFFF",
                  border: "1px solid #ddd",
                  padding: "8px",
                }}
              >
                Add
              </TableCell>
              <TableCell
                style={{
                  color: "#FFFFFF",
                  border: "1px solid #ddd",
                  padding: "8px",
                }}
              >
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {levelData.map((data, index) => (
              <TableRow key={data.id} style={{ border: "1px solid #ddd" }}>
                <TableCell
                  style={{
                    border: "1px solid #ddd",
                    padding: "4px",
                    height: "20px",
                  }}
                >
                  {index + 1}
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid #ddd",
                    padding: "4px",
                    height: "20px",
                  }}
                >
                  {data.levelName}
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid #ddd",
                    padding: "4px",
                    height: "20px",
                  }}
                >
                  {data.shortName}
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid #ddd",
                    padding: "4px",
                    height: "20px",
                  }}
                >
                  {data.code}
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid #ddd",
                    padding: "4px",
                    height: "20px",
                  }}
                >
                  {data.alias}
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid #ddd",
                    padding: "4px",
                    height: "20px",
                  }}
                >
                  <Checkbox color="success" />
                </TableCell>
                <TableCell
                  style={{
                    padding: "8px",
                    color: "green",
                    display: "flex",
                    alignItems: "center",
                    borderBottom: "none",
                  }}
                >
                  <FiberManualRecordIcon
                    sx={{ fontSize: 12, color: "green", marginRight: "4px" }}
                  />
                  <span>Active</span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default LevelAdd;
