import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Popover,
  Box,
  Grid,
} from "@mui/material";
import { FileDownload, InsertDriveFile, PictureAsPdf } from "@mui/icons-material";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const ProgramsByLevels = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);

  // Fetch data from the API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
        const userState = JSON.parse(localStorageData.user);
        const authToken = userState.currentUser.tokenString;
        const config = {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        };
        const response = await fetch(`${backendUrl}/Dashboard/LevelEnrollmentReport`, config);
        const data = await response.json();

        // Ensure missing program levels are set to 0
        const formattedData = data.map((row) => ({
          id: row.sn,
          university: row.universityName,
          bachelor: row.programLevelCounts.Bachelor || 0,
          pgd: row.programLevelCounts.PGD || 0,
          Masters: row.programLevelCounts.Masters || 0,
          mphil: row.programLevelCounts.MPhil || 0,
          phd: row.programLevelCounts.PhD || 0,
        }));

        setRows(formattedData);
      } catch (error) {
        console.error("Error fetching data:", error);
        setRows([]); // Handle error and set empty data
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Calculate the totals for each column and the overall total
  const totals = rows.reduce(
    (acc, row) => {
      acc.bachelor += row.bachelor;
      acc.pgd += row.pgd;
      acc.Masters += row.Masters;
      acc.mphil += row.mphil;
      acc.phd += row.phd;
      return acc;
    },
    {
      bachelor: 0,
      pgd: 0,
      Masters: 0,
      mphil: 0,
      phd: 0,
    }
  );

  // Open the export popover
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Close the export popover
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "export-popover" : undefined;

  // Export to Excel
  const exportToExcel = () => {
    const header = [
      [
        "S.No.",
        "University",
        "Bachelor",
        "PGD",
        "Master",
        "MPhil",
        "PhD",
        "Total",
      ],
    ];

    const data = rows.map((row, index) => [
      index + 1,
      row.university,
      row.bachelor,
      row.pgd,
      row.Masters,
      row.mphil,
      row.phd,
      row.bachelor + row.pgd + row.Masters + row.mphil + row.phd,
    ]);

    const ws = XLSX.utils.aoa_to_sheet([...header, ...data]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Program Levels");
    XLSX.writeFile(wb, "program_levels.xlsx");
  };

  // Export to PDF
  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.setFontSize(12);
    const columns = [
      "S.No.",
      "University",
      "Bachelor",
      "PGD",
      "Master",
      "MPhil",
      "PhD",
      "Total",
    ];
    const data = rows.map((row, index) => [
      index + 1,
      row.university,
      row.bachelor,
      row.pgd,
      row.Masters,
      row.mphil,
      row.phd,
      row.bachelor + row.pgd + row.Masters + row.mphil + row.phd,
    ]);
    doc.autoTable(columns, data);
    doc.save("program_levels.pdf");
  };

  return (
    <div>
      <Grid container justifyContent="right">
      <Button 
        variant="contained" 
        color="primary"
        size="small" 
        startIcon={<FileDownload />} 
        onClick={handleClick}
        style={{ marginBottom: '10px' }}
      >
        Export
      </Button>

      {/* Popover for Excel and PDF options */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box sx={{ padding: '10px' }}>
          <Button 
            variant="contained" 
            color="primary" 
            sx={{ backgroundColor: '#5FAD41' }}
            startIcon={<InsertDriveFile />} 
            onClick={exportToExcel} 
            fullWidth
            style={{ marginBottom: '10px' }}
          >
            Excel
          </Button>
          <Button 
            variant="contained" 
            sx={{ backgroundColor: '#272727' }}
            startIcon={<PictureAsPdf />} 
            onClick={exportToPDF} 
            fullWidth
          >
           PDF
          </Button>
        </Box>
      </Popover>
      </Grid>

      <TableContainer sx={{ border: "1px solid #ddd" }}>
        <Table style={{ borderCollapse: "collapse", border: "1px solid #ddd" }}>
          <TableHead style={{ backgroundColor: "#2A629A" }}>
            <TableRow>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
                rowSpan={2}
              >
                S.No.
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
                rowSpan={2}
              >
                University
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
                colSpan={6}
              >
                Program Level
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Bachelor
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                PGD
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Master
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                MPhil
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                PhD
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Total
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.map((row, index) => {
              const rowTotal = row.bachelor + row.pgd + row.Masters + row.mphil + row.phd;

              return (
                <TableRow key={row.id}>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "center",
                    }}
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "left",
                    }}
                  >
                    {row.university}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "right",
                    }}
                  >
                    {row.bachelor}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "right",
                    }}
                  >
                    {row.pgd}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "right",
                    }}
                  >
                    {row.Masters}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "right",
                    }}
                  >
                    {row.mphil}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "right",
                    }}
                  >
                    {row.phd}
                  </TableCell>

                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "right",
                    }}
                  >
                    {rowTotal}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>

          <TableRow style={{ backgroundColor: "#c2c2c2" }}>
            <TableCell
              colSpan={2}
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "right",
                fontWeight: "bold",
              }}
            >
              Grand Total
            </TableCell>
            <TableCell
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "right",
              }}
            >
              {totals.bachelor}
            </TableCell>
            <TableCell
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "right",
              }}
            >
              {totals.pgd}
            </TableCell>
            <TableCell
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "right",
              }}
            >
              {totals.Masters}
            </TableCell>
            <TableCell
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "right",
              }}
            >
              {totals.mphil}
            </TableCell>
            <TableCell
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "right",
              }}
            >
              {totals.phd}
            </TableCell>

            <TableCell
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "right",
              }}
            >
              {Object.values(totals).reduce((a, b) => a + b, 0)}
            </TableCell>
          </TableRow>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ProgramsByLevels;
